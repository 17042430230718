import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@nordictrustee/nt-ui-library';
import { RouteParam } from '../../../../../../../utils/types/RouteParam';
import EventsTable from '../../../../../components/EventsTable/EventsTable';
import { useGetUpcomingEvents } from '../../../api';
import { ExtraMargin } from './UpcomingEvents.css';

const UpcomingEvents = () => {
  const { agreementId } = useParams<RouteParam>();
  const { eventsData, isLoading, getEvents } =
    useGetUpcomingEvents(agreementId);

  useEffect(() => {
    agreementId && getEvents();
  }, [agreementId, getEvents]);

  return (
    <Container title="Upcoming events" containerHeight="100%">
      <EventsTable
        isLoading={isLoading}
        data={eventsData?.results}
        bodyHeight="330px"
      />
      <ExtraMargin />
    </Container>
  );
};

export default UpcomingEvents;
