import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Toolbar } from '@nordictrustee/nt-ui-library';
import styled from 'styled-components';
import defaultTheme from 'themes';
import theme from 'themes/theme';

export const LogoLink = styled(NavLink)`
  padding: 2rem 1rem 0.5rem 0;
`;

export const NTLoginLink = styled.a`
  color: ${theme.palette.primary.main};
`;

export const Header = styled.header`
  background-color: ${defaultTheme.theme.palette.common.black};
  color: ${defaultTheme.theme.palette.common.white};
  margin-top: -0.3rem;
`;

export const MainMenuBox = styled.div`
  cursor: pointer;
  margin-right: 20px;
`;

const RightLeftToolbar = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
  & > button {
    color: white;
  }
  @media (max-width: 1500px) {
    & > button {
      padding: 0;
      margin-right: 1rem;
    }
  }
`;

export const RightToolbar = styled(RightLeftToolbar)`
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const HeaderToolbar = styled(Toolbar)`
  max-height: '64px';
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 4rem;
  box-sizing: border-box;
`;

export const LeftToolbar = styled(RightLeftToolbar)`
  @media (min-width: 1501px) {
    & > button {
      display: none;
    }
  }
`;

export const MenuItem = styled(Button)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 3rem;
    justify-content: flex-start;
    font-size: 0.8125rem;
    padding: 0 3rem 0 1rem;
  }
  .MuiButton-startIcon svg {
    width: 24px;
    height: 24px;
  }
`;

export const Account = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #ececec;
  color: #13161c;
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;
