import { Container } from '@mui/material';
import styled from 'styled-components';
import { loanColor } from '../../../themes/theme';

export const StyleContainer = styled(Container)`
  .MuiTypography-h3 {
    color: ${loanColor.main};
  }
  .MuiTabs-indicator {
    background: ${loanColor.dark};
  }
  button {
    &.Mui-selected {
      color: ${loanColor.dark};
    }
  }
  .MuiCircularProgress-root {
    color: ${loanColor.dark};
  }
`;
