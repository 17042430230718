import React, { Dispatch, useEffect, useMemo, useRef } from 'react';
import { Column } from '@material-table/core';
import {
  ColumnWithTooltip,
  Container,
  Table,
} from '@nordictrustee/nt-ui-library';
import { centerColumnText, firstColPadding, rightText } from 'utils/constants';
import { getInitDate } from 'utils/formatters/dateFormatter';
import scrollTop from 'utils/scrollTop';
import scrollToRow from 'utils/scrollToRow';
import { selectedRowStyle } from 'utils/selectedRowStyle';
import { facilityAgreementColor } from '../../../../../../../themes/theme';
import { ComplianceScheduleItem } from '../../ComplianceSchedule.types';

interface Props {
  complianceScheduleItemData: ComplianceScheduleItem[];
  isLoadingComplianceScheduleItem: boolean;
  selectedSchedule?: ComplianceScheduleItem;
  setSelectedSchedule: Dispatch<
    React.SetStateAction<ComplianceScheduleItem | undefined>
  >;
}

const CovenantScheduleList = ({
  complianceScheduleItemData,
  isLoadingComplianceScheduleItem,
  selectedSchedule,
  setSelectedSchedule,
}: Props) => {
  const columns: Column<ComplianceScheduleItem>[] = useMemo(
    () => [
      {
        field: 'name',
        title: <ColumnWithTooltip title="Name">Name</ColumnWithTooltip>,
        render: ({ name }) => (
          <ColumnWithTooltip title={name}>{name}</ColumnWithTooltip>
        ),
        ...firstColPadding,
        sorting: true,
        width: '24%',
        customSort: (a, b) => {
          if (a.name === b.name) {
            return (
              Number(getInitDate(a.deadlineDate)) -
              Number(getInitDate(b.deadlineDate))
            );
          } else {
            return -1;
          }
        },
      },
      {
        field: 'deadlineDate',
        title: 'Deadline Date',
        width: '11%',
        render: ({ deadlineDate }) => (
          <ColumnWithTooltip title={deadlineDate}>
            {deadlineDate}
          </ColumnWithTooltip>
        ),
        sorting: true,
        defaultSort: 'asc',
        customSort: (a, b) =>
          Number(getInitDate(a.deadlineDate)) -
          Number(getInitDate(b.deadlineDate)) || a.name.localeCompare(b.name),
      },
      {
        field: 'referenceDate',
        title: 'Reference Date',
        width: '12%',
        render: ({ referenceDate }) => (
          <ColumnWithTooltip title={referenceDate}>
            {referenceDate}
          </ColumnWithTooltip>
        ),
        customSort: (a, b) =>
          Number(getInitDate(a.referenceDate)) -
          Number(getInitDate(b.referenceDate)),
        sorting: true,
      },
      {
        field: 'compliant',
        title: 'Compliant',
        width: '10%',
        render: ({ compliant }) => {
          switch (compliant) {
            case 'Partial':
              return 'Partially Complete';
            case 'Complete':
              return 'Complete';
          }
        },
        sorting: true,
        ...centerColumnText,
      },
      {
        field: 'lastSentReminderDate',
        title: (
          <ColumnWithTooltip title="Last Sent Reminder / No. Of Documents">
            Last sent reminder
          </ColumnWithTooltip>
        ),
        width: '15%',

        render: (item) => (
          <ColumnWithTooltip title={item.lastSentReminderDate || ''}>
            {item.lastSentReminderDate || 'N/A'}
          </ColumnWithTooltip>
        ),
        sorting: true,
        cellStyle: { ...rightText, paddingRight: '1.5rem' },
      },
    ],
    [],
  );

  useEffect(() => {
    if (complianceScheduleItemData.length && firstRender.current) {
      const data = complianceScheduleItemData.map((covenant) => ({
        ...covenant,
        deadlineDate: getInitDate(covenant.deadlineDate)?.unix() || 0,
      }));
      //Sort data ascending from two sources by unix timestamp
      data.sort(
        (a, b) =>
          a.deadlineDate - b.deadlineDate || a.name.localeCompare(b.name),
      );
      const emptyCompliantArray = data.filter((el) => el.compliant === 'Empty');
      if (emptyCompliantArray.length) {
        // if there is any empty compliant select the first one of them
        const firstEmptyCompliantId = emptyCompliantArray[0]?.id;
        const firstEmptyCompliant = complianceScheduleItemData.find(
          (el) => el.id === firstEmptyCompliantId,
        );
        setSelectedSchedule(firstEmptyCompliant);
      } else {
        // if all compliant are marked select the first row
        const firstCompliant = complianceScheduleItemData.find(
          (el) => el.id === data[0]?.id,
        );
        setSelectedSchedule(firstCompliant);
      }
    }
  }, [complianceScheduleItemData, setSelectedSchedule]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (selectedSchedule && firstRender.current) {
      scrollToRow(selectedSchedule.id);
      scrollTop();
      firstRender.current = false;
    }
  }, [selectedSchedule]);

  return (
    <>
      <Container title="Covenant schedule" containerHeight="100%">
        <Table
          isLoading={isLoadingComplianceScheduleItem}
          columns={columns}
          data={complianceScheduleItemData}
          hideSortingArrows
          options={{
            search: false,
            toolbar: false,
            paging: false,
            sorting: true,
            maxBodyHeight: '60vh',
            minBodyHeight: '60vh',
            tableLayout: 'fixed',
            rowStyle: (rowData: ComplianceScheduleItem) =>
              selectedRowStyle(
                rowData.id,
                selectedSchedule ? selectedSchedule.id : 0,
                facilityAgreementColor.rowSelection,
              ),
          }}
          onRowClick={(_: any, rowData: ComplianceScheduleItem) => {
            setSelectedSchedule(rowData);
          }}
        />
      </Container>
    </>
  );
};

export default CovenantScheduleList;
