import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from '@material-table/core';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import {
  ColumnWithTooltip,
  Container,
  Table,
} from '@nordictrustee/nt-ui-library';
import moment from 'moment/moment';
import theme from 'themes/theme';
import {
  dateDisplayFormat,
  firstColPadding,
  searchFieldDebounceInterval,
} from 'utils/constants';
import { selectedRowStyle } from 'utils/selectedRowStyle';
import { daysDiffText } from '../../../../../utils/formatters/dateFormatter';
import { RouteParam } from '../../../../../utils/types/RouteParam';
import { EventFA } from '../../../FacilityAgreement.types';
import { useGetEvents } from '../api';
import { useEventsQuery } from './useEventsQuery';

const Events = () => {
  const { agreementId, itemId } = useParams<RouteParam>();

  const {
    setFilter,
    searchQuery,
    pageSizeQuery,
    pageQuery,
    setPageSizeQuery,
    setPageQuery,
    phraseQuery,
    levelNameQuery,
  } = useEventsQuery();
  const { eventsData, isLoading, getEvents } = useGetEvents(
    searchQuery,
    agreementId,
  );

  useEffect(() => {
    agreementId && getEvents();
  }, [agreementId, getEvents]);

  const setDataSearch = useCallback(() => {
    setPageQuery(0);
  }, [setPageQuery]);

  const columns: Column<EventFA>[] = useMemo(
    () => [
      {
        field: 'date',
        title: 'Date',
        render: ({ date }) => (
          <>{`${moment(date).format(dateDisplayFormat)}`}</>
        ),
        ...firstColPadding,
        sorting: true,
        filtering: false,
        width: '11%',
      },
      {
        field: 'numberToDeadline',
        title: '',
        filtering: false,
        width: '12%',
        render: ({ date }) => {
          const diff = daysDiffText(moment(date));
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LabelImportantIcon
                style={{
                  color: theme.palette.secondary.main,
                }}
              />
              {diff}
            </div>
          );
        },
      },
      {
        field: 'name',
        title: 'Name',
        defaultFilter: phraseQuery,
        render: ({ title }) => (
          <ColumnWithTooltip title={title}>{title}</ColumnWithTooltip>
        ),
        width: '30%',
      },

      {
        field: 'levelName',
        title: 'Facility Agreement Name/Facility Name/Loan Name',
        defaultFilter: levelNameQuery,
        render: ({ levelName }) => (
          <ColumnWithTooltip title={levelName}>{levelName}</ColumnWithTooltip>
        ),
      },
    ],
    [levelNameQuery, phraseQuery],
  );

  return (
    <Container containerHeight="100%" title="Events">
      <Table
        isLoading={isLoading}
        columns={columns}
        data={eventsData?.results ?? []}
        hideSortingArrows
        options={{
          search: false,
          toolbar: false,
          sorting: true,
          maxBodyHeight: '60vh',
          minBodyHeight: '60vh',
          filtering: true,
          paging: true,
          pageSize: pageSizeQuery,
          pageSizeOptions: [15, 25, 50],
          debounceInterval: searchFieldDebounceInterval,
          rowStyle: ({ id }: { id: number }) =>
            selectedRowStyle(id, itemId, undefined, '2.5rem'),
        }}
        onPageChange={setPageQuery}
        onRowsPerPageChange={setPageSizeQuery}
        onFilterChange={setFilter}
        disableDefaultSearchAndFilter
        page={pageQuery}
        onSearchChange={setDataSearch}
        totalCount={eventsData?.count}
      />
    </Container>
  );
};

export default Events;
