import { Box } from '@mui/material';
import styled from 'styled-components';

export const Header = styled.div`
  margin: 1rem 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ComponentWrapper = styled.div`
  margin: 0 1.5rem 0 1.5rem;
`;

export const TabDataView = styled(Box)`
  min-height: 12rem;
`;
