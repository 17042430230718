import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as api from './api';
import { User } from './types';

interface AuthContextType {
  isAuthenticated: boolean;
  user?: User;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  fetchMe: () => void;
  isLoadingUser: boolean;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const isISAuthenticated = JSON.parse(localStorage.getItem('state') || '{}')
    ?.user?.authorized;

  const storageUser: User = JSON.parse(
    localStorage.getItem('state') || '{}'
  )?.user;

  const [isAuthenticated, setIsAuthenticated] = useState(isISAuthenticated);
  const [user, setUser] = useState<User | undefined>(storageUser);
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  useEffect(() => {
    setIsAuthenticated(isISAuthenticated);
  }, [isISAuthenticated]);

  const { getMe } = api.useGetMe();

  const fetchMe = useCallback(async () => {
    if (isISAuthenticated) {
      setIsLoadingUser(true);
      const response = await getMe();

      const abbreviation =
        response.data.firstName?.charAt(0) + response.data.lastName?.charAt(0);

      setUser((prev) => ({
        ...prev,
        termsAccepted: response.data.termsAccepted,
        email: response.data.email,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        abbreviation,
      }));

      const state = {
        user: {
          accessToken: storageUser.accessToken,
          authorized: storageUser.authorized,
          termsAccepted: response.data.termsAccepted,
          abbreviation,
        },
      };
      localStorage.setItem('state', JSON.stringify(state));
      setIsLoadingUser(false);
    }
  }, [
    getMe,
    isISAuthenticated,
    storageUser?.accessToken,
    storageUser?.authorized,
  ]);

  useEffect(() => {
    fetchMe();
  }, [fetchMe]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      user,
      setIsAuthenticated,
      setUser,
      fetchMe,
      isLoadingUser,
    }),
    [fetchMe, isAuthenticated, isLoadingUser, user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
