import { Dispatch, SetStateAction, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
  Button,
  ConfirmDialog,
} from '@nordictrustee/nt-ui-library';
import { yearToMs } from '../../utils/constants';
import * as S from './Cookies.css';

export const ACCEPT_COOKIES = 'lp-accept-cookies';

interface Props {
  setRejectCookies: Dispatch<SetStateAction<boolean>>;
}

const Cookies = ({ setRejectCookies }: Props) => {
  const [, setCookie] = useCookies([ACCEPT_COOKIES]);
  const [openDialog, setOpenDialog] = useState(false);

  const acceptCookies = () =>
    setCookie(ACCEPT_COOKIES, 1, {
      maxAge: yearToMs,
      path: '/',
    });

  const rejectCookies = () => setRejectCookies(true);

  const closeDialog = () => {
    rejectCookies();
    setOpenDialog(false);
  };

  return (
    <>
      <S.Footer>
        <S.FooterToolbar>
          <S.RightToolbar>
            Nordic Trustee uses cookies to ensure that we give you the best
            experience on our website. More information
            <a
              style={{
                textDecoration: 'underline',
                color: 'white',
                marginLeft: '3px',
              }}
              target="_blank"
              rel="noreferrer"
              href="https://nordictrustee.com/privacy/"
            >
              here
            </a>
            .
          </S.RightToolbar>
          <S.LeftToolbar>
            <Button
              variant="outlined"
              style={{ backgroundColor: 'white' }}
              onClick={() => setOpenDialog(true)}
            >
              Decline
            </Button>
            <Button
              onClick={acceptCookies}
            >
              Accept
            </Button>
          </S.LeftToolbar>
        </S.FooterToolbar>
      </S.Footer>
      {openDialog && (
        <ConfirmDialog
          title=" "
          open
          onCancel={closeDialog}
          submitText="Accept cookies"
          isCancelVisible={false}
          message="This website uses cookies in order to offer you the most relevant
              information. Please accept cookies for optimal performance."
          onSubmit={acceptCookies}
        />
      )}
    </>
  );
};

export default Cookies;
