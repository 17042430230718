import { useEffect } from 'react';
import { Container, InitialContent } from '@nordictrustee/nt-ui-library';
import {
  FacilityDetailsDataSectionsType,
  Payment,
} from 'modules/FacilityDetails/FacilityDetails.types';
import LenderSplitTable from './components/LenderSplitTable';
import { useGetPaymentLenderSplit } from './api';

interface Props {
  sectionName: FacilityDetailsDataSectionsType;
  payment?: Payment;
}

const LenderSplit = ({ sectionName, payment }: Props) => {
  const {
    paymentLenderSplit,
    isLoadingPaymentLenderSplitData,
    getPaymentLenderSplit,
  } = useGetPaymentLenderSplit(sectionName, payment?.id);

  useEffect(() => {
    payment?.id && getPaymentLenderSplit();
  }, [getPaymentLenderSplit, payment?.id]);

  const initialContentDescription = (() => {
    switch (true) {
      case !paymentLenderSplit?.length:
        return 'No additional payments specified yet';
      default:
        return 'Select payment for the details';
    }
  })();

  const isNotAmountAdjustmentSection =
    sectionName !== FacilityDetailsDataSectionsType.LoanAmountAdjustment;

  return payment ? (
    <Container
      borderRadius={isNotAmountAdjustmentSection ? '0' : undefined}
      withoutShadow={isNotAmountAdjustmentSection}
      containerHeight="100%"
      title="Payment split (lenders)"
    >
      <LenderSplitTable
        data={paymentLenderSplit}
        isLoadingLenderSplit={isLoadingPaymentLenderSplitData}
        sectionName={sectionName}
      />
    </Container>
  ) : (
    <InitialContent
      withoutShadow={isNotAmountAdjustmentSection}
      square={isNotAmountAdjustmentSection}
      description={initialContentDescription}
      customHeight={isNotAmountAdjustmentSection ? '22vh' : '38vh'}
    />
  );
};

export default LenderSplit;
