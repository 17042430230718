import { useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Box } from '@mui/material';
import { Button } from '@nordictrustee/nt-ui-library';
import { AuthContext } from 'context/AuthProvider/AuthProvider';
import CheckboxInput from 'components/Inputs/CheckboxInput';
import { handleException } from 'utils/handleException';
import { FACILITY_AGREEMENTS } from '../../../router/url';
import Terms from '../Terms';
import * as api from './api';
import * as S from './VerifyAcceptedUserTerms.css';

const VerifyAcceptedUserTerms = ({
  withActions,
}: {
  withActions?: boolean;
}) => {
  const { fetchMe } = useContext(AuthContext);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<{ isTermsAccepted: boolean }>({ mode: 'onBlur' });
  const { push } = useHistory();

  const isTermsAccepted: boolean = useWatch({
    control,
    name: 'isTermsAccepted',
  });

  const { patchUserAcceptTerms } = api.usePatchUserAcceptTerms();

  const onSubmit = handleSubmit(async () => {
    try {
      if (isTermsAccepted) {
        await patchUserAcceptTerms();
        await fetchMe();
        push(FACILITY_AGREEMENTS);
      }
    } catch (e) {
      handleException(e);
    }
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ margin: withActions ? '30px 0' : '30px 0 0 0' }}
    >
      <Terms />
      {withActions && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={4}
          mb={8}
        >
          <S.Form onSubmit={onSubmit}>
            <CheckboxInput
              name="isTermsAccepted"
              label="I have read and understood the Terms and Conditions"
              color="primary"
              control={control}
              errors={errors}
              required
            />
            <Button onClick={onSubmit} disabled={!isTermsAccepted}>
              Accept Terms and Conditions
            </Button>
          </S.Form>
        </Box>
      )}
    </Box>
  );
};

export default VerifyAcceptedUserTerms;
