import { SelectOption } from 'react-select-material-ui';
import { useAxios } from '../../utils/hooks/useAxios';
import { PageableResponse } from '../../utils/types/PageableResponse';
import { Maturity } from './components/MaturityProfile/MaturityProfileChart.types';
import { LastVisitedFAs } from './screens/LastVisitedFacilityAgreements/LastVisitedFacilityAgreements.types';
import { EventFA, FacilityAgreement } from './FacilityAgreement.types';

export const useGetFacilityAgreements = (params: URLSearchParams) => {
  const [
    { data: facilityAgreementsData, loading: isDataLoading },
    getFacilityAgreements,
  ] = useAxios<PageableResponse<FacilityAgreement[]>>(
    {
      url: `/facility-agreements/search?page=0&pageSize=50`,
      params: Object.fromEntries(params.entries()),
    },
    { manual: true },
  );
  return { facilityAgreementsData, isDataLoading, getFacilityAgreements };
};

export const useGetHomeUpcomingEvents = () => {
  const [{ data: events, loading: isLoading }, getHomeEvents] = useAxios<
    EventFA[]
  >(`/calendar-events?count=6&sortKey=Date&sortDir=Asc`, {
    manual: true,
  });
  return { events, isLoading, getHomeEvents };
};

export const usePutLastVisitedFAs = () => {
  const [, putLastVisitedFAs] = useAxios<LastVisitedFAs[]>(
    {
      url: `/visit-logs`,
      method: 'PUT',
    },
    { manual: true },
  );

  return { putLastVisitedFAs };
};

export const useGetCurrencies = () => {
  const [{ data: currencies, loading: areCurrenciesLoading }, getCurrencies] =
    useAxios<string[]>(`/currencies/facility-agreements`, {
      manual: true,
    });

  const currencyOptions: SelectOption[] =
    currencies?.map((o) => ({
      value: o,
      label: o,
    })) || [];

  return { currencies, currencyOptions, areCurrenciesLoading, getCurrencies };
};

export const useGetMaturityProfile = (currency?: string) => {
  const [
    { data: maturityProfile, loading: isLoadingMaturityProfile },
    getMaturityProfile,
  ] = useAxios<Maturity>(
    `/maturity-profile${currency ? `?currency=${currency}` : ''}`,
    {
      manual: true,
    },
  );
  return { maturityProfile, isLoadingMaturityProfile, getMaturityProfile };
};
