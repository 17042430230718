import styled, { css } from 'styled-components';

const baseStyles = css<{
  disabled?: boolean;
  nopaddingleft?: string;
}>`
  text-decoration: 'none';
  cursor: ${({ disabled }) => disabled && 'default'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

export const ExternalLink = styled.a<{
  disabled?: boolean;
  nopaddingleft: string;
  hasMaxWidth?: boolean;
}>`
  ${baseStyles}
  display:${({ hasMaxWidth }) => (hasMaxWidth ? 'flex' : undefined)};
  padding-left: ${({ nopaddingleft }) => nopaddingleft === 'false' && '0.7em'};
  color: black;
  ${({ disabled }) =>
    !disabled &&
    `:after {
    content: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.826,2.824l-2.826,-2.824l6,0l0,6l-2.467,-2.467l-4.826,4.826l-0.707,-0.708l4.826,-4.826l0,-0.001z' fill='%2fff' fill-rule='evenodd'/%3E%3C/svg%3E");
    padding-left: 0.15rem;
  }`};
`;

export const ThreeDotsDiv = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
