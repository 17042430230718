import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FACILITIES } from 'router/url';
import { historyActions } from 'utils/constants';
import { mockEvent } from 'utils/mocks/mockChangeEvent';
import { RouteParam } from 'utils/types/RouteParam';

export const FacilitySections = {
  OVERVIEW: 'overview',
  COMMITMENT_FEE: 'commitment-fee',
  COMMITMENT_FEE_SCHEDULE: 'commitment-fee-schedule',
};

interface ExtendedChangeEvent extends React.ChangeEvent<{}> {
  ctrlKey?: boolean;
}
export const useTabMenu = () => {
  let { facilityId = 0, section = FacilitySections.OVERVIEW } =
    useParams<RouteParam>();
  const { push, action, replace } = useHistory();

  facilityId = Number(facilityId);

  const TabList: {
    readonly [key: string]: { value: string; linkTo?: string; label: string };
  } = {
    overview: {
      value: FacilitySections.OVERVIEW,
      linkTo: `${FACILITIES}/${facilityId}/${FacilitySections.OVERVIEW}`,
      label: 'Overview',
    },
    'commitment-fee': {
      value: FacilitySections.COMMITMENT_FEE,
      linkTo: `${FACILITIES}/${facilityId}/${FacilitySections.COMMITMENT_FEE}`,
      label: 'Commitment Fee',
    },
    'commitment-fee-schedule': {
      value: FacilitySections.COMMITMENT_FEE_SCHEDULE,
      linkTo: `${FACILITIES}/${facilityId}/${FacilitySections.COMMITMENT_FEE_SCHEDULE}`,
      label: 'Commitment Fee Schedule',
    },
  };

  const handleTabChange = useCallback(
    (_: ExtendedChangeEvent, tab: string) => {
      if (_.ctrlKey) {
        return;
      }
      setCurrentTab(tab);
      push(`${FACILITIES}/${facilityId}/${tab}`);
    },
    [facilityId, push],
  );

  const handlePopChange = useCallback(
    (_: React.ChangeEvent<{}>, tab: string) => {
      setCurrentTab(tab);
      replace(`${FACILITIES}/${facilityId}/${tab}`);
    },
    [replace, facilityId],
  );

  useEffect(() => {
    if (action === historyActions.POP) {
      handlePopChange(mockEvent, section);
    }
  }, [action, handlePopChange, section]);

  const [currentTab, setCurrentTab] = useState(
    TabList[section || 'overview']?.value,
  );

  return { TabList, currentTab, handleTabChange };
};
