interface Country {
  id: number;
  name: string;
  isoCode: string;
  isEUMember: boolean;
}

interface FeePayer {
  id: number;
  name: string;
  organizationNumber: string;
  country: Country;
  calendar: string;
}

export interface FacilityBasicInformation {
  borrowers: string[];
  id: number;
  name: string;
  availabilityPeriodStart: string;
  availabilityPeriodEnd: string;
  maturityDate: string;
  commitmentFeeApplicable: string;
  commitmentFeePayer: FeePayer;
  internalPaymentAgent: string;
  revolvingCreditFacility: string;
  arrangementFee: number;
  numberOfLoans: number;
  numberOfTrancheLoans: number;
  maxLoans: number;
  baseCurrency: string;
  optionalCurrencies: string[];
  optionalCurrenciesDisabled: boolean;
  accountingRuleType: string;
  accountingRuleTypeLabel: string;
  isMatureDateNotPassed: boolean;
  facilityAgreementID: number;
  facilityAgreementName: string;
  commitments: Commitment;
  isCommitmentFeeCreated?: boolean;
}

export interface Commitment {
  lenderCommitmentsCalculated: LenderCommitmentsCalculated[];
  totalCommitmentSummary: number;
  availableCommitmentSummary: number;
  currency: string;
}

interface LenderCommitmentsCalculated {
  lender: Lender;
  totalCommitment: number;
  availableCommitment: number;
}

export interface Lender {
  name: string;
  createdBy: string;
  modifiedBy: string;
  createdAt: string;
  modifiedAt: string;
  externalID: number;
  isDeleted: boolean;
}

export interface CommitmentFeeData {
  id: number;
  commitmentFeeStartDate: string;
  commitmentFeeEndDate: string;
  firstCommitmentFeePaymentYear: number | null;
  firstCommitmentFeePaymentMonth: number | null;
  firstCommitmentFeePaymentDay: number | null;
  calendarBusiness: string[];
  calendarQuotation: string[];
  remarks: string;
  notificationRemarks: string;
}

export type FacilityInterestScheduleReturnType = 'FIX' | 'FRN' | '';

export interface InterestPaymentType {
  id: number;
  name: string;
}

export interface CountConvention {
  id: number;
  name: string;
  description: string;
}

export interface InterestFloorConvention {
  value: number;
  label: string;
  shortName: string;
  description: string;
}

export interface ScheduleDefinition {
  id: number;
  commitmentFeeID: number;
  startDateYear: number;
  startDateMonth: number;
  startDateDay: number;
  adjustedDate: string;
  returnType: string;
  interestPaymentType: InterestPaymentType;
  dayCountConvention: string;
  dayCountConventionLabel: string;
  businessDayConvention: CountConvention;
  termsPerYear: string;
  termsPerYearLabel: string;
  remarks: string;
  interestRate: number;
  roundingRule: number;
  force28Feb: boolean;
  capRate: number;
  maximumFloatingRateIncrease: number;
  maximumFloatingRateDecrease: number;
  termsPerFixing: number;
  firstFixingTerm: number;
  floor: number;
  interestFloorConvention: InterestFloorConvention | null;
  interestFloorConventionLabel: string;
  referenceRate: string;
  margin: number;
  floorDailyRate?: string;
}

export enum FacilityDetailsDataSectionsType {
  LoanInterestSchedule = 'LoanInterestSchedule',
  LoanAmountAdjustment = 'LoanAmountAdjustment',
  FacilityCommitmentFeeSchedule = 'FacilityCommitmentFeeSchedule',
}

type InterestFloorConventionType =
  | 'NoFloor'
  | 'SpecifiedFloor'
  | 'ZeroFloor'
  | 'ZeroReferenceRateFloor'
  | 'Unknown';

export interface BasicInterestSchedule {
  id: number;
  index?: number;
  termStart: string | null;
  termEnd: string | null;
  dayCountConvention?: number | string | null;
  days: number | null;
  quotationDate?: string | null;
  referenceRateValue?: number | string | null;
  margin?: number | string | null;
  coupon?: number | string | null;
  floor?: number | string | null;
  cash?: number | string | null;
  pik?: number | string | null;
  capitalised?: number | string | null;
  adjust: boolean;
  modified: boolean;
  fixManually: boolean;
  isManual: boolean;
  containsManualPayments?: boolean;
  paymentDate: string | null;
  interestFloorConvention?: InterestFloorConventionType;
}

export interface FacilityInterestSchedule extends BasicInterestSchedule {
  paymentDate: string | null;
  isMatured?: boolean;
  periodId?: number;
  periodStartDate?: string;
  periodEndDate?: string;
  returnType?: FacilityInterestScheduleReturnType;
  containsManualPayments?: boolean;
  isManual: boolean;
}

export interface FacilityInterestSchedulePeriods {
  id: number;
  startDate: string;
  endDate: string;
  containsManualPayments: boolean;
  schedules: FacilityInterestSchedule[];
}

export interface FacilityInterestSchedulePeriodsData {
  containsUnpersistedChanges?: boolean;
  schedulePeriods?: FacilityInterestSchedulePeriods[];
}

export enum PaymentRecipient {
  Lenders = 'Lenders',
  Borrower = 'Borrower',
  NTS = 'NTS',
}

export enum PaymentType {
  Manual = 'Manual',
  Automatic = 'Automatic',
  Interest = 'Interest',
  AmountAdjustment = 'AmountAdjustment',
  AccruedInterest = 'AccruedInterest',
}

export interface Payment {
  id: number;
  commitmentFeeScheduleID: number;
  amount: number;
  currency: string;
  recipient: string;
  description: string;
  type: string;
  includeIntoPaymentNotification: boolean;
  errorMessage: string;
}

export interface LenderSplit {
  id: number;
  commitmentFeeSchedulePaymentID?: number;
  amountAdjustmentPaymentID?: number;
  interestSchedulePaymentID?: number;
  lenderID?: number;
  lenderName: string;
  amount: number;
  currency: string;
  isActive: boolean;
  share?: number;
}
