import { Grid } from '@mui/material';
import { FacilityBasicInformation } from '../../FacilityDetails.types';
import BasicInformation from './components/BasicInformation/BasicInformation';
import CommitmentsTable from './components/CommitmentsTable';
import Loans from './components/Loans/Loans';

interface Props {
  data?: FacilityBasicInformation;
  isLoading?: boolean;
}
const Overview = ({ data, isLoading }: Props) => (
  <Grid spacing={2} container>
    <Grid item xs={12} lg={5.5}>
      <BasicInformation facilityData={data} isLoading={isLoading} />
    </Grid>
    <Grid item xs={12} lg={6.5}>
      <CommitmentsTable
        isLoading={isLoading}
        data={data?.commitments?.lenderCommitmentsCalculated}
        currency={data?.commitments?.currency}
        summary={data?.commitments}
      />
    </Grid>
    <Grid item xs={12} paddingBottom="64px">
      <Loans />
    </Grid>
  </Grid>
);
export default Overview;
