import moment from 'moment';
import { AmountAdjustment } from 'modules/Loan/LoanDetails/LoanDetails.types';
import { getInitDate } from './formatters/dateFormatter';

export const findClosestToTodayAdjustment = (
  amountAdjustments: AmountAdjustment[],
) => {
  const currentDate = moment();
  const adjustmentsBeforeOrToday = amountAdjustments.filter((period) => {
    const settlementDate = getInitDate(period.settlementDate);
    return settlementDate?.isSameOrBefore(currentDate);
  });

  if (adjustmentsBeforeOrToday.length) {
    return adjustmentsBeforeOrToday[adjustmentsBeforeOrToday.length - 1];
  } else {
    // there are only future adjustments - return the first one - the closest to today
    return amountAdjustments[0];
  }
};
