import { LinkProps, Tooltip } from '@mui/material';
import * as S from './DataLinkBlack.css';

interface Props extends LinkProps {
  text?: string | JSX.Element;
  to?: string;
  tooltip?: string;
  noPaddingLeft?: boolean;
  maxWidth?: string;
}

const DataLinkBlack = ({
  text,
  to = '',
  tooltip = '',
  noPaddingLeft = false,
  maxWidth,
}: Props) => (
  <S.ExternalLink
    href={to}
    target="_blank"
    rel="noreferrer"
    nopaddingleft={noPaddingLeft.toString()}
    onClick={(e) => e.stopPropagation()}
    hasMaxWidth={!!maxWidth}
  >
    <Tooltip title={tooltip}>
      {maxWidth ? (
        <S.ThreeDotsDiv maxWidth={maxWidth}>
          <span>{text}</span>
        </S.ThreeDotsDiv>
      ) : (
        <span>{text}</span>
      )}
    </Tooltip>
  </S.ExternalLink>
);

export default DataLinkBlack;
