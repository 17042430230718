import { Dispatch, SetStateAction } from 'react';
import { Grid } from '@mui/material';
import { BasicAgreement } from '../../../FacilityAgreement.types';
import BasicInformation from './components/BasicInformation/BasicInformation';
import Facilities from './components/Facilities/Facilities';
import UpcomingEvents from './components/UpcomingEvents/UpcomingEvents';

interface Props {
  data?: BasicAgreement;
  isLoading?: boolean;
  setCurrency: Dispatch<SetStateAction<string | undefined>>;
  currency?: string;
}

const Overview = ({ data, isLoading, setCurrency, currency }: Props) => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={12} lg={8}>
        <BasicInformation
          data={data}
          isLoading={isLoading}
          setCurrency={setCurrency}
          currency={currency}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <UpcomingEvents />
      </Grid>
      <Grid item xs={12} paddingBottom="64px">
        <Facilities />
      </Grid>
    </Grid>
  );
};
export default Overview;
