const scrollToRow = (rowNumber: number, childrenScroll: boolean = false) => {
  // childrenScroll is used in Security Packages to scroll to added Asset (data-id is already used there as a Security Package Item's id)
  setTimeout(() => {
    const tableRowElement: HTMLDivElement | null = document.querySelector(
      childrenScroll
        ? `tr[data-children-id*='${rowNumber}']`
        : `tr[data-id*='${rowNumber}']`,
    );
    if (tableRowElement) {
      tableRowElement.scrollIntoView({
        block: 'center',
      });
    }
  }, 0);
};

export default scrollToRow;
