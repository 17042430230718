import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';
import { helperText } from 'utils/reactHookFormUtils';
import { ErrorLabel } from '../styles';

interface Props {
  required?: boolean;
  name: string;
  label: React.ReactNode;
  color?: 'primary' | 'secondary' | 'default' | undefined;
  control: Control<any>;
  errors: DeepMap<object, FieldError>;
  autoFocus?: boolean;
}

const TextInput = (props: Props) => {
  return (
    <div>
      <Controller
        defaultValue={false}
        name={props.name}
        render={({ field: { name, onChange, value } }) => (
          <FormControlLabel
            label={props.label}
            control={
              <Checkbox
                checked={value ?? false}
                onChange={(val) => onChange(val)}
                name={name}
                color={props.color}
                autoFocus={props.autoFocus}
              />
            }
          />
        )}
        control={props.control}
        rules={{
          ...(props.required && { required: 'This field is required' }),
        }}
      />
      <ErrorLabel>
        {props.errors && helperText(props.name, props.errors)}
      </ErrorLabel>
    </div>
  );
};

export default TextInput;
