import { useCallback, useMemo, useState } from 'react';
import { Filter } from '@material-table/core';
import useQuery from '../../../../../utils/hooks/useQuery';

export const useEventsQuery = () => {
  const query = useQuery();
  const pageSizeFromQuery = useMemo(
    () => Number(query.get('pageSize')) || 15,
    [query],
  );
  const pageFromQuery = useMemo(() => Number(query.get('page')) || 0, [query]);
  const phraseFromQuery = useMemo(() => query.get('phrase') || '', [query]);
  const levelNameFromQuery = useMemo(
    () => query.get('levelName') || '',
    [query],
  );

  const [pageSizeQuery, setPageSizeQuery] = useState(pageSizeFromQuery);
  const [pageQuery, setPageQuery] = useState(pageFromQuery);
  const [phraseQuery, setPhraseQuery] = useState(phraseFromQuery);
  const [levelNameQuery, setLevelNameQuery] = useState(levelNameFromQuery);

  const setFilter = useCallback((filters: Filter<object>[]) => {
    setPhraseQuery('');
    setLevelNameQuery('');
    setPageQuery(0);

    filters.forEach((f) => {
      switch (f.column.field) {
        case 'name':
          if (f.value.length >= 3) setPhraseQuery(f.value);
          break;
        case 'levelName':
          if (f.value.length >= 3) setLevelNameQuery(f.value);
          break;
      }
    });
  }, []);

  const params = useMemo(
    () => ({
      phrase: phraseQuery,
      levelName: levelNameQuery,
      page: pageQuery,
      pageSize: pageSizeQuery,
    }),
    [levelNameQuery, pageQuery, pageSizeQuery, phraseQuery],
  );

  const searchQuery = useMemo(() => {
    let searchQuery: string[][] = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value !== '') {
        searchQuery.push([key, `${value}`]);
      }
    });
    return new URLSearchParams(searchQuery);
  }, [params]);

  return useMemo(
    () => ({
      setFilter,
      searchQuery,
      pageSizeQuery,
      pageQuery,
      setPageSizeQuery,
      setPageQuery,
      phraseQuery,
      levelNameQuery,
    }),
    [
      levelNameQuery,
      pageQuery,
      pageSizeQuery,
      phraseQuery,
      searchQuery,
      setFilter,
    ],
  );
};
