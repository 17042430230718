import { ReactText } from 'react';
import BigNumber from 'bignumber.js';

export const absoluteNumberFormatter = (
  amount?: number,
  options?: Intl.NumberFormatOptions,
) => {
  if (amount === undefined || amount === null) {
    return 'N/A';
  }
  return Math.abs(amount).toLocaleString('nb-NO', options);
};

// TODO: numberFormatter and numberFormatterWithEmptySpaceWhenFalsy can be merged into one function

export const numberFormatter = (
  amount?: string | number | null,
  options?: Intl.NumberFormatOptions,
  na: boolean = true,
) => {
  if (amount === undefined || amount === null || amount === '') {
    if (na) {
      return 'N/A';
    } else {
      return '';
    }
  }
  return amount.toLocaleString('nb-NO', options);
};

export const numberFormatterWithMinimumFractionDigits = (
  amount?: string | number | null,
) => numberFormatter(amount, { minimumFractionDigits: 2 });

export const numberFormatterWithEmptySpaceWhenFalsy = (
  amount?: string | number | null,
  options?: Intl.NumberFormatOptions,
) => {
  if (amount === undefined || amount === null || amount === '') {
    return '';
  }
  return typeof amount === 'string'
    ? stringToNumberFormatter(amount).toLocaleString('nb-NO', options)
    : amount.toLocaleString('nb-NO', options);
};

// TODO - try to refactor these function with 'toLocaleString' options (minimumFractionDigits etc...)
export const decimalNumberFormatter = (
  value?: ReactText | null,
  reverted = false,
): string => {
  if (typeof value === 'undefined' || value === null) return '';
  if (value === '-') return '0';
  return reverted
    ? `${String(value).replace(',', '.')}`
    : `${String(value).replace('.', ',')}`;
};

export const getPayloadDecimalNumber = (value?: ReactText | null) =>
  parseFloat(decimalNumberFormatter(value, true));

export const stringToNumberFormatter = (value?: string | null) =>
  parseFloat(value?.replace(',', '.')?.split(' ')?.join('') ?? '');

export const numberToStringFormatter = (value?: number | null) =>
  `${String(value).replace('.', ',')}`;

export const stringWithoutSpaces = (value: string | number | null) => {
  return `${String(value).replace(/\s/g, '')}`;
};

export const isValidNumber = (value: number | string | null | undefined) => {
  return (
    value !== '' &&
    value !== null &&
    value !== undefined &&
    !new BigNumber(decimalNumberFormatter(value, true)).isNaN()
  );
};
// distinguish percentage values between 0 and empty value:
export const getDistinguishedNumber = (
  value: number | string | null | undefined,
) =>
  isValidNumber(value)
    ? new BigNumber(decimalNumberFormatter(value, true))
    : null;

export const numberFormatterWithDecimalDigits = (
  value?: number | string | null,
  minDecimalScale: number = 2,
  maxDecimalScale?: number,
) => {
  if (typeof value === 'number') {
    //Display at least minDecimalScale
    //ex. 1 -> 1,00; 1.1 -> 1,10; 1.123 -> 1,123
    const [, decimal] = String(value).split('.');
    if (decimal && decimal.length > minDecimalScale) {
      return maxDecimalScale
        ? decimalNumberFormatter(value.toFixed(maxDecimalScale))
        : decimalNumberFormatter(value);
    } else {
      return decimalNumberFormatter(value.toFixed(minDecimalScale));
    }
  } else {
    return '';
  }
};

export const decimalNumberWithPercentFormatter = (
  value?: number | string | null,
  decimalScale?: number,
  maxDecimalScale?: number,
) => {
  const formattedValue = numberFormatterWithDecimalDigits(
    value,
    decimalScale ?? 2,
    maxDecimalScale,
  );
  // if formattedValue has value (is not empty string) => add %,
  // if formattedValue has no value (is empty string) => return empty string
  return formattedValue ? `${formattedValue} %` : formattedValue;
};

const changeKeyToZeros = (key: string) => {
  switch (key) {
    case 'k':
      return '000';
    case 'm':
      return '000000';
    case 'b':
      return '000000000';
    default:
      return '';
  }
};

export const fillNumberWithZeros = (value: string, key: string) => {
  if (value.includes(',')) {
    const [wholePart, fractionPart] = value.split(',');
    const newValue = `${wholePart}${key}`.replace(key, changeKeyToZeros(key));
    return `${newValue},${fractionPart}`;
  } else {
    return `${value}${key}`.replace(key, changeKeyToZeros(key));
  }
};
