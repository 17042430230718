import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from '@material-table/core';
import {
  ColumnWithTooltip,
  Container,
  Table,
} from '@nordictrustee/nt-ui-library';
import {
  columnRightText,
  firstColPadding,
  tableRowHeight,
} from '../../../../../../utils/constants';
import { currencyFormatter } from '../../../../../../utils/formatters/currencyFormatter';
import { RouteParam } from '../../../../../../utils/types/RouteParam';
import { useGetLenders } from '../../../api';
import { Lender } from '../../../LoanDetails.types';

const Lenders = () => {
  const { loanId } = useParams<RouteParam>();
  const { lenders, isLoading, getLenders } = useGetLenders(loanId);

  useEffect(() => {
    loanId && getLenders();
  }, [getLenders, loanId]);
  const columns: Column<Lender>[] = [
    {
      field: 'companyName',
      title: 'Company Name',
      width: '55%',
      render: ({ lender }) => (
        <ColumnWithTooltip title={lender?.name}>
          {lender?.name}
        </ColumnWithTooltip>
      ),
      ...firstColPadding,
    },
    {
      field: 'outstandingAmount',
      title: 'Outstanding amount',
      width: '45%',
      render: ({ outstandingAmount, currency }) => (
        <ColumnWithTooltip
          title={currencyFormatter(outstandingAmount, currency)}
        >
          {currencyFormatter(outstandingAmount, currency)}
        </ColumnWithTooltip>
      ),
      ...columnRightText,
    },
  ];
  return (
    <Container
      containerHeight="100%"
      title="Lenders"
    >
      <Table
        columns={columns}
        data={lenders}
        options={{
          search: false,
          toolbar: false,
          paging: false,
          tableLayout: 'fixed',
          maxBodyHeight: '30vh',
          minBodyHeight: '30vh',
          rowStyle: { height: tableRowHeight },
        }}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default Lenders;
