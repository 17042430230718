import { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Grid, Typography } from '@mui/material';
import { MaturedChip, TabMenu, TabPanel } from '@nordictrustee/nt-ui-library';
import { FACILITY_AGREEMENTS } from '../../router/url';
import {
  facilityAgreementColor,
  facilityColor,
  homeMainColor,
} from '../../themes/theme';
import { RouteParam } from '../../utils/types/RouteParam';
import CommitmentFee from './screens/CommitmentFee';
import CommitmentFeeSchedule from './screens/CommitmentFeeSchedule/CommitmentFeeSchedule';
import Overview from './screens/Overview';
import {
  useGetCommitmentFees,
  useGetCommitmentFeeSchedules,
  useGetFacilityBasicInfo,
} from './api';
import { useTabMenu } from './useTabMenu';
import { StyleContainer } from './FacilityDetails.css';

const FacilityDetails = () => {
  const { TabList, currentTab, handleTabChange } = useTabMenu();
  const { facilityId } = useParams<RouteParam>();

  const { commitmentFee, isLoadingCommitmentFees, getCommitmentFees } =
    useGetCommitmentFees(facilityId);

  const {
    commitmentFeeSchedules,
    isLoadingCommitmentFeeSchedules,
    getCommitmentFeeSchedules,
  } = useGetCommitmentFeeSchedules(`${commitmentFee?.id}`);

  const { facilityBasicInfo, isFacilityLoading, getFacilityBasicInfo } =
    useGetFacilityBasicInfo(facilityId);

  useEffect(() => {
    facilityId && getFacilityBasicInfo();
  }, [facilityId, getFacilityBasicInfo]);

  useEffect(() => {
    if (facilityBasicInfo?.isCommitmentFeeCreated) {
      getCommitmentFees();
    }
  }, [facilityBasicInfo?.isCommitmentFeeCreated, getCommitmentFees]);

  useEffect(() => {
    if (commitmentFee?.id) {
      getCommitmentFeeSchedules();
    }
  }, [commitmentFee?.id, getCommitmentFeeSchedules]);

  return (
    <StyleContainer disableGutters maxWidth="xl">
      <Box mt={2} mb={1}>
        <Breadcrumbs>
          <NavLink to={FACILITY_AGREEMENTS} style={{ color: homeMainColor }}>
            Home
          </NavLink>
          <NavLink
            style={{ color: facilityAgreementColor.breadcrumb }}
            to={`${FACILITY_AGREEMENTS}/${facilityBasicInfo?.facilityAgreementID}/overview`}
          >
            {facilityBasicInfo?.facilityAgreementName}
          </NavLink>
        </Breadcrumbs>
        <Box mt={1} mb={2}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography
                variant="h2"
                color={
                  !facilityBasicInfo?.isMatureDateNotPassed
                    ? 'secondary'
                    : facilityColor.header
                }
              >
                {facilityBasicInfo?.name}
              </Typography>
            </Grid>
            {facilityBasicInfo && !facilityBasicInfo?.isMatureDateNotPassed && (
              <MaturedChip />
            )}
          </Grid>
        </Box>
      </Box>
      <Box mt={1} mb={1}>
        <TabMenu
          items={TabList}
          tabsValue={currentTab}
          handleChange={handleTabChange}
          isDividerVisible
        />
      </Box>
      <Box>
        <TabPanel currentTab={currentTab} value={TabList.overview.value}>
          <Overview isLoading={isFacilityLoading} data={facilityBasicInfo} />
        </TabPanel>
        <TabPanel
          currentTab={currentTab}
          value={TabList['commitment-fee'].value}
        >
          <CommitmentFee
            data={facilityBasicInfo?.commitments}
            feePayer={facilityBasicInfo?.commitmentFeePayer?.name}
            commitmentFee={commitmentFee}
            isLoadingCommitmentFees={isLoadingCommitmentFees}
            commitmentFeeSchedules={commitmentFeeSchedules}
            isLoadingCommitmentFeeSchedules={isLoadingCommitmentFeeSchedules}
            getCommitmentFeeSchedules={getCommitmentFeeSchedules}
          />
        </TabPanel>
        <TabPanel
          currentTab={currentTab}
          value={TabList['commitment-fee-schedule'].value}
        >
          <CommitmentFeeSchedule
            commitmentFeeSchedules={commitmentFeeSchedules}
            isLoadingCommitmentFeeSchedules={isLoadingCommitmentFeeSchedules}
            getCommitmentFeeSchedules={getCommitmentFeeSchedules}
          />
        </TabPanel>
      </Box>
    </StyleContainer>
  );
};

export default FacilityDetails;
