import { Grid } from '@mui/material';
import { LoanBasicInfo } from '../LoanDetails.types';
import BasicInformation from './components/BasicInformation';
import InterestSchedulePreview from './components/InterestSchedulePreview';
import Lenders from './components/Lenders/Lenders';
import ScheduleDefinitions from './components/ScheduleDefinitions';

interface Props {
  data?: LoanBasicInfo;
  isLoading?: boolean;
}

const Overview = ({ data, isLoading }: Props) => (
  <Grid container spacing={2}>
    <Grid item xs={12} lg={5}>
      <BasicInformation loanDetails={data} isLoading={isLoading} />
    </Grid>
    <Grid item xs={12} lg={7}>
      <ScheduleDefinitions />
    </Grid>
    <Grid item container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Lenders />
      </Grid>
      <Grid item xs={12} lg={9}>
        <InterestSchedulePreview />
      </Grid>
    </Grid>
  </Grid>
);

export default Overview;
