import { useAxios } from 'utils/hooks/useAxios';
import {
  ComplianceScheduleItem,
  CovenantDocument,
} from './ComplianceSchedule.types';

export const useGetComplianceScheduleItem = (assignmentId?: string) => {
  const [
    {
      data: complianceScheduleItemData = [],
      loading: isLoadingComplianceScheduleItem = false,
    },
    getComplianceScheduleItem,
  ] = useAxios<ComplianceScheduleItem[]>(
    `/compliance-schedule-items?facilityAgreementID=${assignmentId}`,
    { manual: true }
  );

  return {
    complianceScheduleItemData,
    isLoadingComplianceScheduleItem,
    getComplianceScheduleItem,
  };
};

export const useGetCovenantDocuments = (scheduleId: number) => {
  const [
    { data: covenantDocumentsData, loading: isLoadingCovenantDocuments },
    getCovenantDocuments,
  ] = useAxios<CovenantDocument[]>(
    `/compliance-schedule-items/${scheduleId}/covenant-documents`,
    { manual: true }
  );

  return {
    covenantDocumentsData,
    isLoadingCovenantDocuments,
    getCovenantDocuments,
  };
};
