import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { Route } from 'react-router-dom';
import {
  createReactRouterV5Options,
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
  ReactRouterHistory,
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { ThemeProvider, ToastProvider } from '@nordictrustee/nt-ui-library';
import { AuthProvider } from 'context/AuthProvider/AuthProvider';
import { createBrowserHistory } from 'history';
import Routes from './router/routes';
import { EnvironmentName } from './utils/types/EnvironmentName';

const App = () => {
  useEffect(() => {
    const {
      ENVIRONMENT_NAME,
      GOOGLE_ANALYTICS_ID,
      REACT_APP_HOTJAR_ID,
      GRAFANA_URL,
    } = window._env_;

    if (ENVIRONMENT_NAME && ENVIRONMENT_NAME !== EnvironmentName.prod) {
      const favicon = document.getElementById('favicon');
      // favicon-test.ico | favicon-uat.ico | favicon-hotfix.ico | favicon-local.ico
      favicon?.setAttribute('href', `/favicon-${ENVIRONMENT_NAME}.ico`);
    }

    if (GOOGLE_ANALYTICS_ID !== 'disabled') {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID);
      ReactGA.send({
        hitType: 'pageview',
        page: document.location.pathname,
        title: document.title,
      });
    }

    if (REACT_APP_HOTJAR_ID !== 'disabled') {
      const hotjarSnippetVersion = 6;
      hotjar.initialize(REACT_APP_HOTJAR_ID, hotjarSnippetVersion);
    }

    if (GRAFANA_URL) {
      initializeFaro({
        url: GRAFANA_URL,
        app: {
          name: 'Loan-portal-frontend-UI',
          version: process.env.REACT_APP_VERSION,
          environment: ENVIRONMENT_NAME,
        },

        instrumentations: [
          // Mandatory, omits default instrumentations otherwise.
          ...getWebInstrumentations({
            captureConsole: ENVIRONMENT_NAME === 'prod',
          }),
          // Tracing package to get end-to-end visibility for HTTP requests.
          new TracingInstrumentation(),

          // React integration for React applications.
          new ReactIntegration({
            router: createReactRouterV5Options({
              history: createBrowserHistory() as ReactRouterHistory, // the history object used by react-router
              Route, // Route component imported from react-router package
            }),
          }),
        ],
      });
    }
  }, []);

  return (
    <AuthProvider>
      <ToastProvider />
      <ThemeProvider>
        <Routes />
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
