import { useAxios } from 'utils/hooks/useAxios';
import { getFormattedSchedulesData } from 'utils/schedules.utils';
import { FacilityInterestSchedulePeriodsData } from './../../FacilityDetails/FacilityDetails.types';
import {
  AmountAdjustment,
  Lender,
  LoanBasicInfo,
  LoanScheduleDefinition,
} from './LoanDetails.types';

export const useGetInterestSchedules = (loanId?: string) => {
  const [
    { data: interestSchedulesPeriodData, loading: isLoadingInterestSchedules },
    getInterestSchedules,
  ] = useAxios<FacilityInterestSchedulePeriodsData>(
    `loans/${loanId}/interest-schedules`,
    {
      manual: true,
    },
  );
  return {
    interestSchedulesData: getFormattedSchedulesData(
      interestSchedulesPeriodData!,
    ),
    isLoadingInterestSchedules,
    getInterestSchedules,
  };
};

export const useGetLoanBasicInfo = (loanId?: string) => {
  const [{ data: loanBasicInfo, loading: isLoading }, getLoanBasicInfo] =
    useAxios<LoanBasicInfo>(`/loans/${loanId}`, {
      manual: true,
    });
  return { loanBasicInfo, isLoading, getLoanBasicInfo };
};

export const useGetAmountAdjustmentsData = (loanId?: string) => {
  const [
    { data: amountAdjustmentsData, loading: isLoadingAmountAdjustmentsData },
    getAmountAdjustmentsData,
  ] = useAxios<AmountAdjustment[]>(`/loans/${loanId}/amount-adjustments`, {
    manual: true,
  });
  return {
    amountAdjustmentsData,
    isLoadingAmountAdjustmentsData,
    getAmountAdjustmentsData,
  };
};

export const useGetLoanScheduleDefinitions = (loanId?: string) => {
  const [
    { data: definitions = [], loading: isLoading },
    getScheduleDefinitions,
  ] = useAxios<LoanScheduleDefinition[]>(
    `/loans/${loanId}/interest-schedule-definitions`,
    {
      manual: true,
    },
  );
  return { definitions, isLoading, getScheduleDefinitions };
};

export const useGetLenders = (loanId?: string) => {
  const [{ data: lenders = [], loading: isLoading }, getLenders] = useAxios<
    Lender[]
  >(`/loans/${loanId}/lenders`, {
    manual: true,
  });
  return { lenders, isLoading, getLenders };
};
