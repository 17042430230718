import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Column, Options } from '@material-table/core';
import { Chip, Grid, Typography } from '@mui/material';
import {
  ColumnWithTooltip,
  Container as CustomContainer,
  DateProgressBar,
  InfoDoughnut,
  Table,
} from '@nordictrustee/nt-ui-library';
import moment from 'moment';
import {
  centerColumnText,
  columnRightTextWithPadding,
  dateDisplayFormat,
  rightText,
  searchColPadding,
  searchFieldDebounceInterval,
  tableRowHeight,
} from 'utils/constants';
import { currencyFormatter } from '../../utils/formatters/currencyFormatter';
import * as URL from './../../router/url';
import LastVisitedFacilityAgreements from './screens/LastVisitedFacilityAgreements/LastVisitedFacilityAgreements';
import MaturityProfile from './screens/MaturityProfile/MaturityProfile';
import HomeUpcomingEvents from './screens/UpcommingEvents/HomeUpcomingEvents';
import { useGetFacilityAgreements } from './api';
import { FacilityAgreement } from './FacilityAgreement.types';
import { useFacilityAgreementsQuery } from './useFacilityAgreementsQuery';
import {
  ExternalLink,
  StyleContainer,
  TableContainer,
} from './FacilityAgreement.css';

const columnRightTextWithCustomPadding = {
  cellStyle: { ...rightText, paddingRight: '2rem' },
  headerStyle: { ...rightText, paddingRight: '2rem' },
};

const columns: Column<FacilityAgreement>[] = [
  {
    field: 'name',
    title: 'Facility agreement',
    render: ({ name, id }: FacilityAgreement) => (
      <ColumnWithTooltip title={name}>
        <ExternalLink
          to={`${URL.FACILITY_AGREEMENTS}/${id}/overview`}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {name}
        </ExternalLink>
      </ColumnWithTooltip>
    ),
    width: '40%',
    ...searchColPadding,
  },
  {
    field: 'status',
    title: '',
    width: '90px',
    render: ({ isMatured }) =>
      isMatured && (
        <Chip
          color="warning"
          variant="outlined"
          label="Matured"
          size="small"
          style={{ width: 'min-content' }}
        />
      ),
  },
  {
    field: 'facilitiesCount',
    title: '# Facilities',
    width: '10%',
    ...columnRightTextWithCustomPadding,
  },
  {
    field: 'progress',
    title: 'Progress',
    width: '260px',
    ...centerColumnText,
    render: ({ startDate, finalMaturityDate }) => (
      <DateProgressBar
        legendDisplay="below"
        startDate={startDate ? moment(startDate, dateDisplayFormat) : null}
        endDate={
          finalMaturityDate
            ? moment(finalMaturityDate, dateDisplayFormat)
            : null
        }
        height={20}
      />
    ),
  },
  {
    field: 'availableCommitment',
    title: 'Available commitment',
    render: ({
      availableCommitment,
      currency,
      totalCommitment,
      id,
    }: FacilityAgreement) => {
      return (
        <InfoDoughnut
          currentValue={availableCommitment}
          maximumValue={totalCommitment}
          currentValueLabel="Outstanding"
          currency={currency}
          size={1}
          key={id.toString()}
          hideLabels
        />
      );
    },
    width: '230px',
    ...centerColumnText,
  },
  {
    field: 'totalCommitment',
    title: 'Total commitment',
    render: ({ totalCommitment, currency }: FacilityAgreement) => {
      const title = currencyFormatter(totalCommitment, currency);
      return <ColumnWithTooltip title={title}>{title}</ColumnWithTooltip>;
    },
    width: '15%',
    ...columnRightTextWithPadding,
  },
];

const FacilityAgreements = () => {
  const { push } = useHistory();
  const {
    setSearchTextQuery,
    searchQuery,
    searchTextQuery,
    currencyQuery,
    setCurrencyQuery,
  } = useFacilityAgreementsQuery();
  const [search, setSearch] = useState(searchTextQuery);

  const { facilityAgreementsData, isDataLoading, getFacilityAgreements } =
    useGetFacilityAgreements(searchQuery);

  useEffect(() => {
    if (currencyQuery !== undefined) {
      getFacilityAgreements();
    }
  }, [currencyQuery, getFacilityAgreements]);

  useEffect(() => {
    push({
      pathname: `${URL.FACILITY_AGREEMENTS}`,
      search: searchQuery.toString(),
    });
  }, [searchQuery, push]);

  const onSearchChange = useCallback(
    (value: string) => {
      setSearchTextQuery(value?.length >= 3 ? value : '');
      setSearch(value);
    },
    [setSearchTextQuery],
  );

  const options: Options<FacilityAgreement> = useMemo(
    () => ({
      maxBodyHeight: '40.5vh',
      minBodyHeight: '10vh',
      tableLayout: 'fixed',
      searchText: search,
      searchAutoFocus: true,
      searchFieldAlignment: 'left',
      debounceInterval: searchFieldDebounceInterval,
      searchFieldStyle: { width: '230px' },
      rowStyle: { height: tableRowHeight },
      paging: false,
    }),
    [search],
  );

  const onRowClick = (_: any, item: FacilityAgreement) => {
    push(`${URL.FACILITY_AGREEMENTS}/${item.id}/overview`);
  };

  return (
    <StyleContainer disableGutters maxWidth="xl">
      <Grid spacing={2} container mt={2}>
        <Grid item xs={12} lg={4}>
          <HomeUpcomingEvents />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MaturityProfile
            setCurrency={setCurrencyQuery}
            currency={currencyQuery}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <LastVisitedFacilityAgreements />
        </Grid>
        <Grid item xs={12} mb={3}>
          <CustomContainer maxWidth="100%" isHeader={false} noBottomBorder>
            <TableContainer>
              <Table
                title={
                  <Typography variant="h3" paddingRight="1rem">
                    Facility Agreements
                  </Typography>
                }
                columns={columns}
                data={facilityAgreementsData?.results ?? []}
                isLoading={isDataLoading || currencyQuery === undefined}
                onSearchChange={onSearchChange}
                disableDefaultSearchAndFilter
                options={options}
                localization={{
                  toolbar: {
                    searchPlaceholder: 'Search for agreement',
                  },
                }}
                onRowClick={onRowClick}
              />
            </TableContainer>
          </CustomContainer>
        </Grid>
      </Grid>
    </StyleContainer>
  );
};

export default FacilityAgreements;
