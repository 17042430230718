import { Container, DataView } from '@nordictrustee/nt-ui-library';
import { currencyFormatter } from 'utils/formatters/currencyFormatter';
import { getFormattedCalendersValue } from 'utils/formatters/getFormattedCalendarsValue';
import { getUnadjustedDate } from 'utils/formatters/unadjustedDateFormatter';
import { LoanBasicInfo } from '../../../LoanDetails.types';

interface Props {
  loanDetails?: LoanBasicInfo;
  isLoading?: boolean;
}

const BasicInformation = ({ loanDetails, isLoading }: Props) => {
  const firstInterestPaymentDate = getUnadjustedDate(
    loanDetails?.firstInterestPaymentDay,
    loanDetails?.firstInterestPaymentMonth,
    loanDetails?.firstInterestPaymentYear,
  );

  const loanCurrency = loanDetails?.utilisationRequest?.currency;

  const data = [
    {
      data: [
        {
          label: 'Borrower',
          value: loanDetails?.utilisationRequest?.borrower?.name,
        },
        {
          label: 'Loan amount',
          value: currencyFormatter(loanDetails?.loanAmount, loanCurrency),
        },
        {
          label: 'Outstanding amount',
          value: currencyFormatter(
            loanDetails?.outstandingAmount,
            loanCurrency,
          ),
        },
        { label: 'Loan start date', value: loanDetails?.startDate },
        { label: 'Maturity date', value: loanDetails?.maturityDate },
        {
          label: 'Early redeemed date',
          value: loanDetails?.earlyRedeemedDate,
        },
        {
          label: 'First interest payment date (unadjusted)',
          value: firstInterestPaymentDate,
        },
        {
          label: 'Calendar (business days)',
          value: getFormattedCalendersValue(loanDetails?.calendarBusiness),
        },
        {
          label: 'Calendar (quotation days)',
          value: getFormattedCalendersValue(loanDetails?.calendarQuotation),
        },
      ],
    },
  ];

  return (
    <Container
      containerHeight="100%"
      title="Basic information"
      isLoadingContent={isLoading}
      width="100%"
    >
      <DataView
        columns={1}
        data={data}
        extendContainer
        extendInfoColumnContainer
      />
    </Container>
  );
};

export default BasicInformation;
