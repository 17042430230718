import { useAxios } from 'utils/hooks/useAxios';

export const usePatchUserAcceptTerms = () => {
  const [, patchUserAcceptTerms] = useAxios(
    {
      url: `/me/terms-and-conditions`,
      method: 'PATCH',
    },
    {
      manual: true,
    },
  );
  return { patchUserAcceptTerms };
};
