import { DataView } from '@nordictrustee/nt-ui-library';
import { decimalNumberWithPercentFormatter } from 'utils/formatters/numberFormatter';
import { LoanScheduleDefinition } from '../../../../../../../LoanDetails.types';

interface Props {
  scheduleData: LoanScheduleDefinition;
}

const BasicInformation = ({ scheduleData }: Props) => {
  const baseData = [
    {
      label: 'Return type',
      value: scheduleData?.returnType,
    },
    {
      label: 'Day count convention',
      value: scheduleData?.dayCountConventionLabel,
    },
    {
      label: 'Business day convention',
      value: scheduleData?.businessDayConvention?.name,
    },
    {
      label: 'Terms per year',
      value: scheduleData?.termsPerYearLabel,
    },
  ];

  const dataFRN = () => {
    const data = [];
    if (scheduleData?.margin || scheduleData?.margin === 0) {
      data.push({
        label: 'Margin',
        value: decimalNumberWithPercentFormatter(scheduleData.margin),
      });
    }
    if (scheduleData?.referenceRateLabel) {
      data.push({
        label: 'Reference rate',
        value: scheduleData.referenceRateLabel,
      });
    }
    if (
      scheduleData?.quotationDayFixing ||
      scheduleData?.quotationDayFixing === 0
    ) {
      data.push({
        label: 'Quotation day fixing',
        value: `${scheduleData.quotationDayFixing}`,
      });
    }
    if (scheduleData?.quotationDayRuleLabel) {
      data.push({
        label: 'Quotation day rule',
        value: scheduleData?.quotationDayRuleLabel,
      });
    }
    if (scheduleData?.interestFloorConvention) {
      data.push({
        label: 'Interest floor convention',
        value: scheduleData?.interestFloorConvention?.label,
      });
    }
    if (scheduleData?.floor || scheduleData?.floor === 0) {
      data.push({
        label: 'Floor',
        value: decimalNumberWithPercentFormatter(scheduleData.floor),
      });
    }
    if (scheduleData?.interestPaymentType) {
      data.push({
        label: 'Payment type',
        value: scheduleData.interestPaymentType?.name,
      });
    }

    return data;
  };

  const extraData =
    scheduleData?.returnType === 'FIX'
      ? [
          {
            label: 'Interest Rate',
            value: decimalNumberWithPercentFormatter(
              scheduleData?.interestRate,
            ),
          },
          {
            label: 'Payment type',
            value: `${scheduleData?.interestPaymentType?.name}`,
          },
        ]
      : dataFRN();

  const data = [
    {
      data: [...baseData, ...extraData],
    },
  ];

  return (
    <DataView
      data={data}
      minRows={6}
      maxRows={6}
      extendContainer
      extendInfoColumnContainer
    />
  );
};

export default BasicInformation;
