import styled from 'styled-components';

export const CurrencyContainer = styled.div`
  display: flex;
  padding: 1rem 1rem 4px 1rem;
  justify-content: space-between;
`;

export const SelectWrapper = styled.div<{ hideContainer?: boolean }>`
  width: 80px;
  margin-top: -12px;
  .MuiFormLabel-root,
  .MuiFormLabel-root + div {
    margin-top: 8px;
  }
  visibility: ${(props) => (props.hideContainer ? 'hidden' : 'visible')};
`;

export const CurrencyWrapper = styled.span`
  margin: 1rem 1rem 1rem 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const PaddingDiv = styled.div`
  padding: 0 1rem 1rem 1rem;
  position: relative;
  height: 15.9rem;
`;
