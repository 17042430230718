import { useAxios } from 'utils/hooks/useAxios';

interface User {
  firstName: string;
  lastName: string;
  email: string;
  inviteSent: string;
  termsAccepted: boolean;
}

export const useGetMe = () => {
  const [{ data: me, loading: isLoadingMe }, getMe] = useAxios<User>(
    {
      url: '/me',
    },
    { manual: true },
  );
  return {
    me,
    isLoadingMe,
    getMe,
  };
};
