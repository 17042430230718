import { Dispatch, SetStateAction } from 'react';
import { Container } from '@nordictrustee/nt-ui-library';
import { AmountAdjustment } from '../../../LoanDetails.types';
import AmountAdjustmentTable from './components/AmountAdjustmentTable';

interface Props {
  amountAdjustmentsData?: AmountAdjustment[];
  isLoadingAmountAdjustmentsData: boolean;
  selectedItemId: number;
  setSelectedItemId: Dispatch<SetStateAction<number>>;
}

const AmountAdjustmentData = ({
  amountAdjustmentsData,
  isLoadingAmountAdjustmentsData,
  selectedItemId,
  setSelectedItemId,
}: Props) => {
  return (
    <Container
      containerHeight="100%"
      title="Amount adjustments"
    >
      <AmountAdjustmentTable
        amountAdjustmentsData={amountAdjustmentsData}
        isLoadingAmountAdjustmentsData={isLoadingAmountAdjustmentsData}
        selectedItemId={selectedItemId}
        setSelectedItemId={setSelectedItemId}
      />
    </Container>
  );
};

export default AmountAdjustmentData;
