import accounting from 'accounting';
import { currencyDecimalSeparator } from 'utils/constants';
import { numberFormatterWithMinimumFractionDigits } from './numberFormatter';

export const currencyFormatter = (
  value?: number | null,
  currency?: string | null,
) =>
  value || value === 0
    ? `${numberFormatterWithMinimumFractionDigits(value)} ${currency || ''}`
    : undefined;

export const currencyPayload = (value?: string | number | null) => {
  if (typeof value === 'undefined' || value === null) return undefined;

  //remove spaces and replace currencyDecimalSeparator with dot
  return String(value)
    .replace(/\s/g, '')
    .replace(currencyDecimalSeparator, '.');
};

export const humanizeMoney = (
  currency: string,
  value: number,
  isMaxOneDecimal = false,
): string => {
  let formattedValue = value;
  let sign: '' | '-' = '';

  if (formattedValue < 0) {
    formattedValue = Math.abs(value);
    sign = '-';
  }

  const suffix = ['', 'k', 'm', 'bn'];
  let i = 0;
  while (formattedValue >= 1000 && i < suffix.length - 1) {
    i += 1;
    formattedValue = ((formattedValue / 1000) * 100) / 100;
  }

  if (isMaxOneDecimal) {
    return `${sign}${Math.round(formattedValue * 10) / 10} ${
      suffix[i]
    }${currency}`;
  }
  return `${sign}${formattedValue} ${suffix[i]}${currency}`;
};

export function formatMoney(
  value: number,
  currency?: string,
  values?: number[],
) {
  const formattedValue = accounting.formatMoney(value, currency || '');
  const matches = formattedValue.match(/[a-zA-Z]+/g);
  const currencyStr = currency && matches ? matches[0] : '';

  if (!values || values.length === 0) {
    return `${currencyStr} ${formattedValue
      .replace(' ', '')
      .replace(currencyStr, '')}`;
  }

  const longestValue = values.sort(
    (a, b) => b.toFixed().length - a.toFixed().length,
  )[0];
  const negativeValueBuffer = value < 0 ? 1 : 0;
  const spaces =
    longestValue.toFixed().length > value.toFixed().length
      ? longestValue.toFixed().length - value.toFixed().length
      : 0;

  const numberOfCommas = (
    formattedValue.replace(currencyStr, '').match(/,/g) || []
  ).length;
  const longestNumberOfCommas = (
    accounting
      .formatMoney(longestValue, currency)
      .replace(currencyStr, '')
      .match(/,/g) || []
  ).length;

  const commaSpaces = longestNumberOfCommas - numberOfCommas;

  // Visually, a number corresponds to two spaces, so each number missing represents 2 spaces
  const repeatTimes = spaces * 2 + commaSpaces + negativeValueBuffer;

  return (
    currencyStr +
    ' '.repeat(repeatTimes) +
    formattedValue.replace(currencyStr, '')
  );
}
