import { useEffect, useState } from 'react';
import { Container } from '@nordictrustee/nt-ui-library';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { RefetchOptions } from 'axios-hooks';
import {
  FacilityInterestSchedule,
  FacilityInterestSchedulePeriodsData,
} from 'modules/FacilityDetails/FacilityDetails.types';
import { findClosestToTodayPeriod } from 'utils/schedules.utils';
import { facilityColor } from '../../../../../../themes/theme';
import CommitmentFeeScheduleTable from './components/CommitmentFeeScheduleTable';

const CommitmentFeeSchedulePreview = ({
  getCommitmentFeeSchedules,
  commitmentFeeSchedules,
  isLoadingCommitmentFeeSchedules,
}: {
  getCommitmentFeeSchedules: (
    config?: AxiosRequestConfig,
    options?: RefetchOptions,
  ) => AxiosPromise<FacilityInterestSchedulePeriodsData>;
  commitmentFeeSchedules: FacilityInterestSchedule[];
  isLoadingCommitmentFeeSchedules: boolean;
}) => {
  const [selectedItemId, setSelectedItemId] = useState<number>(0);

  useEffect(() => {
    if (getCommitmentFeeSchedules?.length) {
      const closestPeriodId = findClosestToTodayPeriod(
        commitmentFeeSchedules,
      )?.id;
      if (selectedItemId === 0 || selectedItemId === undefined) {
        // only at first render
        setSelectedItemId(closestPeriodId);
      }
    }
  }, [
    commitmentFeeSchedules,
    getCommitmentFeeSchedules?.length,
    selectedItemId,
  ]);

  return (
    <Container title="Commitment Fee Schedule (preview)">
      {commitmentFeeSchedules && (
        <CommitmentFeeScheduleTable
          preview
          commitmentFeeSchedulesData={commitmentFeeSchedules}
          isTableLoading={isLoadingCommitmentFeeSchedules}
          selectedItemId={selectedItemId}
          setSelectedItemId={setSelectedItemId}
          selectedColor={facilityColor.rowSelection}
        />
      )}
    </Container>
  );
};

export default CommitmentFeeSchedulePreview;
