import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@nordictrustee/nt-ui-library';
import InterestScheduleTable from 'modules/Loan/components/InterestScheduleTable';
import { findClosestToTodayPeriod } from 'utils/schedules.utils';
import { RouteParam } from 'utils/types/RouteParam';
import * as api from './../../../api';

const InterestSchedulePreview = () => {
  const [selectedItemId, setSelectedItemId] = useState(0);
  const { loanId } = useParams<RouteParam>();

  const {
    interestSchedulesData,
    isLoadingInterestSchedules,
    getInterestSchedules,
  } = api.useGetInterestSchedules(loanId);

  useEffect(() => {
    loanId && getInterestSchedules();
  }, [getInterestSchedules, loanId]);

  useEffect(() => {
    if (getInterestSchedules?.length) {
      const closestPeriodId = findClosestToTodayPeriod(
        interestSchedulesData,
      )?.id;
      if (selectedItemId === 0 || selectedItemId === undefined) {
        // only at first render
        setSelectedItemId(closestPeriodId);
      }
    }
  }, [getInterestSchedules?.length, interestSchedulesData, selectedItemId]);

  return (
    <Container title="Interest Schedule (preview)" width="100%">
      <InterestScheduleTable
        preview
        interestSchedulesData={interestSchedulesData}
        isTableLoading={isLoadingInterestSchedules}
        selectedItemId={selectedItemId}
        setSelectedItemId={setSelectedItemId}
      />
    </Container>
  );
};

export default InterestSchedulePreview;
