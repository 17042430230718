import { Typography } from '@mui/material';
import FormPage from '../../../components/FormPage';
import * as S from './styles';

const SentEmailConfirmation = () => {
  return (
    <FormPage
      cardTitle="We sent you an email"
      emptyHeader
    >
      <S.CardText>
        <Typography variant="subtitle1">
          Please check your email account and follow the link therein to set
          your new password.
        </Typography>
      </S.CardText>
      <S.CardText>
        <Typography variant="subtitle1">
          If you have not received an email within a few minutes, the email
          might have been caught in your spam filter, or you provided an email
          address that does not exist in our systems.
        </Typography>
      </S.CardText>
    </FormPage>
  );
};

export default SentEmailConfirmation;
