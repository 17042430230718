import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Column, Options } from '@material-table/core';
import { TableHead } from '@mui/material';
import {
  ColumnWithTooltip,
  StickyHeaderCell,
  StickyHeaderRow,
  Table,
} from '@nordictrustee/nt-ui-library';
import { FacilityInterestSchedule } from 'modules/FacilityDetails/FacilityDetails.types';
import scrollTop from 'utils/scrollTop';
import scrollToRow from 'utils/scrollToRow';
import { selectedRowStyle } from 'utils/selectedRowStyle';
import {
  centerColumnText,
  columnWithRightBorder,
} from '../../../../../../../utils/constants';
import { decimalNumberWithPercentFormatter } from '../../../../../../../utils/formatters/numberFormatter';
import { dayCountConventionOptions } from '../mock';

interface Props {
  preview?: boolean;
  commitmentFeeSchedulesData: FacilityInterestSchedule[];
  isTableLoading: boolean;
  selectedItemId: number;
  setSelectedItemId: Dispatch<SetStateAction<number>>;
  selectedColor?: string;
}

const CommitmentFeeScheduleTable = ({
  preview,
  commitmentFeeSchedulesData,
  isTableLoading,
  selectedItemId,
  setSelectedItemId,
  selectedColor,
}: Props) => {
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  const tableData = useMemo(
    () =>
      commitmentFeeSchedulesData.map((schedule) => ({
        ...schedule,
        dayCountConvention: dayCountConventionOptions.find(
          (conv: any) => conv.value === schedule.dayCountConvention,
        )?.label,
      })),
    [commitmentFeeSchedulesData],
  );

  const column: { [key: string]: keyof FacilityInterestSchedule } = useMemo(
    () => ({
      termStart: 'termStart',
      termEnd: 'termEnd',
      paymentDate: 'paymentDate',
      dayCountConvention: 'dayCountConvention',
      days: 'days',
      quotationDate: 'quotationDate',
      referenceRateValue: 'referenceRateValue',
      margin: 'margin',
      coupon: 'coupon',
      floor: 'floor',
      cash: 'cash',
      pik: 'pik',
      capitalised: 'capitalised',
    }),
    [],
  );

  const columns: Column<FacilityInterestSchedule>[] = useMemo(
    () => [
      {
        field: column.termStart,
        title: (
          <StickyHeaderCell
            title="Term Start"
            headerTextAlign="center"
            customWidth="6.6rem"
          />
        ),
        render: ({ termStart }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={`${termStart}`}>
            {termStart}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: column.termEnd,
        title: (
          <StickyHeaderCell
            title="Term End"
            headerTextAlign="center"
            customWidth="6.6rem"
          />
        ),
        render: ({ termEnd }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={`${termEnd}`}>{termEnd}</ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: column.paymentDate,
        title: (
          <StickyHeaderCell
            title="Payment Date"
            headerTextAlign="center"
            rightBorder
            customWidth="6.8rem"
          />
        ),
        render: ({ paymentDate }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={`${paymentDate}`}>
            {paymentDate}
          </ColumnWithTooltip>
        ),
        cellStyle: {
          ...columnWithRightBorder.cellStyle,
          ...centerColumnText.cellStyle,
        },
      },
      {
        field: column.dayCountConvention,
        title: (
          <StickyHeaderCell
            title="Day Conv."
            customWidth="6.4rem"
            headerTextAlign="left"
          />
        ),
        render: ({ dayCountConvention }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={`${dayCountConvention}`}>
            {dayCountConvention}
          </ColumnWithTooltip>
        ),
      },
      {
        field: column.days,
        title: (
          <StickyHeaderCell
            title="Days"
            headerTextAlign="center"
            rightBorder
            customWidth="3.2rem"
          />
        ),
        render: ({ days }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={`${days}`}>{days}</ColumnWithTooltip>
        ),
        cellStyle: {
          ...columnWithRightBorder.cellStyle,
          ...centerColumnText.cellStyle,
        },
      },
      {
        field: column.quotationDate,
        title: (
          <StickyHeaderCell
            title="Quotation Date"
            headerTextAlign="center"
            customWidth="7.1rem"
          />
        ),
        render: ({ quotationDate }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={`${quotationDate}`}>
            {quotationDate}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: column.referenceRateValue,
        title: (
          <StickyHeaderCell
            title="Ref. Rate"
            customWidth="6.1rem"
            headerTextAlign="center"
          />
        ),
        render: ({ referenceRateValue }: FacilityInterestSchedule) => (
          <ColumnWithTooltip
            title={decimalNumberWithPercentFormatter(referenceRateValue)}
          >
            {decimalNumberWithPercentFormatter(referenceRateValue)}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: column.margin,
        title: (
          <StickyHeaderCell
            title="Margin"
            headerTextAlign="center"
            customWidth="6rem"
          />
        ),
        render: ({ margin }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={decimalNumberWithPercentFormatter(margin)}>
            {decimalNumberWithPercentFormatter(margin)}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: column.coupon,
        title: (
          <StickyHeaderCell
            title="Coupon"
            headerTextAlign="center"
            customWidth="6.2rem"
          />
        ),
        render: ({ coupon }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={decimalNumberWithPercentFormatter(coupon!)}>
            {decimalNumberWithPercentFormatter(coupon!)}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: column.floor,
        title: (
          <StickyHeaderCell
            title="Floor"
            headerTextAlign="center"
            rightBorder
            customWidth="6rem"
          />
        ),
        render: ({ floor }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={decimalNumberWithPercentFormatter(floor)}>
            {decimalNumberWithPercentFormatter(floor)}
          </ColumnWithTooltip>
        ),
        cellStyle: {
          ...columnWithRightBorder.cellStyle,
          ...centerColumnText.cellStyle,
        },
      },
      {
        field: column.cash,
        title: (
          <StickyHeaderCell
            title="Cash"
            headerTextAlign="center"
            customWidth="6rem"
          />
        ),
        render: ({ cash }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={decimalNumberWithPercentFormatter(cash)}>
            {decimalNumberWithPercentFormatter(cash)}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: column.pik,
        title: (
          <StickyHeaderCell
            title="PIK"
            headerTextAlign="center"
            customWidth="5.8rem"
          />
        ),
        render: ({ pik }: FacilityInterestSchedule) => (
          <ColumnWithTooltip title={decimalNumberWithPercentFormatter(pik)}>
            {decimalNumberWithPercentFormatter(pik)}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: column.capitalised,
        title: (
          <StickyHeaderCell
            title="Capitalised"
            headerTextAlign="center"
            rightBorder
            customWidth="6rem"
          />
        ),
        render: ({ capitalised }: FacilityInterestSchedule) => (
          <ColumnWithTooltip
            title={decimalNumberWithPercentFormatter(capitalised)}
          >
            {decimalNumberWithPercentFormatter(capitalised)}
          </ColumnWithTooltip>
        ),
        cellStyle: {
          ...columnWithRightBorder.cellStyle,
          ...centerColumnText.cellStyle,
        },
      },
    ],
    [
      column.capitalised,
      column.cash,
      column.coupon,
      column.dayCountConvention,
      column.days,
      column.floor,
      column.margin,
      column.paymentDate,
      column.pik,
      column.quotationDate,
      column.referenceRateValue,
      column.termEnd,
      column.termStart,
    ],
  );

  const tableOptions: Options<FacilityInterestSchedule> = useMemo(
    () => ({
      maxBodyHeight: preview ? '20vh' : '28vh',
      search: false,
      toolbar: false,
      paging: false,
      rowStyle: (rowData) =>
        selectedRowStyle(rowData.id, selectedItemId, selectedColor),
    }),
    [preview, selectedColor, selectedItemId],
  );

  const tableComponents = useMemo(
    () => ({
      Header: () => (
        <TableHead>
          <StickyHeaderRow>
            <StickyHeaderCell
              isTopHeader
              colSpan={10}
              title="Basic Information"
            />
            <StickyHeaderCell
              isTopHeader
              colSpan={3}
              title="Coupon Distribution"
            />
          </StickyHeaderRow>
          <StickyHeaderRow>
            {columns.map(
              (item: Column<FacilityInterestSchedule>, index: number) => {
                const Title = () => item?.title as JSX.Element;
                return !item?.hidden && <Title key={`data-${index}`} />;
              },
            )}
          </StickyHeaderRow>
        </TableHead>
      ),
    }),
    [columns],
  );

  const onRowClick = (_: any, item: FacilityInterestSchedule) =>
    setSelectedItemId(item.id);

  useEffect(() => {
    if (selectedItemId && isFirstRender) {
      scrollToRow(selectedItemId);
      scrollTop();
      setIsFirstRender(false);
    }
  }, [selectedItemId, isFirstRender]);

  return (
    <Table
      isLoading={isTableLoading}
      columns={columns}
      data={tableData}
      options={tableOptions}
      onRowClick={!preview ? onRowClick : undefined}
      components={tableComponents}
    />
  );
};

export default CommitmentFeeScheduleTable;
