import {
  ColumnWithTooltip,
  Container,
  SummaryWrapper,
  Table,
} from '@nordictrustee/nt-ui-library';
import theme from 'themes/theme';
import {
  columnRightText,
  columnRightTextWithPadding,
  firstColPaddingWithCenterText,
} from 'utils/constants';
import { currencyFormatter } from 'utils/formatters/currencyFormatter';
import {
  CommitmentCalculatedProps,
  CommitmentProps,
} from './CommitmentsTable.types';

interface Props {
  data?: CommitmentCalculatedProps[] | CommitmentProps[];
  isLoading?: boolean;
  isLendersSection?: boolean;
  currency?: string;
  summary?: {
    availableCommitmentSummary: number;
    totalCommitmentSummary: number;
    currency: string;
    lenderCommitmentsCalculated: CommitmentCalculatedProps[];
  };
}

const CommitmentsTable = ({
  data,
  isLoading,
  isLendersSection,
  currency,
  summary,
}: Props) => {
  const columns = [
    {
      field: 'date',
      title: 'Date',
      width: '50%',
      hidden: !isLendersSection,
      ...firstColPaddingWithCenterText,
    },
    {
      field: 'amount',
      ...columnRightText,
      render: ({ amount, currency }: CommitmentProps) => (
        <ColumnWithTooltip title={currencyFormatter(amount, currency)}>
          {currencyFormatter(amount, currency)}
        </ColumnWithTooltip>
      ),
      title: 'Amount',
      width: '50%',
      hidden: !isLendersSection,
    },
    {
      field: 'lender',
      title: 'Lender',
      width: '44%',
      hidden: isLendersSection,
      render: (item: CommitmentCalculatedProps) => {
        return (
          <ColumnWithTooltip title={item.lender.name}>
            {item.lender.name}
          </ColumnWithTooltip>
        );
      },
    },
    {
      field: 'totalCommitment',
      title: 'Total Commitment',
      width: '28%',
      hidden: isLendersSection,
      ...columnRightText,
      render: ({ totalCommitment }: CommitmentCalculatedProps) => (
        <ColumnWithTooltip title={currencyFormatter(totalCommitment, currency)}>
          {currencyFormatter(totalCommitment, currency)}
        </ColumnWithTooltip>
      ),
    },
    {
      field: 'availableCommitment',
      title: 'Available Commitment',
      width: '28%',
      hidden: isLendersSection,
      ...columnRightTextWithPadding,
      render: ({ availableCommitment }: CommitmentCalculatedProps) => (
        <ColumnWithTooltip
          title={currencyFormatter(availableCommitment, currency)}
        >
          {currencyFormatter(availableCommitment, currency)}
        </ColumnWithTooltip>
      ),
    },
  ];
  return (
    <>
      <Container
        title="Commitments"
        containerHeight="100%"
      >
        <Table
          columns={columns}
          data={data ?? []}
          options={{
            toolbar: false,
            paging: false,
            tableLayout: 'fixed',
            maxBodyHeight: '250px',
            minBodyHeight: '250px',
            rowStyle: {
              height: '2.5rem',
            },
          }}
          isLoading={isLoading}
        />
        {data && data.length > 0 && (
          <SummaryWrapper
            commonLabel="Sum"
            style={{
              gridTemplateColumns: '44% 28% 28%',
              borderBottom: 'none',
              color: theme.palette.text.secondary,
              borderRadius: 0,
              width: '100%',
              boxSizing: 'border-box',
            }}
            data={[
              {
                amount: summary?.totalCommitmentSummary,
                suffix: currency,
              },
              {
                amount: summary?.availableCommitmentSummary,
                suffix: currency,
              },
            ]}
          />
        )}
      </Container>
    </>
  );
};

export default CommitmentsTable;
