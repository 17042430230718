import { KeyboardEvent, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { DataLink } from '@nordictrustee/nt-ui-library';
import { AuthContext } from 'context/AuthProvider/AuthProvider';
import { FORGOT_PASSWORD, TERMS } from 'router/url';
import { FormPageCard } from 'components/FormPage';
import PasswordInput from 'components/Inputs/PasswordInput';
import UsernameInput from 'components/Inputs/UsernameInput';
import { authenticate } from 'modules/Auth/api';
import { handleException } from 'utils/handleException';
import * as api from '../../../context/AuthProvider/api';
import * as S from './styles';

const Login = () => {
  const { setIsAuthenticated, setUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useHistory();
  const { getMe } = api.useGetMe();

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    trigger,
  } = useForm({ mode: 'onBlur' });

  const handleISLogin = handleSubmit(async () => {
    const isValid = await trigger();
    if (isValid) {
      setIsLoading(true);
      const { username, password } = getValues();
      try {
        const response = await authenticate(username, password);
        setIsLoading(false);
        if (response && response.status === 200) {
          const me = await getMe();
          setIsLoading(false);
          const loggedUser = {
            accessToken: response.accessToken,
            authorized: true,
            termsAccepted: me?.data?.termsAccepted,
          };
          setUser(loggedUser);
          setIsAuthenticated(true);
          if (!me?.data?.termsAccepted) {
            push(TERMS);
          }
        }
      } catch (e) {
        setIsLoading(false);
        handleException(e);
      }
    }
  });

  const handleEnterClick = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      const { username, password } = getValues();
      if (username && password) {
        handleISLogin();
      }
    }
  };

  return (
    <FormPageCard
      cardTitle="Login"
      buttonText="Log in"
      onClick={handleISLogin}
      isLoadingButton={isLoading}
    >
      <UsernameInput
        control={control}
        errors={errors}
        isRequired
        onKeyUp={handleEnterClick}
      />
      <PasswordInput
        control={control}
        errors={errors}
        required
        onKeyUp={handleEnterClick}
        label="Password"
      />
      <S.ForgotPassword>
        <DataLink text="Forgot password?" to={`${FORGOT_PASSWORD}`} />
      </S.ForgotPassword>
    </FormPageCard>
  );
};

export default Login;
