import React from 'react';
import { Box, Paper } from '@mui/material';
import { ScheduleDefinition } from '../../../../../../FacilityDetails.types';
import BasicInformation from './components/BasicInformation/BasicInformation';
import * as S from './ScheduleDefinitionDetails.css';

interface Props {
  scheduleData: ScheduleDefinition;
}

const ScheduleDefinitionDetails = ({ scheduleData }: Props) => {
  return (
    <Box mx={2}>
      <Paper elevation={0}>
        <S.TabDataView>
          <BasicInformation scheduleData={scheduleData} />
        </S.TabDataView>
      </Paper>
    </Box>
  );
};

export default ScheduleDefinitionDetails;
