import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import FormPage from '../../../components/FormPage';
import EmailInput from '../../../components/Inputs/EmailInput';
import * as URL from '../../../router/url';
import { handleException } from '../../../utils/handleException';
import { sendResetEmail } from '../api';
import * as S from './styles';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useHistory();

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
    getValues,
  } = useForm({ mode: 'onBlur' });

  const handleSendResetPasswordEmail = handleSubmit(async () => {
    const isValid = await trigger();
    if (isValid) {
      setIsLoading(true);
      const { email } = getValues();
      try {
        const response = await sendResetEmail(email);
        setIsLoading(false);
        if (response && response.status === 204) {
          push(`${URL.SENT_EMAIL_CONFIRMATION}`);
        }
      } catch (e) {
        setIsLoading(false);
        handleException(e);
      }
    }
  });

  return (
    <FormPage
      cardTitle="Forgot your password?"
      buttonText="Reset password"
      emptyHeader
      boxSize={6}
      onClick={handleSendResetPasswordEmail}
      isLoadingButton={isLoading}
    >
      <S.CardText>
        <Typography variant="subtitle1">
          In order to reset your password, we need to confirm your identity.
          Please enter your email address below, then click the "RESET PASSWORD"
          button.
        </Typography>
      </S.CardText>
      <EmailInput
        name={'email'}
        label={'Email'}
        control={control}
        errors={errors}
        isRequired
        hideRequiredStar
      />
    </FormPage>
  );
};

export default ForgotPassword;
