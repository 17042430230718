import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { MaturedChip, TabMenu, TabPanel } from '@nordictrustee/nt-ui-library';
import { usePutLastVisitedFAs } from 'modules/FacilityAgreements/api';
import { FACILITY_AGREEMENTS } from '../../../../router/url';
import {
  facilityAgreementColor,
  homeMainColor,
} from '../../../../themes/theme';
import { RouteParam } from '../../../../utils/types/RouteParam';
import { useGetBasicFacility } from './api';
import ComplianceSchedule from './ComplianceSchedule';
import Events from './Events';
import Overview from './Overview';
import { FacilitySections, useTabMenu } from './useTabMenu';
import { StyleContainer } from './FacilityAgreementDetails.css';

const FacilityAgreementDetails = () => {
  const { TabList, currentTab, handleTabChange } = useTabMenu();
  const { agreementId } = useParams<RouteParam>();
  const [currency, setCurrency] = useState<string>();
  const [isLastVisited, setLastVisited] = useState<boolean>(false);
  const { basicFacility, isFacilityLoading, getBasicFacility } =
    useGetBasicFacility(agreementId, currency);

  const { putLastVisitedFAs } = usePutLastVisitedFAs();

  useEffect(() => {
    if (putLastVisitedFAs && basicFacility && !isLastVisited) {
      putLastVisitedFAs({
        data: {
          objectId: basicFacility.id,
          objectName: basicFacility.name,
        },
      });
      setLastVisited(true);
    }
  }, [basicFacility, isLastVisited, putLastVisitedFAs]);

  useEffect(() => {
    //get basic FA when currency is set
    if (currency !== undefined) {
      getBasicFacility();
    }
  }, [currency, getBasicFacility]);

  useEffect(() => {
    //get basic FA for different tabs than overview
    if (!basicFacility && currentTab !== FacilitySections.OVERVIEW) {
      getBasicFacility();
    }
  }, [basicFacility, currentTab, getBasicFacility]);

  return (
    <StyleContainer disableGutters maxWidth="xl">
      <Box mt={2} mb={1}>
        <Breadcrumbs>
          <NavLink to={FACILITY_AGREEMENTS} style={{ color: homeMainColor }}>
            Home
          </NavLink>
        </Breadcrumbs>
        <Box
          sx={{ justifyContent: 'space-between', display: 'flex' }}
          mt={1}
          mb={2}
        >
          <Typography
            variant="h2"
            color={
              basicFacility?.isMatured
                ? 'secondary'
                : facilityAgreementColor.header
            }
          >
            {basicFacility?.name}
          </Typography>
          {basicFacility?.isMatured && <MaturedChip />}
        </Box>
      </Box>
      <Box mt={1} mb={1}>
        <TabMenu
          items={TabList}
          tabsValue={currentTab}
          handleChange={handleTabChange}
          isDividerVisible
        />
      </Box>
      <Box>
        <TabPanel currentTab={currentTab} value={TabList.overview.value}>
          <Overview
            isLoading={!basicFacility || isFacilityLoading}
            data={basicFacility}
            setCurrency={setCurrency}
            currency={currency}
          />
        </TabPanel>
        <TabPanel
          currentTab={currentTab}
          value={TabList['compliance-schedule'].value}
        >
          <ComplianceSchedule />
        </TabPanel>
        <TabPanel currentTab={currentTab} value={TabList.events.value}>
          <Events />
        </TabPanel>
      </Box>
    </StyleContainer>
  );
};

export default FacilityAgreementDetails;
