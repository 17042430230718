import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FACILITIES } from 'router/url';
import { historyActions } from 'utils/constants';
import { mockEvent } from 'utils/mocks/mockChangeEvent';
import { RouteParam } from 'utils/types/RouteParam';

export const LoanSections = {
  OVERVIEW: 'overview',
  AMOUNT_ADJUSTMENT: 'amount-adjustment',
  INTEREST_SCHEDULE: 'interest-schedule',
};

interface ExtendedChangeEvent extends React.ChangeEvent<{}> {
  ctrlKey?: boolean;
}
export const useTabMenu = () => {
  let {
    facilityId = 0,
    section = LoanSections.OVERVIEW,
    loanId = 0,
  } = useParams<RouteParam>();
  const { push, action, replace } = useHistory();

  facilityId = Number(facilityId);
  loanId = Number(loanId);

  const TabList: {
    readonly [key: string]: { value: string; linkTo?: string; label: string };
  } = {
    overview: {
      value: LoanSections.OVERVIEW,
      linkTo: `${FACILITIES}/${facilityId}/loan/${loanId}/${LoanSections.OVERVIEW}`,
      label: 'Overview',
    },
    'amount-adjustment': {
      value: LoanSections.AMOUNT_ADJUSTMENT,
      linkTo: `${FACILITIES}/${facilityId}/loan/${loanId}/${LoanSections.AMOUNT_ADJUSTMENT}`,
      label: 'Amount Adjustment',
    },
    'interest-schedule': {
      value: LoanSections.INTEREST_SCHEDULE,
      linkTo: `${FACILITIES}/${facilityId}/loan/${loanId}/${LoanSections.INTEREST_SCHEDULE}`,
      label: 'Interest Schedule',
    },
  };

  const handleTabChange = useCallback(
    (_: ExtendedChangeEvent, tab: string) => {
      if (_.ctrlKey) {
        return;
      }
      setCurrentTab(tab);
      push(`${FACILITIES}/${facilityId}/loan/${loanId}/${tab}`);
    },
    [facilityId, push, loanId],
  );

  const handlePopChange = useCallback(
    (_: React.ChangeEvent<{}>, tab: string) => {
      setCurrentTab(tab);
      replace(`${FACILITIES}/${facilityId}/loan/${loanId}/${tab}`);
    },
    [replace, facilityId, loanId],
  );

  useEffect(() => {
    if (action === historyActions.POP) {
      handlePopChange(mockEvent, section);
    }
  }, [handlePopChange, section, action]);

  const [currentTab, setCurrentTab] = useState(
    TabList[section || 'overview']?.value,
  );

  return { TabList, currentTab, handleTabChange };
};
