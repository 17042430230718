import { Dispatch, SetStateAction, useEffect } from 'react';
import { Container, SelectUncontrolled } from '@nordictrustee/nt-ui-library';
import { useGetCurrencies, useGetMaturityProfile } from '../../api';
import MaturityProfileChart from '../../components/MaturityProfile/MaturityProfileChart';
import {
  CurrencyContainer,
  CurrencyWrapper,
  PaddingDiv,
  SelectWrapper,
} from './MaturityProfile.css';

interface Props {
  setCurrency: Dispatch<SetStateAction<string | undefined>>;
  currency?: string;
}

const MaturityProfile = ({ setCurrency, currency }: Props) => {
  const { currencies, currencyOptions, areCurrenciesLoading, getCurrencies } =
    useGetCurrencies();
  const { maturityProfile, isLoadingMaturityProfile, getMaturityProfile } =
    useGetMaturityProfile(currency);

  useEffect(() => {
    getCurrencies();
  }, [getCurrencies]);

  useEffect(() => {
    if (currencies) {
      if (currencies.length > 0 && currency === undefined) {
        setCurrency(currencies[0]);
      }
    }
  }, [currencies, currency, setCurrency]);

  useEffect(() => {
    if (currency !== undefined) {
      getMaturityProfile();
    }
  }, [currency, getMaturityProfile]);

  return (
    <Container
      isHeader={false}
      containerHeight="100%"
      isLoadingContent={areCurrenciesLoading}
    >
      <CurrencyContainer>
        <CurrencyWrapper>Repayments</CurrencyWrapper>
        <SelectWrapper
          hideContainer={!currencies?.length || currencies.length === 1}
        >
          <SelectUncontrolled
            name="currency"
            value={currency}
            options={currencyOptions}
            onChange={(value: string) => setCurrency(value)}
            isLoading={areCurrenciesLoading}
            disabled={!currencies?.length}
          />
        </SelectWrapper>
      </CurrencyContainer>
      <PaddingDiv>
        <MaturityProfileChart
          maturityProfile={maturityProfile}
          isLoading={isLoadingMaturityProfile}
        />
      </PaddingDiv>
    </Container>
  );
};

export default MaturityProfile;
