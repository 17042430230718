import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Column } from '@material-table/core';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { ColumnWithTooltip, Table } from '@nordictrustee/nt-ui-library';
import moment from 'moment/moment';
import { FACILITY_AGREEMENTS } from 'router/url';
import theme from '../../../../themes/theme';
import {
  dateDisplayFormat,
  linkHeaderPadding,
  rightText,
} from '../../../../utils/constants';
import { daysDiffText } from '../../../../utils/formatters/dateFormatter';
import { EventFA } from '../../FacilityAgreement.types';

interface Props {
  isLoading: boolean;
  data?: EventFA[];
  bodyHeight?: string;
}
const headerCellPadding = {
  headerStyle: { padding: '0' },
  cellStyle: { padding: '0 0 0 1rem' },
};
const EventsTable = ({ isLoading, data, bodyHeight }: Props) => {
  const { push } = useHistory();

  const columns: Column<EventFA>[] = useMemo(
    () => [
      {
        field: 'date',
        title: '',
        render: ({ date }) => (
          <>{`${moment(date).format(dateDisplayFormat)}`}</>
        ),
        ...headerCellPadding,
        sorting: true,
      },
      {
        field: 'numberToDeadline',
        title: '',
        render: ({ date }) => {
          const diff = daysDiffText(moment(date));
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LabelImportantIcon
                style={{
                  color: theme.palette.secondary.main,
                }}
              />
              {diff}
            </div>
          );
        },
        ...headerCellPadding,
      },
      {
        field: 'name',
        title: '',
        render: ({ title }) => (
          <ColumnWithTooltip title={title}>
            {title}
            <span
              style={{
                marginLeft: '4px',
                content: `url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.826,2.824l-2.826,-2.824l6,0l0,6l-2.467,-2.467l-4.826,4.826l-0.707,-0.708l4.826,-4.826l0,-0.001z' fill='%2fff' fill-rule='evenodd'/%3E%3C/svg%3E")`,
              }}
            />
          </ColumnWithTooltip>
        ),
        headerStyle: { ...linkHeaderPadding.headerStyle },
        cellStyle: {
          ...rightText,
          padding: '0 1rem',
        },
      },
    ],
    [],
  );

  const onRowClick = (_: any, item: EventFA) => {
    push(
      `${FACILITY_AGREEMENTS}/${item.facilityAgreementID}/events/${item.id}`,
    );
  };

  return (
    <Table
      isLoading={isLoading}
      columns={columns}
      data={data ?? []}
      hideSortingArrows
      onRowClick={onRowClick}
      options={{
        search: false,
        toolbar: false,
        paging: false,
        sorting: true,
        maxBodyHeight: bodyHeight ? bodyHeight : '355px',
        minBodyHeight: bodyHeight ? bodyHeight : '355px',
        rowStyle: { height: '2.5rem' },
      }}
    />
  );
};

export default EventsTable;
