import { Container } from '@mui/material';
import styled from 'styled-components';
import { facilityAgreementColor } from '../../../../themes/theme';

export const StyleContainer = styled(Container)`
  .MuiTypography-h3 {
    color: ${facilityAgreementColor.main};
  }
  .MuiTabs-indicator {
    background: ${facilityAgreementColor.dark};
  }
  button {
    &.Mui-selected {
      color: ${facilityAgreementColor.dark};
    }
  }
  .MuiCircularProgress-root {
    color: ${facilityAgreementColor.dark};
  }
  .MuiLinearProgress-root {
    background-color: ${facilityAgreementColor.light};
    .MuiLinearProgress-bar {
      background-color: ${facilityAgreementColor.dark};
    }
  }
`;
