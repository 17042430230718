import { ReactNode, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArticleIcon from '@mui/icons-material/Article';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { EnvironmentChip, MenuButton } from '@nordictrustee/nt-ui-library';
import { AuthContext } from 'context/AuthProvider/AuthProvider';
import * as URL from 'router/url';
import { EnvironmentName } from 'utils/types/EnvironmentName';
import Logo from './components/Logo';
import * as S from './Header.css';

interface Props {
  children?: ReactNode;
}

const { ENVIRONMENT_NAME } = window._env_;

const Header = (props: Props) => {
  const { isAuthenticated, setIsAuthenticated, setUser, user } =
    useContext(AuthContext);
  const { push } = useHistory();

  const handleLogOut = () => {
    localStorage.removeItem('state');
    setIsAuthenticated(false);
    setUser(undefined);
    push(URL.LOGIN);
  };

  const handleRedirectToTerms = () => push(URL.TERMS);

  const handleGoToProfile = () => push(URL.PROFILE);

  const link =
    !ENVIRONMENT_NAME ||
    ENVIRONMENT_NAME === EnvironmentName.local ||
    ENVIRONMENT_NAME === EnvironmentName.prod
      ? 'https://loanportal-user-management.nordictrustee.com/login'
      : `https://loanportal-user-management-${ENVIRONMENT_NAME}.nordictrustee.com/login`;

  return (
    <S.Header>
      <S.HeaderToolbar>
        <S.LeftToolbar>
          <S.MainMenuBox>
            <S.LogoLink
              to={isAuthenticated ? URL.FACILITY_AGREEMENTS : URL.LOGIN}
            >
              <Logo />
            </S.LogoLink>
          </S.MainMenuBox>
          {ENVIRONMENT_NAME && ENVIRONMENT_NAME !== EnvironmentName.prod && (
            <EnvironmentChip environmentName={ENVIRONMENT_NAME} />
          )}
        </S.LeftToolbar>
        <S.RightToolbar>
          {isAuthenticated ? (
            <MenuButton
              transformX={-10}
              icon={
                user?.abbreviation ? (
                  <S.Account>{user.abbreviation}</S.Account>
                ) : (
                  <AccountCircleIcon />
                )
              }
            >
              <S.MenuItem
                onClick={handleGoToProfile}
                startIcon={<PersonIcon />}
              >
                Profile
              </S.MenuItem>
              <S.MenuItem
                onClick={handleRedirectToTerms}
                startIcon={<ArticleIcon />}
              >
                Terms
              </S.MenuItem>
              <S.MenuItem onClick={handleLogOut} startIcon={<LogoutIcon />}>
                Log out
              </S.MenuItem>
            </MenuButton>
          ) : (
            <MenuButton transformX={-10} icon={<AccountCircleIcon />}>
              <S.MenuItem startIcon={<LoginIcon />}>
                <S.NTLoginLink href={link}>NT LOGIN</S.NTLoginLink>
              </S.MenuItem>
            </MenuButton>
          )}
        </S.RightToolbar>
      </S.HeaderToolbar>
      {props.children}
    </S.Header>
  );
};

export default Header;
