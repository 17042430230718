import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from '@material-table/core';
import { Chip } from '@mui/material';
import {
  ChipList,
  ColumnWithTooltip,
  Container,
  Table,
  TableLink,
} from '@nordictrustee/nt-ui-library';
import { useGetLoans } from 'modules/FacilityDetails/api';
import {
  columnRightText,
  linkColPadding,
  linkFirstColPadding,
  rightColumnWithPadding,
  wrapCell,
} from 'utils/constants';
import { currencyFormatter } from 'utils/formatters/currencyFormatter';
import { RouteParam } from 'utils/types/RouteParam';
import { LoanItem } from './Loans.types';

const Loans = () => {
  const { facilityId } = useParams<RouteParam>();
  const { loansData, areLoansLoading, getLoans } = useGetLoans(facilityId);

  const columns: Column<LoanItem>[] = (
    [
      {
        field: 'name',
        title: (
          <ColumnWithTooltip title="Loan Name">Loan Name</ColumnWithTooltip>
        ),
        width: '15%',
        render: ({ name }) => (
          <ColumnWithTooltip title={name}>{name}</ColumnWithTooltip>
        ),
        ...linkFirstColPadding,
      },
      {
        field: 'status',
        sorting: false,
        title: '',
        width: '5%',
        cellStyle: {
          ...wrapCell,
          ...linkColPadding.cellStyle,
        },
        render: ({ isMatured }) => {
          if (isMatured) {
            return (
              <Chip
                label="Matured"
                color="secondary"
                variant="outlined"
                size="small"
                style={{ width: 'min-content' }}
              />
            );
          }
        },
      },
      {
        field: 'utilizationDate',
        title: (
          <ColumnWithTooltip title="Utilisation Date">
            Utilisation Date
          </ColumnWithTooltip>
        ),
        width: '10%',
        render: ({ utilizationDate }) => (
          <ColumnWithTooltip title={utilizationDate}>
            {utilizationDate}
          </ColumnWithTooltip>
        ),
        ...linkFirstColPadding,
      },
      {
        field: 'loanAmount',
        title: (
          <ColumnWithTooltip title="Loan Amount">Loan Amount</ColumnWithTooltip>
        ),
        width: '15%',
        render: ({ loanAmount, currency }) => (
          <ColumnWithTooltip title={currencyFormatter(loanAmount, currency)}>
            {currencyFormatter(loanAmount, currency)}
          </ColumnWithTooltip>
        ),
        ...columnRightText,
      },
      {
        field: 'outstandingAmount',
        title: (
          <ColumnWithTooltip title="Outstanding Amount">
            Outstanding Amount
          </ColumnWithTooltip>
        ),
        width: '15%',
        ...rightColumnWithPadding,

        render: ({ outstandingAmount, currency }: LoanItem) => (
          <ColumnWithTooltip
            title={currencyFormatter(outstandingAmount, currency)}
          >
            {currencyFormatter(outstandingAmount, currency)}
          </ColumnWithTooltip>
        ),
      },
      {
        field: 'borrower',
        sorting: false,
        title: 'Borrower',
        width: '10%',
        cellStyle: { ...wrapCell, ...linkColPadding.cellStyle },
      },
      {
        field: 'lenders',
        sorting: false,
        title: 'Lenders',
        width: '25%',
        cellStyle: { ...wrapCell, ...linkColPadding.cellStyle },
      },
    ] as Column<LoanItem>[]
  ).map((column) => ({
    ...column,

    render: (row) => (
      <TableLink to={`/facilities/${facilityId}/loan/${row.id}/overview`}>
        {column.render
          ? column.render(row, 'row')
          : row[column.field as keyof LoanItem]}
      </TableLink>
    ),
  }));

  useEffect(() => {
    facilityId && getLoans();
  }, [facilityId, getLoans]);

  const loans = useMemo(
    () =>
      loansData?.map((item: LoanItem) => ({
        ...item,
        lenders: <ChipList maxChips={2} list={item.lenders} />,
        borrower: item.borrower && <ChipList list={[item.borrower]} />,
      })),
    [loansData],
  );

  return (
    <>
      <Container title="Loans">
        <Table
          columns={columns}
          data={loans}
          options={{
            tableLayout: 'fixed',
            paging: false,
            search: false,
            toolbar: false,
            rowStyle: { height: '42px', boxSizing: 'border-box' },
            maxBodyHeight: '32vh',
            minBodyHeight: '10vh',
          }}
          onRowClick={() => {}}
          isLoading={areLoansLoading}
        />
      </Container>
    </>
  );
};

export default Loans;
