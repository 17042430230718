import { Grid } from '@mui/material';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { RefetchOptions } from 'axios-hooks';
import {
  Commitment,
  CommitmentFeeData,
  FacilityInterestSchedule,
  FacilityInterestSchedulePeriodsData,
} from '../../FacilityDetails.types';
import BasicInformation from './components/BasicInformation/BasicInformation';
import ScheduleDefinitions from './components/ScheduleDefinitions/ScheduleDefinitions';
import CommitmentFeeSchedulePreview from './components/SchedulePreview/CommitmentFeeSchedulePreview';

interface Props {
  data?: Commitment;
  feePayer?: string;
  commitmentFee?: CommitmentFeeData;
  isLoadingCommitmentFees?: boolean;
  commitmentFeeSchedules: FacilityInterestSchedule[];
  isLoadingCommitmentFeeSchedules: boolean;
  getCommitmentFeeSchedules: (
    config?: AxiosRequestConfig,
    options?: RefetchOptions,
  ) => AxiosPromise<FacilityInterestSchedulePeriodsData>;
}

const Overview = ({
  data,
  feePayer,
  commitmentFee,
  isLoadingCommitmentFees,
  commitmentFeeSchedules,
  isLoadingCommitmentFeeSchedules,
  getCommitmentFeeSchedules,
}: Props) => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={12} lg={4}>
        <BasicInformation
          commitments={data}
          feePayer={feePayer}
          commitmentFee={commitmentFee}
          isLoadingCommitmentFees={isLoadingCommitmentFees ?? false}
        />
      </Grid>
      <Grid item xs={12} lg={8}>
        <ScheduleDefinitions />
      </Grid>
      <Grid item xs={12} paddingBottom="64px">
        <CommitmentFeeSchedulePreview
          commitmentFeeSchedules={commitmentFeeSchedules}
          isLoadingCommitmentFeeSchedules={isLoadingCommitmentFeeSchedules}
          getCommitmentFeeSchedules={getCommitmentFeeSchedules}
        />
      </Grid>
    </Grid>
  );
};
export default Overview;
