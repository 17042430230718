import { useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Container, DividerWithText } from '@nordictrustee/nt-ui-library';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { RefetchOptions } from 'axios-hooks';
import { useGetPayments } from 'modules/FacilityDetails/api';
import {
  FacilityDetailsDataSectionsType,
  FacilityInterestSchedule,
  FacilityInterestSchedulePeriodsData,
  Payment,
} from 'modules/FacilityDetails/FacilityDetails.types';
import { findClosestToTodayPeriod } from 'utils/schedules.utils';
import { facilityColor } from '../../../../../themes/theme';
import CommitmentFeeScheduleTable from '../../CommitmentFee/components/SchedulePreview/components/CommitmentFeeScheduleTable';
import LenderSplit from '../components/CommitmentFeePeriod/components/LenderSplit';
import Payments from '../components/CommitmentFeePeriod/components/Payments';

const CommitmentFeeSchedule = ({
  getCommitmentFeeSchedules,
  commitmentFeeSchedules,
  isLoadingCommitmentFeeSchedules,
}: {
  getCommitmentFeeSchedules: (
    config?: AxiosRequestConfig,
    options?: RefetchOptions,
  ) => AxiosPromise<FacilityInterestSchedulePeriodsData>;
  commitmentFeeSchedules: FacilityInterestSchedule[];
  isLoadingCommitmentFeeSchedules: boolean;
}) => {
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [selectedPayment, setSelectedPayment] = useState<Payment>();

  const { payments, isLoadingPayments, getPayments } = useGetPayments();

  const facilityCommitmentFeeScheduleSection =
    FacilityDetailsDataSectionsType.FacilityCommitmentFeeSchedule;

  const currentPeriodItem = useMemo(
    () =>
      commitmentFeeSchedules?.find(
        ({ id }: FacilityInterestSchedule) => id === Number(selectedItemId),
      ),
    [commitmentFeeSchedules, selectedItemId],
  );

  useEffect(() => {
    if (getCommitmentFeeSchedules?.length) {
      const closestPeriodId = findClosestToTodayPeriod(
        commitmentFeeSchedules,
      )?.id;
      if (selectedItemId === 0 || selectedItemId === undefined) {
        // only at first render
        setSelectedItemId(closestPeriodId);
      }
    }
  }, [
    commitmentFeeSchedules,
    getCommitmentFeeSchedules?.length,
    selectedItemId,
  ]);

  useEffect(() => {
    if (payments?.length) {
      setSelectedPayment(payments[0]);
    }
  }, [payments]);

  useEffect(() => {
    selectedItemId &&
      getPayments(
        selectedItemId,
        facilityCommitmentFeeScheduleSection,
        currentPeriodItem?.periodId,
      );
  }, [
    currentPeriodItem?.periodId,
    facilityCommitmentFeeScheduleSection,
    getPayments,
    selectedItemId,
  ]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Container title="Commitment Fee Schedule" >
          {commitmentFeeSchedules && (
            <CommitmentFeeScheduleTable
              commitmentFeeSchedulesData={commitmentFeeSchedules}
              isTableLoading={isLoadingCommitmentFeeSchedules}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
              selectedColor={facilityColor.rowSelection}
            />
          )}
        </Container>
      </Grid>
      <DividerWithText
        margin="1rem 0 0"
        text={
          <Typography variant="h3">
            {`Commitment Fee Period: ${
              currentPeriodItem?.periodStartDate ?? ''
            } - ${currentPeriodItem?.periodEndDate ?? ''}`}
          </Typography>
        }
      />
      <Grid item xs={12}>
        <Container
          isHeader={false}
          noBorder
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Payments
                sectionName={facilityCommitmentFeeScheduleSection}
                payments={payments}
                isLoadingPayments={isLoadingPayments}
                setSelectedPayment={setSelectedPayment}
                selectedPayment={selectedPayment}
                itemId={selectedItemId}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LenderSplit
                sectionName={facilityCommitmentFeeScheduleSection}
                payment={selectedPayment}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default CommitmentFeeSchedule;
