import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as URL from 'router/url';
import { historyActions } from 'utils/constants';
import { mockEvent } from 'utils/mocks/mockChangeEvent';
import { RouteParam } from 'utils/types/RouteParam';

export const ProfileSections = {
  SETTINGS: 'settings',
};

const TabList: {
  readonly [key: string]: { value: string; linkTo?: string; label: string };
} = {
  settings: {
    value: ProfileSections.SETTINGS,
    linkTo: `${URL.PROFILE}`,
    label: 'Settings',
  },
};
interface ExtendedChangeEvent extends React.ChangeEvent<{}> {
  ctrlKey?: boolean;
}
export const useTabMenu = () => {
  let { section = ProfileSections.SETTINGS } = useParams<RouteParam>();
  const { push, replace, action } = useHistory();

  const [currentTab, setCurrentTab] = useState(TabList[section]?.value);

  const handleTabChange = useCallback(
    (_: ExtendedChangeEvent, tab: string) => {
      if (_.ctrlKey) {
        return;
      }
      setCurrentTab(tab);
      push(`${URL.PROFILE}`);
    },
    [push],
  );

  const handlePopChange = useCallback(
    (_: ChangeEvent<{}>, tab: string) => {
      setCurrentTab(tab);
      replace(`${URL.PROFILE}`);
    },
    [replace],
  );

  useEffect(() => {
    if (action === historyActions.POP && section) {
      handlePopChange(mockEvent, section);
    }
  }, [action, handlePopChange, section]);

  return { TabList, currentTab, handleTabChange };
};
