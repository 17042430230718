import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from '@material-table/core';
import { Chip } from '@mui/material';
import {
  ChipList,
  ColumnWithTooltip,
  Container,
  ProgressBar,
  Table,
  TableLink,
} from '@nordictrustee/nt-ui-library';
import { FacilityItem } from 'modules/FacilityAgreements/FacilityAgreement.types';
import {
  centerColumnText,
  columnRightText,
  firstColPadding,
  linkColPadding,
  linkFirstColPadding,
  wrapCell,
} from 'utils/constants';
import { currencyFormatter } from 'utils/formatters/currencyFormatter';
import { RouteParam } from '../../../../../../../utils/types/RouteParam';
import { useGetFacilities } from '../../../api';

const Facilities = () => {
  const { agreementId } = useParams<RouteParam>();
  const { facilitiesData, areFacilitiesLoading, getFacilities } =
    useGetFacilities(agreementId);

  useEffect(() => {
    agreementId && getFacilities();
  }, [agreementId, getFacilities]);

  const facilities = useMemo(
    () =>
      facilitiesData?.map((item) => ({
        ...item,
        loans: (
          <ProgressBar currentValue={item.loans} maxValue={item.maxLoans} />
        ),
        lenders: <ChipList maxChips={2} list={item.lenders} />,
        borrowers: <ChipList maxChips={2} list={item.borrowers} />,
        availableCommitment: currencyFormatter(
          item?.availableCommitment,
          item?.currency,
        ),
        maturityDate: item?.maturityDate,
      })),
    [facilitiesData],
  );

  const maxNumberOfBorrowers = facilitiesData
    ?.map((el) => el.borrowers.length)
    .sort()
    .reverse()[0];

  const getBorrowersWidth = () => {
    switch (maxNumberOfBorrowers) {
      case 0:
      case 1:
        return '8%';
      case 2:
        return '12%';
      default:
        return '15%';
    }
  };

  const getLendersWidth = () => {
    switch (maxNumberOfBorrowers) {
      case 0:
      case 1:
        return '23%';
      case 2:
        return '18%';
      default:
        return '15%';
    }
  };

  const columns: Column<FacilityItem>[] = (
    [
      {
        field: 'name',
        title: 'Facility Name',
        width: '12%',
        render: ({ name }) => (
          <ColumnWithTooltip title={name}>{name}</ColumnWithTooltip>
        ),
        ...linkFirstColPadding,
      },
      {
        field: 'status',
        title: '',
        width: '5%',
        cellStyle: {
          ...wrapCell,
          ...linkColPadding.cellStyle,
        },
        render: ({ isMatured }) => {
          if (isMatured) {
            return (
              <Chip
                label="Matured"
                color="secondary"
                variant="outlined"
                size="small"
                style={{ width: 'min-content' }}
              />
            );
          }
        },
      },
      {
        field: 'loans',
        title: (
          <ColumnWithTooltip title="Loans/Max Loans">
            Loans/Max Loans
          </ColumnWithTooltip>
        ),
        width: '8%',
        ...centerColumnText,
      },
      {
        field: 'availableCommitment',
        title: 'Available Commitment',
        width: '10%',
        cellStyle: {
          ...linkColPadding.cellStyle,
          ...columnRightText.cellStyle,
        },
        headerStyle: columnRightText.headerStyle,
      },
      {
        field: 'borrowers',
        title: 'Borrowers',
        width: getBorrowersWidth(),
        cellStyle: {
          ...wrapCell,
          ...linkColPadding.cellStyle,
          ...firstColPadding.cellStyle,
        },
        headerStyle: {
          paddingLeft: '1.5rem',
        },
      },
      {
        field: 'lenders',
        title: 'Lenders',
        width: getLendersWidth(),
        cellStyle: { ...wrapCell, ...linkColPadding.cellStyle },
      },
      {
        field: 'maturityDate',
        title: 'Facility maturity date',
        width: '8%',
        ...columnRightText,
      },
    ] as Column<FacilityItem>[]
  ).map((column) => ({
    ...column,

    render: (row) => (
      <TableLink to={`/facilities/${row.id}/overview`}>
        {column.render
          ? column.render(row, 'row')
          : row[column.field as keyof FacilityItem]}
      </TableLink>
    ),
  }));

  return (
    <Container
      title="Facilities"
      containerHeight="100%"
    >
      <Table
        columns={columns}
        data={facilities}
        onRowClick={() => {}}
        options={{
          maxBodyHeight: '35vh',
          minBodyHeight: '10vh',
          paging: false,
          sorting: false,
          search: false,
          toolbar: false,
          tableLayout: 'fixed',
        }}
        isLoading={areFacilitiesLoading}
      />
    </Container>
  );
};

export default Facilities;
