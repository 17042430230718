import { Bar, ChartData } from 'react-chartjs-2';
import { Preloader } from '@nordictrustee/nt-ui-library';
import { ChartData as CData, ChartTooltipItem } from 'chart.js';
import { getChartColor } from '../../../../themes/theme';
import {
  formatMoney,
  humanizeMoney,
} from '../../../../utils/formatters/currencyFormatter';
import { Maturity } from './MaturityProfileChart.types';

const getOptions = (currency?: string) => ({
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          callback(label: number) {
            return humanizeMoney(currency || '', label);
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label(tooltipItem: ChartTooltipItem, data: CData) {
        if (data?.datasets && data.datasets[0] && data.datasets[0].data) {
          const dataSet = data.datasets[tooltipItem.datasetIndex || 0].data;
          const val: number = dataSet
            ? (dataSet[tooltipItem.index || 0] as number)
            : 0;
          const label: string =
            data.datasets[tooltipItem.datasetIndex || 0].label || 'N/A';
          return `${label}: ${formatMoney(val || 0, currency?.toString())}`;
        }
        return '';
      },
    },
  },
});

const getChartData = (
  maturityProfile?: Maturity,
): ChartData<Chart.ChartData> => {
  if (!maturityProfile) {
    return {};
  }

  const keys = Object.keys(maturityProfile);

  if (!keys || keys.length === 0) {
    return {};
  }

  const datasets = [
    {
      label: 'Repayments',
      borderWidth: 0,
      backgroundColor: getChartColor(0),
      data: maturityProfile['repayments'].map((m) => m.amount ?? 0),
    },
  ];

  const labels = maturityProfile['repayments'].map((m) => m.year ?? 0);

  return {
    labels,
    datasets,
  };
};

interface Props {
  maturityProfile?: Maturity;
  isLoading: boolean;
}

const MaturityProfileChart = ({ maturityProfile, isLoading }: Props) => {
  const chartData = getChartData(maturityProfile);
  const options = getOptions(maturityProfile?.currency);
  return isLoading ? (
    <Preloader />
  ) : (
    <Bar data={chartData} options={options} legend={{ display: false }} />
  );
};

export default MaturityProfileChart;
