import { Link } from 'react-router-dom';
import { Container } from '@mui/material';
import styled from 'styled-components';

export const TableContainer = styled.div`
  .MuiToolbar-root {
    padding-left: 1rem !important;
  }
`;

export const StyleContainer = styled(Container)`
  .MuiTypography-h3 {
    color: #000;
  }
`;

export const ExternalLink = styled(Link)`
  text-decoration: 'none';
  color: #000;
`;
