import { useMemo } from 'react';
import { BoxEmpty, Container, DataView } from '@nordictrustee/nt-ui-library';
import { currencyFormatter } from '../../../../../../utils/formatters/currencyFormatter';
import { getFormattedCalendersValue } from '../../../../../../utils/formatters/getFormattedCalendarsValue';
import { getUnadjustedDate } from '../../../../../../utils/formatters/unadjustedDateFormatter';
import {
  Commitment,
  CommitmentFeeData,
} from '../../../../FacilityDetails.types';

interface Props {
  commitments?: Commitment;
  feePayer?: string;
  commitmentFee?: CommitmentFeeData;
  isLoadingCommitmentFees: boolean;
}

const BasicInformation = ({
  commitments,
  feePayer,
  commitmentFee,
  isLoadingCommitmentFees,
}: Props) => {
  const firstCommitmentFeePaymentDate = getUnadjustedDate(
    commitmentFee?.firstCommitmentFeePaymentDay,
    commitmentFee?.firstCommitmentFeePaymentMonth,
    commitmentFee?.firstCommitmentFeePaymentYear,
  );

  const dataViewHeight = '255px';
  const viewData = useMemo(
    () => [
      {
        data: [
          {
            label: 'Commitment Fee Payer',
            value: feePayer,
          },
          {
            label: 'Available commitment',
            value: currencyFormatter(
              commitments?.availableCommitmentSummary,
              commitments?.currency,
            ),
          },
          {
            label: 'Commitment Fee Start Date',
            value: commitmentFee?.commitmentFeeStartDate,
          },
          {
            label: 'Commitment Fee End Date',
            value: commitmentFee?.commitmentFeeEndDate,
          },
          {
            label: 'First Commitment Fee Payment Date (Unadjusted)',
            value: firstCommitmentFeePaymentDate,
          },
          {
            label: 'Calendar (Quotation Days)',
            value: getFormattedCalendersValue(commitmentFee?.calendarQuotation),
          },
          {
            label: 'Calendar (Business Days)',
            value: getFormattedCalendersValue(commitmentFee?.calendarBusiness),
          },
        ],
      },
    ],
    [
      commitmentFee?.calendarBusiness,
      commitmentFee?.calendarQuotation,
      commitmentFee?.commitmentFeeEndDate,
      commitmentFee?.commitmentFeeStartDate,
      commitments?.availableCommitmentSummary,
      commitments?.currency,
      feePayer,
      firstCommitmentFeePaymentDate,
    ],
  );

  return (
    <Container
      title="Basic information"
      containerHeight="100%"
      isLoadingContent={isLoadingCommitmentFees}
    >
      {commitmentFee ? (
        <DataView
          data={viewData}
          columns={1}
          minRows={7}
          maxRows={7}
          height={dataViewHeight}
          extendContainer
          extendInfoColumnContainer
        />
      ) : (
        <BoxEmpty height="30vh" message="No basic information specified yet" />
      )}
    </Container>
  );
};

export default BasicInformation;
