import { useCallback } from 'react';
import { getFormattedSchedulesData } from 'utils/schedules.utils';
import { useAxios } from '../../utils/hooks/useAxios';
import { LoanItem } from './screens/Overview/components/Loans/Loans.types';
import {
  CommitmentFeeData,
  FacilityBasicInformation,
  FacilityDetailsDataSectionsType,
  FacilityInterestSchedulePeriodsData,
  Payment,
  ScheduleDefinition,
} from './FacilityDetails.types';

export const useGetFacilityBasicInfo = (facilityId?: string) => {
  const [
    { data: facilityBasicInfo, loading: isFacilityLoading },
    getFacilityBasicInfo,
  ] = useAxios<FacilityBasicInformation>(`/facilities/${facilityId}`, {
    manual: true,
  });
  return { facilityBasicInfo, isFacilityLoading, getFacilityBasicInfo };
};

export const useGetCommitmentFees = (facilityId?: string) => {
  const [
    { data: commitmentFee, loading: isLoadingCommitmentFees },
    getCommitmentFees,
  ] = useAxios<CommitmentFeeData>(`/facilities/${facilityId}/commitment-fees`, {
    manual: true,
  });
  return { commitmentFee, isLoadingCommitmentFees, getCommitmentFees };
};

export const useGetCommitmentFeeSchedules = (commitmentFeeId: string) => {
  const [
    { data: commitmentFeeSchedules, loading: isLoadingCommitmentFeeSchedules },
    getCommitmentFeeSchedules,
  ] = useAxios<FacilityInterestSchedulePeriodsData>(
    `/commitment-fees/${commitmentFeeId}/commitment-fee-schedules`,
    {
      manual: true,
    },
  );
  return {
    commitmentFeeSchedules: getFormattedSchedulesData(commitmentFeeSchedules!),
    isLoadingCommitmentFeeSchedules,
    getCommitmentFeeSchedules,
  };
};

export const useGetLoans = (facilityID?: string) => {
  const [{ data: loansData = [], loading: areLoansLoading }, getLoans] =
    useAxios<LoanItem[]>(`facilities/${facilityID}/loans`, {
      manual: true,
    });
  return { loansData, areLoansLoading, getLoans };
};

export const useGetCommitmentFeeScheduleDefinitions = (facilityId?: string) => {
  const [
    { data: definitions = [], loading: isLoading },
    getScheduleDefinitions,
  ] = useAxios<ScheduleDefinition[]>(
    `/facilities/${facilityId}/commitment-fee-schedule-definitions`,
    {
      manual: true,
    },
  );
  return { definitions, isLoading, getScheduleDefinitions };
};

export const useGetPayments = () => {
  const [{ data: payments = [], loading: isLoadingPayments }, getPaymentsData] =
    useAxios<Payment[]>(
      { method: 'GET' },
      {
        manual: true,
      },
    );

  const getGetUrl = (
    sectionName: FacilityDetailsDataSectionsType,
    itemId: number,
    periodId?: number,
  ) => {
    switch (sectionName) {
      case FacilityDetailsDataSectionsType.FacilityCommitmentFeeSchedule: {
        return `/commitment-fee-schedules/periods/${periodId}/payments`;
      }
      case FacilityDetailsDataSectionsType.LoanAmountAdjustment: {
        return `/amount-adjustment-payments/${itemId}/payments`;
      }
      case FacilityDetailsDataSectionsType.LoanInterestSchedule: {
        return `/interest-schedules/periods/${periodId}/interest-payments`;
      }
      default: {
        return '';
      }
    }
  };

  const getPayments = useCallback(
    async (
      itemId: number,
      sectionName: FacilityDetailsDataSectionsType,
      periodId?: number,
    ) => {
      const url = getGetUrl(sectionName, itemId, periodId);
      return await getPaymentsData({ url });
    },
    [getPaymentsData],
  );

  return { payments, isLoadingPayments, getPayments };
};
