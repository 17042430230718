import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import {
  ColumnWithTooltip,
  Container,
  DataView,
  DateProgressBar,
  InfoDoughnut,
  SelectUncontrolled,
} from '@nordictrustee/nt-ui-library';
import moment from 'moment/moment';
import { currencyFormatter } from 'utils/formatters/currencyFormatter';
import { facilityAgreementColor } from '../../../../../../../themes/theme';
import { dateDisplayFormat } from '../../../../../../../utils/constants';
import { isEmptyObject } from '../../../../../../../utils/functional';
import { RouteParam } from '../../../../../../../utils/types/RouteParam';
import { BasicAgreement } from '../../../../../FacilityAgreement.types';
import {
  useGetCaseHandlerPhoto,
  useGetCurrenciesForAgreement,
} from '../../../api';
import {
  CurrencyContainer,
  DataContainer,
  MarginContainer,
  Photo,
  PhotoContainer,
  PhotoPlaceholder,
  PlaceholderAvatar,
  PortraitPhotoContainer,
  SectionDiv,
  SelectWrapper,
  Title,
} from './BasicInformation.css';

interface Props {
  data?: BasicAgreement;
  isLoading?: boolean;
  setCurrency: Dispatch<SetStateAction<string | undefined>>;
  currency?: string;
}

const BasicInformation = ({
  data,
  isLoading,
  setCurrency,
  currency,
}: Props) => {
  const { agreementId } = useParams<RouteParam>();
  const { currencies, currencyOptions, areCurrenciesLoading, getFACurrencies } =
    useGetCurrenciesForAgreement(agreementId);
  const { caseHandler, isPhotoLoading, getCaseHandlerPhoto } =
    useGetCaseHandlerPhoto(agreementId);

  const photo = useMemo(
    () => JSON.parse(localStorage.getItem(`photo-${agreementId}`) || '{}'),
    [agreementId],
  );

  useEffect(() => {
    agreementId && getFACurrencies();
  }, [agreementId, getFACurrencies]);

  useEffect(() => {
    agreementId && isEmptyObject(photo) && getCaseHandlerPhoto();
  }, [agreementId, getCaseHandlerPhoto, photo]);

  useEffect(() => {
    if (currencies) {
      let defaultCurrency = '';
      if (currencies.length > 0) {
        defaultCurrency = currencies[0];
      }
      setCurrency(defaultCurrency);
    }
  }, [currencies, setCurrency]);

  const viewDataBasic = useMemo(
    () => [
      {
        data: [
          { label: 'Governing Law', value: data?.governingLaw },
          {
            label: 'Total commitment',
            value: (
              <ColumnWithTooltip
                title={
                  currencies && currencies.length > 1 && currency === 'EUR'
                    ? 'In cases where commitments are made in more than one currency, the amount is calculated based on latest daily relevant FIX rates'
                    : ''
                }
              >
                {currencyFormatter(data?.totalCommitment, data?.currency)}
              </ColumnWithTooltip>
            ),
          },
          {
            label: 'Compliance Reporting',
            component: data?.complianceReporting,
          },
        ],
      },
    ],
    [
      currencies,
      currency,
      data?.complianceReporting,
      data?.currency,
      data?.governingLaw,
      data?.totalCommitment,
    ],
  );

  const viewDataAgents = useMemo(
    () => [
      {
        data: [
          { label: 'Facility Agent', value: data?.facilityAgent?.name },
          { label: 'Security Agent', value: data?.securityAgent?.name },
          { label: 'Calculation Agent', value: data?.calculationAgent?.name },
        ],
      },
    ],
    [data],
  );

  const viewDataDates = useMemo(
    () => [
      {
        data: [
          { label: 'Signing date', value: data?.signingDate },
          {
            label: 'First Utilisation date',
            value: data?.firstUtilisationDate,
          },
          {
            label: 'Final Maturity date',
            value: data?.finalMaturityDate,
            title:
              'The Maturity Date of the Facility that ends furthest in the future',
          },
        ],
      },
    ],
    [data],
  );

  return (
    <Container
      isHeader={false}
      containerHeight="100%"
      isLoadingContent={isLoading}
    >
      <MarginContainer />
      <DataContainer>
        <SectionDiv>
          <CurrencyContainer>
            <Title>Basic information</Title>
            <SelectWrapper
              hideContainer={!currencies?.length || currencies.length === 1}
            >
              <SelectUncontrolled
                name="currency"
                value={currency}
                options={currencyOptions}
                onChange={(value: string) => setCurrency(value)}
                isLoading={areCurrenciesLoading}
                disabled={!currencies?.length}
              />
            </SelectWrapper>
          </CurrencyContainer>
          <DataView
            data={viewDataBasic}
            columns={1}
            extendContainer
            extendInfoColumnContainer
          />
        </SectionDiv>
        <SectionDiv>
          <Title>Available Commitment</Title>
          {data && (
            <Box display="flex" ml="1rem">
              <InfoDoughnut
                currentValue={data.availableCommitment}
                maximumValue={data.totalCommitment}
                hideLabels
                currency={data.currency}
                size={2}
                darkColor={facilityAgreementColor.dark}
                lightColor={facilityAgreementColor.light}
              />
            </Box>
          )}
        </SectionDiv>
        <SectionDiv>
          <Title>Case handler</Title>
          <PhotoContainer>
            {(isPhotoLoading || !caseHandler?.content) &&
            isEmptyObject(photo) ? (
              <PhotoPlaceholder>
                <PlaceholderAvatar />
              </PhotoPlaceholder>
            ) : (
              <PortraitPhotoContainer>
                <Photo
                  src={`data:image/png;base64,${
                    isEmptyObject(photo) ? caseHandler?.content : photo
                  }`}
                />
              </PortraitPhotoContainer>
            )}
            <Typography variant="body2" ml="12px">
              {data?.caseHandlerName}
            </Typography>
          </PhotoContainer>
        </SectionDiv>
        <SectionDiv>
          <Title>Agents</Title>
          <DataView
            data={viewDataAgents}
            columns={1}
            extendContainer
            extendInfoColumnContainer
          />
        </SectionDiv>
        <SectionDiv>
          <Title>Dates</Title>
          <div>
            <DataView
              data={viewDataDates}
              columns={1}
              extendContainer
              extendInfoColumnContainer
            />
            <Box ml="1rem" mr="1rem">
              <DateProgressBar
                legendDisplay="below"
                startDate={
                  data?.signingDate
                    ? moment(data.signingDate, dateDisplayFormat)
                    : null
                }
                endDate={
                  data?.finalMaturityDate
                    ? moment(data.finalMaturityDate, dateDisplayFormat)
                    : null
                }
                height={20}
              />
            </Box>
          </div>
        </SectionDiv>
      </DataContainer>
    </Container>
  );
};

export default BasicInformation;
