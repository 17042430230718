import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { RouteParam } from '../../../../../utils/types/RouteParam';
import CovenantDocumentList from './components/CovenantDocumentList';
import CovenantScheduleList from './components/CovenantScheduleList';
import * as api from './api';
import { ComplianceScheduleItem } from './ComplianceSchedule.types';

const ComplianceSchedule = () => {
  const [selectedSchedule, setSelectedSchedule] = useState<ComplianceScheduleItem>();
  const { agreementId } = useParams<RouteParam>();

  const {
    complianceScheduleItemData,
    isLoadingComplianceScheduleItem,
    getComplianceScheduleItem,
  } = api.useGetComplianceScheduleItem(agreementId);

  const {
    covenantDocumentsData,
    isLoadingCovenantDocuments,
    getCovenantDocuments,
  } = api.useGetCovenantDocuments((selectedSchedule as ComplianceScheduleItem)?.id);

  useEffect(() => {
    if (agreementId) {
      getComplianceScheduleItem();
    }
  }, [agreementId, getComplianceScheduleItem]);

  useEffect(() => {
    if (selectedSchedule && !isLoadingComplianceScheduleItem) {
      getCovenantDocuments();
    }
  }, [getCovenantDocuments, isLoadingComplianceScheduleItem, selectedSchedule]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <CovenantScheduleList
          complianceScheduleItemData={complianceScheduleItemData}
          isLoadingComplianceScheduleItem={isLoadingComplianceScheduleItem}
          selectedSchedule={selectedSchedule}
          setSelectedSchedule={setSelectedSchedule}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CovenantDocumentList
          covenantDocumentsData={covenantDocumentsData}
          isLoadingCovenantDocuments={isLoadingCovenantDocuments}
          selectedSchedule={selectedSchedule}
          isLoadingComplianceScheduleItem={isLoadingComplianceScheduleItem}
        />
      </Grid>
    </Grid>
  );
};

export default ComplianceSchedule;
