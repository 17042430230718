import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Column, Options } from '@material-table/core';
import { TableHead } from '@mui/material';
import {
  ColumnWithTooltip,
  StickyHeaderCell,
  StickyHeaderRow,
  Table,
} from '@nordictrustee/nt-ui-library';
import { FacilityInterestSchedule } from 'modules/FacilityDetails/FacilityDetails.types';
import { dayCountConventionOptions } from 'modules/FacilityDetails/screens/CommitmentFee/components/SchedulePreview/mock';
import { centerColumnText, columnWithRightBorder } from 'utils/constants';
import { decimalNumberWithPercentFormatter } from 'utils/formatters/numberFormatter';
import scrollTop from 'utils/scrollTop';
import scrollToRow from 'utils/scrollToRow';
import { selectedRowStyle } from 'utils/selectedRowStyle';
import { RouteParam } from 'utils/types/RouteParam';
import { loanColor } from '../../../../themes/theme';

interface Props {
  preview?: boolean;
  interestSchedulesData: FacilityInterestSchedule[];
  isTableLoading: boolean;
  selectedItemId: number;
  setSelectedItemId: Dispatch<SetStateAction<number>>;
}

const InterestScheduleTable = ({
  preview,
  interestSchedulesData,
  isTableLoading,
  selectedItemId,
  setSelectedItemId,
}: Props) => {
  const { push } = useHistory();
  const { loanId, facilityId } = useParams<RouteParam>();
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  const tableData = useMemo(
    () =>
      interestSchedulesData.map((schedule) => ({
        ...schedule,
        dayCountConvention: dayCountConventionOptions.find(
          (conv) => conv.value === schedule.dayCountConvention,
        )?.label,
      })),
    [interestSchedulesData],
  );

  const columns: Column<FacilityInterestSchedule>[] = useMemo(
    () => [
      {
        field: 'termStart',
        title: (
          <StickyHeaderCell
            title="Term Start"
            headerTextAlign="center"
            customWidth="6.6rem"
          />
        ),
        render: (item) => (
          <ColumnWithTooltip title={`${item.termStart}`}>
            {item.termStart}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: 'termEnd',
        title: (
          <StickyHeaderCell
            title="Term End"
            headerTextAlign="center"
            customWidth="6.6rem"
          />
        ),
        render: (item) => (
          <ColumnWithTooltip title={`${item.termEnd}`}>
            {item.termEnd}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: 'paymentDate',
        title: (
          <StickyHeaderCell
            title="Payment Date"
            headerTextAlign="center"
            rightBorder
            customWidth="6.8rem"
          />
        ),
        render: (item) => (
          <ColumnWithTooltip title={`${item.paymentDate}`}>
            {item.paymentDate}
          </ColumnWithTooltip>
        ),
        cellStyle: {
          ...columnWithRightBorder.cellStyle,
          ...centerColumnText.cellStyle,
        },
      },
      {
        field: 'dayCountConvention',
        title: (
          <StickyHeaderCell
            title="Day Conv."
            customWidth="5.8rem"
            headerTextAlign="left"
          />
        ),
        render: (item) => (
          <ColumnWithTooltip title={`${item.dayCountConvention}`}>
            {item?.dayCountConvention}
          </ColumnWithTooltip>
        ),
      },
      {
        field: 'days',
        title: (
          <StickyHeaderCell
            title="Days"
            headerTextAlign="center"
            rightBorder
            customWidth="3.2rem"
          />
        ),
        render: (item) => (
          <ColumnWithTooltip title={`${item.days}`}>
            {item.days}
          </ColumnWithTooltip>
        ),
        cellStyle: {
          ...columnWithRightBorder.cellStyle,
          ...centerColumnText.cellStyle,
        },
      },
      {
        field: 'quotationDate',
        title: (
          <StickyHeaderCell
            title="Quotation Date"
            headerTextAlign="center"
            customWidth={preview ? '6rem' : '7rem'}
          />
        ),
        render: (item) => (
          <ColumnWithTooltip title={`${item.quotationDate}`}>
            {item.quotationDate}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: 'referenceRateValue',
        title: (
          <StickyHeaderCell
            title="Reference Rate"
            customWidth={preview ? '4.5rem' : '6rem'}
            headerTextAlign="center"
          />
        ),
        render: (item) => (
          <ColumnWithTooltip
            title={decimalNumberWithPercentFormatter(item.referenceRateValue)}
          >
            {decimalNumberWithPercentFormatter(item.referenceRateValue)}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: 'margin',
        title: (
          <StickyHeaderCell
            title="Margin"
            headerTextAlign="center"
            customWidth={preview ? '5rem' : '5.5rem'}
          />
        ),
        render: (item) => (
          <ColumnWithTooltip
            title={decimalNumberWithPercentFormatter(item.margin)}
          >
            {decimalNumberWithPercentFormatter(item.margin)}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: 'coupon',
        title: (
          <StickyHeaderCell
            title="Coupon"
            headerTextAlign="center"
            customWidth={preview ? '5.2rem' : '6rem'}
          />
        ),
        render: (item) => (
          <ColumnWithTooltip
            title={decimalNumberWithPercentFormatter(item.coupon!)}
          >
            {decimalNumberWithPercentFormatter(item.coupon!)}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: 'floor',
        title: (
          <StickyHeaderCell
            title="Floor Rate"
            headerTextAlign="center"
            rightBorder
            customWidth={preview ? '4.5rem' : '5.5rem'}
          />
        ),
        render: (item) => (
          <ColumnWithTooltip
            title={decimalNumberWithPercentFormatter(item.floor)}
          >
            {decimalNumberWithPercentFormatter(item.floor)}
          </ColumnWithTooltip>
        ),
        cellStyle: {
          ...columnWithRightBorder.cellStyle,
          ...centerColumnText.cellStyle,
        },
      },
      {
        field: 'cash',
        title: (
          <StickyHeaderCell
            title="Cash"
            headerTextAlign="center"
            customWidth={preview ? '4rem' : '6rem'}
          />
        ),
        render: (item) => (
          <ColumnWithTooltip
            title={decimalNumberWithPercentFormatter(item.cash)}
          >
            {decimalNumberWithPercentFormatter(item.cash)}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: 'pik',
        title: (
          <StickyHeaderCell
            title="PIK"
            headerTextAlign="center"
            customWidth={preview ? '4rem' : '6rem'}
          />
        ),
        render: (item) => (
          <ColumnWithTooltip
            title={decimalNumberWithPercentFormatter(item.pik)}
          >
            {decimalNumberWithPercentFormatter(item.pik)}
          </ColumnWithTooltip>
        ),
        ...centerColumnText,
      },
      {
        field: 'capitalised',
        title: (
          <StickyHeaderCell
            title="Capitalised"
            headerTextAlign="center"
            rightBorder
            customWidth={preview ? '4rem' : '6rem'}
          />
        ),
        render: (item) => (
          <ColumnWithTooltip
            title={decimalNumberWithPercentFormatter(item.capitalised)}
          >
            {decimalNumberWithPercentFormatter(item.capitalised)}
          </ColumnWithTooltip>
        ),
        cellStyle: {
          ...columnWithRightBorder.cellStyle,
          ...centerColumnText.cellStyle,
        },
      },
    ],
    [preview],
  );

  const tableOptions: Options<FacilityInterestSchedule> = useMemo(
    () => ({
      maxBodyHeight: preview ? '30vh' : '28vh',
      minBodyHeight: preview ? '30vh' : '28vh',
      search: false,
      toolbar: false,
      paging: false,
      rowStyle: (rowData) =>
        selectedRowStyle(rowData.id, selectedItemId, loanColor.rowSelection),
    }),
    [preview, selectedItemId],
  );

  const tableComponents = useMemo(
    () => ({
      Header: () => (
        <TableHead>
          <StickyHeaderRow>
            <StickyHeaderCell
              isTopHeader
              colSpan={10}
              title="Basic Information"
            />
            <StickyHeaderCell
              isTopHeader
              colSpan={3}
              title="Coupon Distribution"
            />
          </StickyHeaderRow>
          <StickyHeaderRow>
            {columns.map(
              (item: Column<FacilityInterestSchedule>, index: number) => {
                const Title = () => item?.title as JSX.Element;
                return <Title key={`data-${index}`} />;
              },
            )}
          </StickyHeaderRow>
        </TableHead>
      ),
    }),
    [columns],
  );

  const onRowClick = (_: any, item: FacilityInterestSchedule) => {
    setSelectedItemId(Number(item.id));
    push(
      `/facilities/${facilityId}/loan/${loanId}/interest-schedule/${item.id}`,
    );
  };

  useEffect(() => {
    if (selectedItemId && isFirstRender) {
      scrollToRow(selectedItemId);
      scrollTop();
      setIsFirstRender(false);
    }
  }, [isFirstRender, selectedItemId]);

  return (
    <Table
      isLoading={isTableLoading}
      columns={columns}
      data={tableData}
      options={tableOptions}
      onRowClick={!preview ? onRowClick : undefined}
      components={tableComponents}
    />
  );
};

export default InterestScheduleTable;
