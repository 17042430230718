import { toast } from 'react-toastify';
import { ErrorToast } from '@nordictrustee/nt-ui-library';
import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';

const axiosInstance = axios.create({
  baseURL: '/api/portal',
});

const browserSessionIdHeader = 'X-NT-Browser-Session-Id';
const browserOriginHeader = 'X-NT-Browser-Request-Origin';
const browserOriginNameHeader = 'X-NT-Browser-Request-Origin-Name';

axiosInstance.interceptors.request.use(
  async (config) => {
    const faroSessionId = JSON.parse(
      sessionStorage.getItem('com.grafana.faro.session') ?? ''
    )?.sessionId;

    const browserRequestOrigin =
      window.location.origin + window.location.pathname;

    const state = JSON.parse(localStorage.getItem('state') || '{}');
    const token = state?.user?.accessToken;
    if (!config?.headers?.Authorization && token) {
      config.headers.set({
        ...config.headers,
        Authorization: `Bearer ${token}`,
        [browserSessionIdHeader]: faroSessionId,
        [browserOriginHeader]: browserRequestOrigin,
        [browserOriginNameHeader]: 'Loan portal',
      });
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    let serverMessage;
    let serverDetails;
    if (Array.isArray(error?.response?.data)) {
      serverMessage = error.response.data
        .map((err: any) => err.message + '\n\n')
        .join(' ');
      serverDetails = error.response.data
        .map((err: any) => err.details)
        .join(' ');
    } else {
      serverMessage = error?.response?.data?.message;
      serverDetails = error?.response?.data?.details;
    }

    const message =
      serverMessage ||
      `Unknown error has occurred. 
      Please contact us.`;

    if (error?.response?.status === 403) {
      window.location.href = '/';
    }

    if (error?.response?.status === 401) {
      localStorage.removeItem('state');
      window.location.href = '/login';
    }

    // do not show error if response is cancelled (e.g. on fast tabs switching)
    if (!axios.isCancel(error) && error?.response) {
      toast.error(<ErrorToast message={message} />, { autoClose: false });
    }
    if (serverDetails) {
      console.error(`API response error serverDetails: ${serverDetails}`);
    }
    return Promise.reject(error);
  }
);

export const useAxios = makeUseAxios({
  axios: axiosInstance,
});
