import { useAxios } from 'utils/hooks/useAxios';
import { LastVisitedFAs } from './LastVisitedFacilityAgreements.types';

export const useGetLastVisitedFAs = () => {
  const [
    { data: lastVisitedFAsData, loading: isDataLoading },
    getLastVisitedFAs,
  ] = useAxios<LastVisitedFAs[]>(
    {
      url: `/visit-logs?count=6`,
    },
    { manual: true },
  );
  return { lastVisitedFAsData, isDataLoading, getLastVisitedFAs };
};
