import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { Container, DividerWithText } from '@nordictrustee/nt-ui-library';
import LenderSplit from 'modules/FacilityDetails/screens/CommitmentFeeSchedule/components/CommitmentFeePeriod/components/LenderSplit';
import InterestScheduleTable from 'modules/Loan/components/InterestScheduleTable';
import { findClosestToTodayPeriod } from 'utils/schedules.utils';
import { RouteParam } from 'utils/types/RouteParam';
import { useGetPayments } from '../../../FacilityDetails/api';
import {
  FacilityDetailsDataSectionsType,
  FacilityInterestSchedule,
  Payment,
} from '../../../FacilityDetails/FacilityDetails.types';
import Payments from '../../../FacilityDetails/screens/CommitmentFeeSchedule/components/CommitmentFeePeriod/components/Payments';
import * as api from './../api';

const InterestSchedule = () => {
  const loanInterestScheduleSection =
    FacilityDetailsDataSectionsType.LoanInterestSchedule;
  const [selectedItemId, setSelectedItemId] = useState(0);
  const { loanId } = useParams<RouteParam>();
  const [selectedPayment, setSelectedPayment] = useState<Payment>();
  const { payments, isLoadingPayments, getPayments } = useGetPayments();

  const {
    interestSchedulesData,
    isLoadingInterestSchedules,
    getInterestSchedules,
  } = api.useGetInterestSchedules(loanId);

  const currentPeriodItem = useMemo(
    () =>
      interestSchedulesData?.find(
        ({ id }: FacilityInterestSchedule) => id === Number(selectedItemId),
      ),
    [interestSchedulesData, selectedItemId],
  );

  useEffect(() => {
    selectedItemId &&
      getPayments(
        selectedItemId,
        loanInterestScheduleSection,
        currentPeriodItem?.periodId,
      );
  }, [
    currentPeriodItem?.periodId,
    getPayments,
    loanInterestScheduleSection,
    selectedItemId,
  ]);

  useEffect(() => {
    loanId && getInterestSchedules();
  }, [getInterestSchedules, loanId]);

  useEffect(() => {
    if (getInterestSchedules?.length) {
      const closestPeriodId = findClosestToTodayPeriod(
        interestSchedulesData,
      )?.id;
      if (selectedItemId === 0 || selectedItemId === undefined) {
        // only at first render
        setSelectedItemId(closestPeriodId);
      }
    }
  }, [getInterestSchedules?.length, interestSchedulesData, selectedItemId]);

  useEffect(() => {
    if (payments?.length) {
      setSelectedPayment(payments[0]);
    }
  }, [payments]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Container
          title="Interest Schedule"
        >
          <InterestScheduleTable
            interestSchedulesData={interestSchedulesData}
            isTableLoading={isLoadingInterestSchedules}
            selectedItemId={selectedItemId}
            setSelectedItemId={setSelectedItemId}
          />
        </Container>
      </Grid>
      <DividerWithText
        margin="1rem 0 0"
        text={
          <Typography variant="h3">
            {`Interest Period: ${currentPeriodItem?.periodStartDate ?? ''} - ${
              currentPeriodItem?.periodEndDate ?? ''
            }`}
          </Typography>
        }
      />
      <Grid item xs={12}>
        <Container
          isHeader={false}
          noBorder
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Payments
                sectionName={loanInterestScheduleSection}
                payments={payments}
                isLoadingPayments={isLoadingPayments}
                setSelectedPayment={setSelectedPayment}
                selectedPayment={selectedPayment}
                itemId={selectedItemId}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LenderSplit
                sectionName={loanInterestScheduleSection}
                payment={selectedPayment}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default InterestSchedule;
