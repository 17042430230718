import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import {
  FacilityDetailsDataSectionsType,
  Payment,
} from 'modules/FacilityDetails/FacilityDetails.types';
import LenderSplit from 'modules/FacilityDetails/screens/CommitmentFeeSchedule/components/CommitmentFeePeriod/components/LenderSplit';
import { findClosestToTodayAdjustment } from '../../../../utils/findCLosestToTodayAdjustment';
import scrollTop from '../../../../utils/scrollTop';
import scrollToRow from '../../../../utils/scrollToRow';
import { RouteParam } from '../../../../utils/types/RouteParam';
import { useGetPayments } from '../../../FacilityDetails/api';
import Payments from '../../../FacilityDetails/screens/CommitmentFeeSchedule/components/CommitmentFeePeriod/components/Payments';
import * as api from '../api';
import AmountAdjustmentData from './components/AmountAdjustmentData';

const AmountAdjustment = () => {
  const loanAmountAdjustmentSection =
    FacilityDetailsDataSectionsType.LoanAmountAdjustment;
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [selectedPayment, setSelectedPayment] = useState<Payment>();
  const { loanId } = useParams<RouteParam>();

  const {
    amountAdjustmentsData,
    isLoadingAmountAdjustmentsData,
    getAmountAdjustmentsData,
  } = api.useGetAmountAdjustmentsData(loanId);

  const { payments, isLoadingPayments, getPayments } = useGetPayments();

  const currentPeriodItem = useMemo(
    () =>
      amountAdjustmentsData?.find(({ id }) => id === Number(selectedItemId)),
    [amountAdjustmentsData, selectedItemId],
  );

  useEffect(() => {
    loanId && getAmountAdjustmentsData();
  }, [getAmountAdjustmentsData, loanId]);

  useEffect(() => {
    selectedItemId && getPayments(selectedItemId, loanAmountAdjustmentSection);
  }, [loanAmountAdjustmentSection, getPayments, selectedItemId]);

  useEffect(() => {
    if (amountAdjustmentsData?.length) {
      const closestPeriodId = findClosestToTodayAdjustment(
        amountAdjustmentsData,
      )?.id;
      if (selectedItemId === 0 || selectedItemId === undefined) {
        // only at first render
        setSelectedItemId(closestPeriodId);
        scrollToRow(closestPeriodId);
        scrollTop();
      }
    }
  }, [amountAdjustmentsData, selectedItemId]);

  useEffect(() => {
    if (payments?.length) {
      setSelectedPayment(payments[0]);
    }
  }, [payments]);

  return (
    <Grid spacing={2} container>
      <Grid item xs={12} lg={7}>
        <AmountAdjustmentData
          amountAdjustmentsData={amountAdjustmentsData}
          isLoadingAmountAdjustmentsData={isLoadingAmountAdjustmentsData}
          selectedItemId={selectedItemId}
          setSelectedItemId={setSelectedItemId}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <Box mb={2}>
          <Payments
            sectionName={loanAmountAdjustmentSection}
            payments={payments}
            isLoadingPayments={isLoadingPayments}
            setSelectedPayment={setSelectedPayment}
            selectedPayment={selectedPayment}
            itemId={selectedItemId}
            period={currentPeriodItem?.paymentDate}
          />
        </Box>
        <Box>
          <LenderSplit
            sectionName={loanAmountAdjustmentSection}
            payment={selectedPayment}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AmountAdjustment;
