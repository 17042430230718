import { Toolbar } from '@nordictrustee/nt-ui-library';
import styled from 'styled-components';
import defaultTheme from 'themes';

export const Footer = styled.footer`
  background-color: ${defaultTheme.theme.palette.common.black};
  color: ${defaultTheme.theme.palette.common.white};
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
`;

export const FooterToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

export const RightToolbar = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
  font-size: 16px;
  a {
    color: white;
    padding-left: 5px;
  }
`;

export const LeftToolbar = styled(RightToolbar)`
  display: flex;
  gap: 20px;
`;
