import { Container } from '@mui/material';
import styled from 'styled-components';
import { facilityColor } from '../../themes/theme';

export const StyleContainer = styled(Container)`
  .MuiTypography-h3 {
    color: ${facilityColor.main};
  }
  .MuiTabs-indicator {
    background: ${facilityColor.dark};
  }
  button {
    &.Mui-selected {
      color: ${facilityColor.dark};
    }
  }
  .MuiCircularProgress-root {
    color: ${facilityColor.dark};
  }
  nav {
    li:last-child {
      a {
        color: ${facilityColor.header};
      }
    }
  }
`;
