import { UnadjustedDate } from 'utils/types/UnadjustedDate';

export const getUnadjustedDate = (
  day?: number | null,
  month?: number | null,
  year?: number | null,
) => {
  if (day && month && year) {
    const dateValue = getFormattedDate(String(day));
    const monthValue = getFormattedDate(String(month));
    return `${dateValue}.${monthValue}.${year}`;
  }
  return '';
};

export const getUnadjustedDateFromObject = (
  unadjustedDate?: UnadjustedDate,
) => {
  if (unadjustedDate) {
    const dateValue = getFormattedDate(String(unadjustedDate.day));
    const monthValue = getFormattedDate(String(unadjustedDate.month));
    return `${dateValue}.${monthValue}.${unadjustedDate.year}`;
  }
  return '';
};

// backend returns '1', so for '01' value we need to add '0' in front of that value
export const getFormattedDate = (value: any) => {
  if (typeof value === 'string') {
    return value?.length === 1 ? `0${value}` : value;
  }
  return '';
};

export const splitStringDateToDayMonthYear = (date: string | null) => {
  if (!date) {
    return {};
  }

  const splitDate = date?.split('.');
  const [day, month, year] = splitDate;

  const dayNumber = Number(day);
  const monthNumber = Number(month);
  const yearNumber = Number(year);

  return { dayNumber, monthNumber, yearNumber };
};

// to date - string comparison
export const changeDateFormatToYYYYMMDD = (date: string) => {
  const { dayNumber, monthNumber, yearNumber } = splitStringDateToDayMonthYear(
    date,
  );
  return `${yearNumber}.${getFormattedDate(
    monthNumber?.toString(),
  )}.${getFormattedDate(dayNumber?.toString())}`;
};
