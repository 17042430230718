import { useCallback, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider/AuthProvider';
import Cookies from 'components/CookiesFooter';
import Header from 'components/Header';
import ForgotPassword from 'modules/Auth/ForgotPassword';
import Login from 'modules/Auth/Login';
import { ACCEPT_COOKIES } from '../components/CookiesFooter/Cookies';
import About, { AboutLayout } from '../modules/About';
import SentEmailConfirmation from '../modules/Auth/ForgotPassword/SentEmailConfirmation';
import ResetPassword from '../modules/Auth/ResetPassword';
import VerifyAccount from '../modules/Auth/VerifyAccount';
import FacilityAgreements from '../modules/FacilityAgreements/FacilityAgreements';
import FacilityAgreementDetails from '../modules/FacilityAgreements/screens/FacilityAgreementDetails/FacilityAgreementDetails';
import FacilityDetails from '../modules/FacilityDetails/FacilityDetails';
import LoanDetails from '../modules/Loan/LoanDetails/LoanDetails';
import Profile from '../modules/Profile/Profile';
import VerifyAcceptedUserTerms from '../modules/TermsAndConditions/VerifyAcceptedUserTerms/VerifyAcceptedUserTerms';
import * as URL from './url';

const Routes = () => {
  const [cookies] = useCookies([ACCEPT_COOKIES]);
  const [rejectCookies, setRejectCookies] = useState(false);
  const { isAuthenticated, setIsAuthenticated, user, setUser } =
    useContext(AuthContext);

  useEffect(() => {
    if (rejectCookies) {
      localStorage.removeItem('state');
      setIsAuthenticated(false);
      setUser(undefined);
      window.location.reload();
    }
  });
  const UnauthenticatedRoutes = useCallback(() => {
    return (
      <Switch>
        <Route path={URL.LOGIN}>
          <AboutLayout logInMarkup={<Login />} />
        </Route>
        <Route path={URL.FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>
        <Route path={URL.VERIFY_ACCOUNT}>
          <VerifyAccount />
        </Route>
        <Route path={URL.RESET_PASSWORD}>
          <ResetPassword />
        </Route>
        <Route path={URL.SENT_EMAIL_CONFIRMATION}>
          <SentEmailConfirmation />
        </Route>
        <Redirect to={URL.LOGIN} />
      </Switch>
    );
  }, []);

  const AuthenticatedRoutes = useCallback(() => {
    if (!user?.termsAccepted) {
      return <Redirect to={URL.TERMS} />;
    }
    return (
      <Switch>
        <Route exact path={URL.FACILITY_AGREEMENTS}>
          <FacilityAgreements />
        </Route>
        <Route exact path={URL.FACILITY_AGREEMENT_DETAILS}>
          <FacilityAgreementDetails />
        </Route>
        <Route exact path={URL.FACILITY_DETAILS}>
          <FacilityDetails />
        </Route>
        <Route exact path={URL.LOAN_DETAILS}>
          <LoanDetails />
        </Route>
        <Route exact path={URL.PROFILE}>
          <Profile />
        </Route>
        <Redirect to={URL.FACILITY_AGREEMENTS} />
      </Switch>
    );
  }, [user?.termsAccepted]);

  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path={URL.TERMS}>
          <VerifyAcceptedUserTerms
            withActions={isAuthenticated && !user?.termsAccepted}
          />
        </Route>
        <Route exact path={URL.ABOUT}>
          <About />
        </Route>
        {!isAuthenticated ? <UnauthenticatedRoutes /> : <AuthenticatedRoutes />}
      </Switch>
      {!cookies[ACCEPT_COOKIES] && !rejectCookies && (
        <Cookies setRejectCookies={setRejectCookies} />
      )}
    </BrowserRouter>
  );
};

export default Routes;
