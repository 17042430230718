import { useMemo } from 'react';
import { Column } from '@material-table/core';
import {
  ColumnWithTooltip,
  Container,
  InitialContent,
  Table,
} from '@nordictrustee/nt-ui-library';
import { centerColumnText, firstColPadding } from 'utils/constants';
import { getInitDate } from 'utils/formatters/dateFormatter';
import {
  ComplianceScheduleItem,
  CovenantDocument,
} from '../../ComplianceSchedule.types';

interface Props {
  covenantDocumentsData?: CovenantDocument[];
  isLoadingCovenantDocuments: boolean;
  selectedSchedule?: ComplianceScheduleItem;
  isLoadingComplianceScheduleItem: boolean;
}

const CovenantDocumentList = ({
  covenantDocumentsData,
  isLoadingCovenantDocuments,
  selectedSchedule,
  isLoadingComplianceScheduleItem,
}: Props) => {
  const columns: Column<CovenantDocument>[] = useMemo(
    () => [
      {
        field: 'covenant',
        title: 'Covenant',
        width: '33%',
        render: (item) => (
          <ColumnWithTooltip title={item.covenant} inheritWidth>
            {item.covenant}
          </ColumnWithTooltip>
        ),
        ...firstColPadding,
      },
      {
        field: 'receivedDate',
        title: 'Received',
        customSort: (a, b) =>
          Number(getInitDate(a.receivedDate)) -
          Number(getInitDate(b.receivedDate)),
        defaultSort: 'asc',
        ...centerColumnText,
      },
      {
        field: 'remindedDate',
        title: 'Reminded',
        ...centerColumnText,
      },
    ],
    [],
  );

  return (
    <Container
      title="Documents"
      containerHeight="100%"
      isLoadingContent={isLoadingCovenantDocuments || isLoadingComplianceScheduleItem}
    >
      {selectedSchedule ? (
        <Table
          columns={columns}
          data={covenantDocumentsData ?? []}
          hideSortingArrows
          options={{
            paging: false,
            search: false,
            toolbar: false,
            sorting: true,
            minBodyHeight: '60vh',
            maxBodyHeight: '60vh',
            actionsCellStyle: {
              height: '3rem',
              alignItems: 'center',
            },
            rowStyle: {
              height: '2.5rem',
            },
          }}
        />
      ) : (
        <InitialContent
          description="Select Schedule item for details"
        />
      )}
    </Container>
  );
};

export default CovenantDocumentList;
