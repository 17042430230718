import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  Container,
  InitialContent,
  TabMenu,
  TabPanel,
} from '@nordictrustee/nt-ui-library';
import { getNearestDate } from 'utils/getNearestDate';
import { RouteParam } from '../../../../../../utils/types/RouteParam';
import { useGetLoanScheduleDefinitions } from '../../../api';
import ScheduleDefinitionDetails from './components/ScheduleDefinitionsDetails';

const ScheduleDefinitions = () => {
  const { loanId } = useParams<RouteParam>();
  const { definitions, isLoading, getScheduleDefinitions } =
    useGetLoanScheduleDefinitions(loanId);

  useEffect(() => {
    loanId && getScheduleDefinitions();
  }, [getScheduleDefinitions, loanId]);

  const tabList: {
    [key: string]: { value: string; label: string };
  } = {};

  definitions.forEach((scheduleDef) => {
    tabList[scheduleDef.adjustedDate] = {
      value: String(scheduleDef.id),
      label: scheduleDef.adjustedDate
        ? scheduleDef.adjustedDate
        : 'no adjusted date',
    };
  });

  const tabIds = Object.values(tabList).map((tab) => tab?.value);
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    if (tabIds.length && !currentTab) {
      const nearestDate = getNearestDate(Object.values(tabList));
      setCurrentTab(nearestDate.value);
    }
  }, [currentTab, tabIds.length, tabList]);

  const showDefinitions = tabIds.length !== 0;
  const currentItemIndex = tabIds.indexOf(currentTab);

  const [newTabAdjustedDate, setNewTabAdjustedDate] = useState('');

  useEffect(() => {
    if (newTabAdjustedDate && !isLoading) {
      const newTabDefinitionId = String(
        definitions.find(
          (definition) => definition.adjustedDate === newTabAdjustedDate,
        )?.id,
      );
      setCurrentTab(
        tabIds.find((id) => id === newTabDefinitionId) || tabIds[0],
      );
      setNewTabAdjustedDate('');
    }
  }, [definitions, isLoading, newTabAdjustedDate, tabIds]);

  const handleTabChange = (_: React.ChangeEvent<{}>, tab: string) => {
    setCurrentTab(tab);
  };

  return (
    <Container
      title="Schedule definitions"
      containerHeight="100%"
      isLoadingContent={isLoading}
    >
      {showDefinitions ? (
        <Grid container alignItems="baseline">
          <Grid item xs={10}>
            {currentTab && (
              <TabMenu
                items={tabList}
                tabsValue={currentTab}
                handleChange={handleTabChange}
                variant="scrollable"
                fitToContent
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {tabIds.map((tabId) => (
              <TabPanel
                key={tabId}
                currentTab={currentTab}
                value={String(tabId)}
              >
                <ScheduleDefinitionDetails
                  scheduleData={definitions[currentItemIndex]}
                />
              </TabPanel>
            ))}
          </Grid>
        </Grid>
      ) : (
        <InitialContent description="No schedule definitions specified yet" />
      )}
    </Container>
  );
};

export default ScheduleDefinitions;
