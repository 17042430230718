import PersonIcon from '@mui/icons-material/Person';
import styled from 'styled-components';
import theme, {
  facilityAgreementColor,
} from '../../../../../../../themes/theme';

export const Title = styled.p`
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  line-height: 23px;
  color: ${facilityAgreementColor.main};
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 1rem;
`;

export const DataContainer = styled.div`
  display: grid;
  grid-template-columns: [first] 1fr [line2] 1fr [line3] 1fr [end];
  grid-template-rows: [row1-start] 45% [row1-end] 50%;
  position: relative;
`;

export const SectionDiv = styled.div``;

export const PhotoContainer = styled.div`
  display: flex;
`;

export const Photo = styled.img`
  height: auto;
  width: 100%;
  margin-top: -6px;
`;

export const PortraitPhotoContainer = styled.div`
  margin-left: 1rem;
  position: relative;
  width: 110px;
  height: 110px;
  overflow: hidden;
  border-radius: 50%;
`;

export const PhotoPlaceholder = styled.div`
  height: 105px;
  width: 105px;
  background-color: ${theme.palette.grey[300]};
  margin-left: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlaceholderAvatar = styled(PersonIcon)`
  color: white;
  height: 80% !important;
  width: 80% !important;
  position: relative;
  top: -4px;
`;

export const CurrencyContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectWrapper = styled.div<{ hideContainer?: boolean }>`
  width: 80px;
  margin-top: -10px;
  .MuiFormLabel-root,
  .MuiFormLabel-root + div {
    margin-top: 10px;
  }
  visibility: ${(props) => (props.hideContainer ? 'hidden' : 'visible')};
`;

export const MarginContainer = styled.div`
  margin-top: 15px;
`;
