import React, { Dispatch, SetStateAction } from 'react';
import { Column } from '@material-table/core';
import { ColumnWithTooltip, Table } from '@nordictrustee/nt-ui-library';
import { AmountAdjustment } from 'modules/Loan/LoanDetails/LoanDetails.types';
import { firstColPadding } from 'utils/constants';
import { currencyFormatter } from 'utils/formatters/currencyFormatter';
import { addSpaceBeforeCapitalLetters } from 'utils/functional';
import { selectedRowStyle } from 'utils/selectedRowStyle';
import { loanColor } from '../../../../../../../../themes/theme';

const columns: Column<AmountAdjustment>[] = [
  {
    field: 'settlementDate',
    title: 'Settlement Date',
    width: '17%',
    ...firstColPadding,
  },
  {
    field: 'amount',
    title: 'Amount',
    width: '17%',
    render: ({ amount, currency }) => (
      <ColumnWithTooltip title={currencyFormatter(amount, currency)}>
        {currencyFormatter(amount, currency)}
      </ColumnWithTooltip>
    ),
  },
  {
    field: 'category',
    title: 'Category',
    width: '14%',
    render: ({ category }) => addSpaceBeforeCapitalLetters(category),
  },
  {
    field: 'paymentDate',
    title: 'Payment Date',
    width: '15%',
    cellStyle: { textAlign: 'left' },
  },
];

interface Props {
  amountAdjustmentsData?: AmountAdjustment[];
  isLoadingAmountAdjustmentsData: boolean;
  selectedItemId: number;
  setSelectedItemId: Dispatch<SetStateAction<number>>;
}

const AmountAdjustmentTable = ({
  amountAdjustmentsData,
  isLoadingAmountAdjustmentsData,
  selectedItemId,
  setSelectedItemId,
}: Props) => {
  return (
    <Table
      columns={columns}
      data={amountAdjustmentsData ?? []}
      isLoading={isLoadingAmountAdjustmentsData}
      options={{
        maxBodyHeight: '65vh',
        minBodyHeight: '65vh',
        paging: false,
        search: false,
        toolbar: false,
        tableLayout: 'fixed',
        rowStyle: (rowData: AmountAdjustment) =>
          selectedRowStyle(rowData.id, selectedItemId, loanColor.rowSelection),
      }}
      onRowClick={(_: unknown, item: AmountAdjustment) =>
        setSelectedItemId(item.id)
      }
    />
  );
};

export default AmountAdjustmentTable;
