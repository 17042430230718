//Login
export const ROOT = '/';
export const ABOUT = '/about';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const VERIFY_ACCOUNT = '/verify-account';
export const TERMS = '/terms';
export const RESET_PASSWORD = '/reset-account';
export const SENT_EMAIL_CONFIRMATION = '/sent-email-confirmation';
export const PROFILE = '/profile';

// Facility agreements
export const FACILITY_AGREEMENTS = '/facility-agreements';
export const FACILITY_AGREEMENT_DETAILS =
  '/facility-agreements/:agreementId/:section(events|overview|compliance-schedule)/:itemId?';
export const FACILITY_DETAILS = '/facilities/:facilityId/:section';
export const FACILITIES = '/facilities';
export const LOAN_DETAILS =
  '/facilities/:facilityId/loan/:loanId/:section/:amountId?/:paymentId?';
