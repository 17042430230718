import React, { Dispatch, useEffect, useState } from 'react';
import { Column } from '@material-table/core';
import { ColumnWithTooltip, Table } from '@nordictrustee/nt-ui-library';
import {
  FacilityDetailsDataSectionsType,
  Payment,
  PaymentType,
} from 'modules/FacilityDetails/FacilityDetails.types';
import { firstColPadding, tableRowHeight } from 'utils/constants';
import { currencyFormatter } from 'utils/formatters/currencyFormatter';
import { addSpaceBeforeCapitalLetters } from 'utils/functional';
import { selectedRowStyle } from 'utils/selectedRowStyle';
import { ActionsGeneric } from 'utils/types/ActionsGeneric';
import {
  facilityColor,
  loanColor,
} from '../../../../../../../../../../themes/theme';

interface Props {
  actions?: ActionsGeneric<Payment>;
  sectionName: FacilityDetailsDataSectionsType;
  data: Payment[];
  isLoadingPayments: boolean;
  getRowClickUrl?: (itemId?: string) => string;
  selectedPayment?: Payment;
  setSelectedPayment: Dispatch<React.SetStateAction<Payment | undefined>>;
}

const PaymentsTable = ({
  actions,
  sectionName,
  data,
  isLoadingPayments,
  getRowClickUrl,
  selectedPayment,
  setSelectedPayment,
}: Props) => {
  const isAmountAdjustmentSection =
    sectionName === FacilityDetailsDataSectionsType.LoanAmountAdjustment;

  const unableToCalculate = 'Unable to calculate';

  const columns: Column<Payment>[] = [
    {
      field: 'amount',
      title: 'Value',
      width: '40%',
      render: (item) => (
        <ColumnWithTooltip
          title={
            item?.errorMessage ||
            currencyFormatter(item?.amount, item?.currency)
          }
        >
          {item?.errorMessage
            ? unableToCalculate
            : currencyFormatter(item?.amount, item?.currency)}
        </ColumnWithTooltip>
      ),
      ...firstColPadding,
    },
    {
      field: 'recipient',
      title: 'Recipient',
      width: '20%',
      render: (item) => (
        <ColumnWithTooltip title={item?.recipient}>
          {item?.recipient}
        </ColumnWithTooltip>
      ),
    },
    {
      field: 'description',
      title: 'Description (ext)',
      width: '40%',
      render: (item) => (
        <ColumnWithTooltip
          title={
            item.type !== PaymentType.Manual
              ? addSpaceBeforeCapitalLetters(item?.description)
              : item?.description
          }
        >
          {item.type !== PaymentType.Manual
            ? addSpaceBeforeCapitalLetters(item?.description)
            : item?.description}
        </ColumnWithTooltip>
      ),
    },
  ];

  const [tempId, setTempId] = useState('');

  useEffect(() => {
    if (
      data.length &&
      sectionName !== FacilityDetailsDataSectionsType.LoanAmountAdjustment &&
      data[0].type !== 'AmountAdjustment'
    ) {
      if (tempId !== String(data[0].id)) {
        setTempId(String(data[0].id));
      }
    }
  }, [data, getRowClickUrl, sectionName, tempId]);

  return (
    <Table
      actions={actions}
      columns={columns}
      data={data}
      options={{
        actionsCellStyle: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: tableRowHeight,
        },
        search: false,
        toolbar: false,
        paging: false,
        tableLayout: 'fixed',
        maxBodyHeight: isAmountAdjustmentSection ? '25vh' : '23vh',
        minBodyHeight: isAmountAdjustmentSection ? '25vh' : '23vh',
        rowStyle: (rowData: Payment) =>
          selectedRowStyle(
            rowData.id,
            selectedPayment ? selectedPayment?.id : 0,
            FacilityDetailsDataSectionsType.FacilityCommitmentFeeSchedule ===
              sectionName
              ? facilityColor.rowSelection
              : loanColor.rowSelection,
          ),
      }}
      isLoading={isLoadingPayments}
      onRowClick={(_: any, rowData: Payment) => setSelectedPayment(rowData)}
    />
  );
};

export default PaymentsTable;
