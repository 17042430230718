import { Column } from '@material-table/core';
import {
  ActiveCell,
  ColumnWithTooltip,
  Table,
} from '@nordictrustee/nt-ui-library';
import {
  FacilityDetailsDataSectionsType,
  LenderSplit,
} from 'modules/FacilityDetails/FacilityDetails.types';
import {
  columnRightText,
  firstColPadding,
  tableRowHeight,
} from 'utils/constants';
import { currencyFormatter } from 'utils/formatters/currencyFormatter';
import { decimalNumberWithPercentFormatter } from '../../../../../../../../../../utils/formatters/numberFormatter';

interface Props {
  data: LenderSplit[];
  isLoadingLenderSplit: boolean;
  sectionName?: FacilityDetailsDataSectionsType;
}

const LenderSplitTable = ({
  data,
  isLoadingLenderSplit,
  sectionName,
}: Props) => {
  const columns: Column<LenderSplit>[] = [
    {
      field: 'lender',
      title: 'Lender',
      width: '40%',
      render: (item) => (
        <ActiveCell isActive={item?.isActive}>
          <ColumnWithTooltip title={item?.lenderName}>
            {item?.lenderName}
          </ColumnWithTooltip>
        </ActiveCell>
      ),
      ...firstColPadding,
    },
    {
      field: 'value',
      title: 'Value',
      width: '35%',
      render: (item) => (
        <ActiveCell isActive={item?.isActive}>
          <ColumnWithTooltip
            title={currencyFormatter(item?.amount, item?.currency)}
          >
            {currencyFormatter(item?.amount, item?.currency)}
          </ColumnWithTooltip>
        </ActiveCell>
      ),
      ...columnRightText,
    },
    {
      field: 'percentage',
      title: 'Percentage',
      width: '25%',
      render: (item) => (
        <ActiveCell isActive={item?.isActive}>
          <ColumnWithTooltip
            title={decimalNumberWithPercentFormatter(item?.share)}
          >
            {decimalNumberWithPercentFormatter(item?.share)}
          </ColumnWithTooltip>
        </ActiveCell>
      ),
      ...columnRightText,
    },
  ];

  const isAmountAdjustmentSection =
    sectionName === FacilityDetailsDataSectionsType.LoanAmountAdjustment;

  return (
    <Table
      columns={columns}
      data={data}
      options={{
        search: false,
        toolbar: false,
        paging: false,
        tableLayout: 'fixed',
        maxBodyHeight: isAmountAdjustmentSection ? '31vh' : '23vh',
        minBodyHeight: isAmountAdjustmentSection ? '31vh' : '23vh',
        rowStyle: { height: tableRowHeight },
      }}
      isLoading={isLoadingLenderSplit}
    />
  );
};

export default LenderSplitTable;
