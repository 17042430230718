import { FC, ReactNode } from 'react';
import {
  Assignment as AccessIcon,
  NewReleases as MitigateIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import {
  Button,
  LandingPageLayout as Layout,
  LandingPageScreenShot as ScreenShot,
  LandingPageSection as Section,
} from '@nordictrustee/nt-ui-library';
import screenIntro from './images/landingpage1.png';
import screenMitigate from './images/landingpage2.png';
import screenAccess from './images/landingpage3.png';
import classes from './AboutLayout.module.css';

export type AboutLayoutProps = {
  logInMarkup?: ReactNode;
};
type MuiButtonColors =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';
const AboutLayout: FC<AboutLayoutProps> = ({ logInMarkup }) => {
  const DemoButton: FC<{ color?: MuiButtonColors }> = ({ color }) => (
    <Button
      startIcon={<SendIcon />}
      href="mailto:portals@nordictrustee.com?subject=Requesting%20Loan%20Portal%20Demo"
      size="large"
      variant={!color && logInMarkup ? 'outlined' : 'contained'}
      color={color ? color : 'primary'}
    >
      Request Demo
    </Button>
  );
  const introMarkup = logInMarkup ? (
    <div className={classes.login}>{logInMarkup}</div>
  ) : (
    <ScreenShot
      screenShot={
        <img src={screenIntro} alt="Screenshot from the Loan Portal" />
      }
    />
  );
  return (
    <Layout
      className={classes.loanPortalTheme}
      title={
        <>
          Loan <strong>Portal</strong>
        </>
      }
      description={
        <p>
          Empowers borrowers and lenders with deal specific information about
          their private debt transactions.
        </p>
      }
      introFooter={<DemoButton />}
      introDecoration={introMarkup}
      joinUsProps={{
        primaryButton: <DemoButton color={'secondary'} />,
      }}
    >
      <Section
        className={classes.mitigateSection}
        decoration={
          <ScreenShot
            screenShot={
              <img src={screenMitigate} alt="Screenshot from the Loan Portal" />
            }
            backgroundElement={<MitigateIcon />}
          />
        }
        content={
          <>
            <h2>
              <MitigateIcon /> Mitigate&nbsp;<strong>Risk</strong>
            </h2>
            <p>
              Reduce the operational risks of your finance and back office
              department through event notifications, interest rate calculations
              and other related information. The Loan Portal helps you mitigate
              risk of errors resulting from manual interactions and reduces
              dependencies on data maintained and stored locally.
            </p>
          </>
        }
      />
      <Section
        className={classes.accessSection}
        decoration={
          <ScreenShot
            screenShot={
              <img src={screenAccess} alt="Screenshot from the Loan Portal" />
            }
            backgroundElement={<AccessIcon />}
          />
        }
        content={
          <>
            <h2>
              <AccessIcon /> Access&nbsp;<strong>Information</strong>
            </h2>
            <ul>
              <li>
                Access information about your loans, facilities and facility
                agreements
              </li>
              <li>
                See aggregated exposure across facility agreements for both
                lenders and borrowers
              </li>
              <li>
                Gain full insight into commitment fees, undrawn and drawn
                commitments, interest and exposure
              </li>
              <li>
                Stay up to date with support from the Event Calendar function
              </li>
            </ul>
          </>
        }
      />
    </Layout>
  );
};

export default AboutLayout;
