import theme, {
  baseBoxShadow,
  bondStatusChip,
  environmentChip,
  iconsColors,
  issuesInProcessStateChip,
  primaryColorShades,
  tableColors,
} from './theme';
import { DefaultTheme } from './types';

const defaultTheme: DefaultTheme = {
  bondStatusChip,
  iconsColors,
  primaryColorShades,
  tableColors,
  environmentChip,
  issuesInProcessStateChip,
  theme,
  baseBoxShadow,
};

export default defaultTheme;
