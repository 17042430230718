import { addSpaceBeforeCapitalLetters } from 'utils/functional';

export const getFormattedCalendersValue = (
  calendarValues: string[] | undefined,
) => {
  return calendarValues
    ? calendarValues
        ?.map((calendar) => addSpaceBeforeCapitalLetters(calendar))
        .sort()
        .join(', ')
    : '';
};
