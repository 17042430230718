import { KeyboardEvent } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import FingerPrintIcon from '@mui/icons-material/Fingerprint';
import { TextField } from '@mui/material';
import { helperText } from 'utils/reactHookFormUtils';
import { ErrorLabel } from '../styles';
import { PASSWORD_REGEX } from './consts';
import * as S from './styles';

export interface Props {
  name?: string;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  control: Control<any>;
  errors: DeepMap<object, FieldError>;
  disabled?: boolean;
  showIcon?: boolean;
  passwordToMatch?: string;
  resetPassword?: boolean;
  onKeyUp?: (data: KeyboardEvent<HTMLElement>) => void;
}

const PasswordInput = ({
  name = 'password',
  label = name,
  required,
  autoFocus,
  control,
  errors,
  disabled,
  resetPassword,
  showIcon,
  passwordToMatch,
  onKeyUp,
}: Props) => {
  const requiredStarLabel = required ? '*' : '';
  return (
    <S.IconInput>
      {!showIcon && (
        <S.Icon>
          <FingerPrintIcon />
        </S.Icon>
      )}
      <S.Input>
        <Controller
          defaultValue=""
          name={name}
          render={({ field: { onChange, value, onBlur, ref }, fieldState: {error} }) => (
            <TextField
              inputRef={ref}
              type="password"
              autoFocus={autoFocus}
              name={name}
              label={`${label}${requiredStarLabel}`}
              onChange={(event) => {
                onChange(event.target.value);
              }}
              onBlur={onBlur}
              onKeyUp={onKeyUp}
              value={value || ''}
              size="small"
              margin={'dense'}
              fullWidth
              disabled={disabled}
              autoComplete="on"
              error={!!error}
            />
          )}
          control={control}
          rules={{
            required: required ? 'This field is required' : undefined,
            pattern: resetPassword
              ? {
                  value: PASSWORD_REGEX,
                  message:
                    'Password must contain minimum 1 UPPER, 1 lowercase letter, at least 1 number with a total minimum length of 8 chars',
                }
              : undefined,
            validate: (value) => {
              return passwordToMatch && passwordToMatch !== value
                ? 'Password needs to match'
                : undefined;
            },
          }}
        />
        <ErrorLabel>
          {errors && helperText(`${name}`, errors)}
        </ErrorLabel>
      </S.Input>
    </S.IconInput>
  );
};

export default PasswordInput;
