import { useMemo, useState } from 'react';
import useQuery from 'utils/hooks/useQuery';

export const useFacilityAgreementsQuery = () => {
  const query = useQuery();
  const searchTextFromQuery = useMemo(() => query.get('phrase') || '', [query]);
  const [searchTextQuery, setSearchTextQuery] = useState(searchTextFromQuery);
  const [currencyQuery, setCurrencyQuery] = useState<string>();

  const params = useMemo(
    () => ({
      phrase: searchTextQuery,
      currency: currencyQuery,
    }),
    [currencyQuery, searchTextQuery],
  );

  const searchQuery = useMemo(() => {
    let searchQuery: string[][] = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== '') {
        searchQuery.push([key, `${value}`]);
      }
    });
    return new URLSearchParams(searchQuery);
  }, [params]);

  return useMemo(
    () => ({
      setSearchTextQuery,
      searchQuery,
      searchTextQuery,
      currencyQuery,
      setCurrencyQuery,
    }),
    [searchQuery, searchTextQuery, currencyQuery],
  );
};
