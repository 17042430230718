import { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { MaturedChip, TabMenu, TabPanel } from '@nordictrustee/nt-ui-library';
import { FACILITIES, FACILITY_AGREEMENTS } from '../../../router/url';
import {
  facilityAgreementColor,
  facilityColor,
  homeMainColor,
  loanColor,
} from '../../../themes/theme';
import { RouteParam } from '../../../utils/types/RouteParam';
import { useGetFacilityBasicInfo } from '../../FacilityDetails/api';
import AmountAdjustment from './AmountAdjustment/index';
import { useGetLoanBasicInfo } from './api';
import InterestSchedule from './InterestSchedule';
import Overview from './Overview';
import { useTabMenu } from './useTabMenu';
import { StyleContainer } from './LoanDetails.css';

const LoanDetails = () => {
  const { TabList, currentTab, handleTabChange } = useTabMenu();
  const { facilityId, loanId } = useParams<RouteParam>();

  const { loanBasicInfo, isLoading, getLoanBasicInfo } =
    useGetLoanBasicInfo(loanId);

  const { facilityBasicInfo, getFacilityBasicInfo } =
    useGetFacilityBasicInfo(facilityId);

  useEffect(() => {
    loanId && getLoanBasicInfo();
  }, [getLoanBasicInfo, loanId]);

  useEffect(() => {
    //todo: remove when loan details has facility info fields
    facilityId && getFacilityBasicInfo();
  }, [facilityId, getFacilityBasicInfo]);

  return (
    <StyleContainer disableGutters maxWidth="xl">
      <Box mt={2} mb={1}>
        <Breadcrumbs>
          <NavLink to={FACILITY_AGREEMENTS} style={{ color: homeMainColor }}>
            Home
          </NavLink>
          <NavLink
            style={{ color: facilityAgreementColor.breadcrumb }}
            to={`${FACILITY_AGREEMENTS}/${facilityBasicInfo?.facilityAgreementID}/overview`}
          >
            {facilityBasicInfo?.facilityAgreementName}
          </NavLink>
          <NavLink
            style={{ color: facilityColor.breadcrumb }}
            to={`${FACILITIES}/${facilityId}/overview`}
          >
            {facilityBasicInfo?.name}
          </NavLink>
        </Breadcrumbs>
        <Box mt={1} mb={2}>
          <Box display="inline-flex">
            <Typography
              variant="h2"
              color={loanBasicInfo?.isMatured ? 'secondary' : loanColor.header}
            >
              {loanBasicInfo?.name}
            </Typography>
            {loanBasicInfo?.isMatured && <MaturedChip />}
          </Box>
        </Box>
      </Box>
      <Box mt={1} mb={1}>
        <TabMenu
          items={TabList}
          tabsValue={currentTab}
          handleChange={handleTabChange}
          isDividerVisible
        />
      </Box>
      <Box>
        <TabPanel currentTab={currentTab} value={TabList.overview.value}>
          <Overview isLoading={isLoading} data={loanBasicInfo} />
        </TabPanel>
        <TabPanel
          currentTab={currentTab}
          value={TabList['amount-adjustment'].value}
        >
          <AmountAdjustment />
        </TabPanel>
        <TabPanel
          currentTab={currentTab}
          value={TabList['interest-schedule'].value}
        >
          <InterestSchedule />
        </TabPanel>
      </Box>
    </StyleContainer>
  );
};

export default LoanDetails;
