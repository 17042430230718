import { ChipList, Container, DataView } from '@nordictrustee/nt-ui-library';
import { FacilityBasicInformation } from '../../../../FacilityDetails.types';

interface Props {
  facilityData?: FacilityBasicInformation;
  isLoading?: boolean;
}

const BasicInformation = ({ facilityData, isLoading }: Props) => {
  const viewData = [
    {
      data: [
        {
          label: 'Availability period start',
          value: facilityData?.availabilityPeriodStart,
        },
        {
          label: 'Availability period end',
          value: facilityData?.availabilityPeriodEnd,
        },
        { label: 'Facility Maturity date', value: facilityData?.maturityDate },
        {
          label: 'Commitment Fee',
          value: facilityData?.commitmentFeeApplicable,
        },
        {
          label: 'Commitment Fee Payer',
          value: facilityData?.commitmentFeePayer?.name,
        },
        {
          label: 'Revolving Credit Facility',
          value: facilityData?.revolvingCreditFacility,
        },
        {
          label: 'No. of loans/Max no. of loans',
          value: `${facilityData?.numberOfLoans ?? '-'}/${
            facilityData?.maxLoans ?? '-'
          }`,
        },
        { label: 'Base currency', value: facilityData?.baseCurrency },
        {
          label: 'Optional currencies',
          value: facilityData?.optionalCurrencies?.length
            ? facilityData?.optionalCurrencies?.join(', ')
            : 'N/A',
        },
        {
          label: 'Accounting rule',
          value: facilityData?.accountingRuleTypeLabel,
        },
        {
          label: 'Borrowers',
          value: <ChipList list={facilityData?.borrowers || []} maxChips={1} />,
        },
      ],
    },
  ];

  return (
    <Container
      title="Basic information"
      containerHeight="100%"
      isLoadingContent={isLoading}
    >
      <DataView data={viewData} columns={2} minRows={8} maxRows={8} />
    </Container>
  );
};

export default BasicInformation;
