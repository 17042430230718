import moment, { Moment } from 'moment';
import { dateDisplayFormat } from 'utils/constants';

export const getInitDate = (date: any) =>
  date ? moment(date, dateDisplayFormat) : null;

export const daysDiffText = (date: Moment) => {
  const days = date.startOf('day').diff(moment().startOf('day'), 'days');
  if (days === 0) {
    return 'Today';
  }
  if (days === 1) {
    return 'In 1 day';
  }
  if (days > 0) {
    return `In ${days} days`;
  }
  return undefined;
};
