import * as S from './Logo.css';

const Logo = () => {
  return (
    <S.Logo
      id="Layer_1"
      dta-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2969 514"
    >
      <style>{'.cls-1{fill:#f7f8f6}'}</style>
      <title>Home</title>
      <path
        className="cls-1"
        d="M795.2,170.59a23,23,0,0,0-16.4,6.75l-65.44,65.89a87.16,87.16,0,0,0-145.43-51L420.68,339.46l40.78,0a23.05,23.05,0,0,0,16.41-6.75l65.52-65.35v.06a87.16,87.16,0,0,0,145.34,50.41L835.32,170.54ZM628,305.5A50.5,50.5,0,1,1,678.5,255,50.5,50.5,0,0,1,628,305.5Z"
      />
      <path
        className="cls-1"
        d="M997.39,206.87V337.14H970.68V171.64h35l79.21,130.27V171.64h26.71v165.5h-35Z"
      />
      <path
        className="cls-1"
        d="M1141.88,275c0-37.83,27.89-63.13,62.65-63.13,35.23,0,62.66,24.83,62.66,63.13,0,39.25-28.14,64.78-62.66,64.78C1169.77,339.74,1141.88,314.92,1141.88,275Zm99.06,0c0-23.64-14.89-40.67-36.41-40.67-21.75,0-36.41,17-36.41,40.67,0,25.06,14.89,42.32,36.41,42.32S1240.94,300.26,1240.94,275Z"
      />
      <path
        className="cls-1"
        d="M1321.11,337.14h-25.54V214.67h25.3v18.44c3.07-4.73,6.14-8.75,10.64-12.06,7.33-5.44,14.18-7.33,24.35-7.33a37.65,37.65,0,0,1,8,.71v24.83c-6.15-.48-9-.48-12.06-.48-13,0-30.73,4.5-30.73,29.32Z"
      />
      <path
        className="cls-1"
        d="M1473.14,320.59c-6.85,11.11-20.81,19.15-38.06,19.15-31,0-57-22.46-57-64.78,0-40.43,27.18-63.13,57.21-63.13,17.73,0,31,7.8,37.83,17.26V166.91h25.54V337.14h-25.54Zm.47-45.63c0-26-14.66-41.38-34.52-41.38-20.09,0-34.52,15.61-34.52,41.38,0,27,14.43,43,34.52,43C1458.25,318,1473.61,301.91,1473.61,275Z"
      />
      <path className="cls-1" d="M1535.1,214.67h25.54V337.14H1535.1Z" />
      <path
        className="cls-1"
        d="M1589,275c0-36.89,27.43-63.13,61.24-63.13,28.13,0,53.2,18.44,56,45.87H1681c-3.55-14.66-16.55-23.41-31-23.41-20.57,0-34.76,17.26-34.76,40.67,0,24.35,14.66,42.32,34.76,42.32,14.19,0,26.72-8,31-23.64h25.3c-4,29.08-29.79,46.1-56.51,46.1C1615.74,339.74,1589,314.21,1589,275Z"
      />
      <path
        className="cls-1"
        d="M1767.3,195h-54.85v-23.4h137.13V195h-54.85v142.1H1767.3Z"
      />
      <path
        className="cls-1"
        d="M1886.55,337.14H1861V214.67h25.3v18.44c3.07-4.73,6.14-8.75,10.64-12.06,7.33-5.44,14.18-7.33,24.35-7.33a37.65,37.65,0,0,1,8,.71v24.83c-6.15-.48-9-.48-12.06-.48-13,0-30.73,4.5-30.73,29.32Z"
      />
      <path
        className="cls-1"
        d="M2029.12,322.25c-7.8,11.58-19.86,17.49-35.47,17.49-26.24,0-44.21-16.78-44.21-48.23V214.67H1975V288c0,19.16,10.41,29.56,26.25,29.56,14.18,0,27.42-10.88,27.42-34v-68.8h25.54V337.14h-25.06Z"
      />
      <path
        className="cls-1"
        d="M2081.62,298.37h24.59c2.36,14.42,14.89,20.57,29.79,20.57,12.53,0,25.06-5,25.06-15.61,0-9.93-9.22-13.95-26.47-17l-12.07-2.13c-23.64-4.26-38.06-15.37-38.06-34.28,0-23.88,21.75-38.31,47.76-38.31,25.54,0,47.29,13.48,50.36,37.83h-24.35c-3.08-11.35-13-16.78-26.72-16.78-13,0-22.94,5.91-22.94,15.13,0,8.51,7.34,11.82,21.76,14.42l12.05,2.13c26.25,4.73,43.27,14.9,43.27,36.41,0,25.3-24.11,39.25-50.12,39.25C2107.87,340,2084.46,326.5,2081.62,298.37Z"
      />
      <path
        className="cls-1"
        d="M2220.42,304.51V235.24h-21.28V214.67h21.28V185.35h25.3v29.32H2275v20.57h-29.32V301c0,13.24,4.5,15.6,14,15.6H2275V336.9c-9,.48-12.53.48-22,.48C2233.66,337.38,2220.42,331.47,2220.42,304.51Z"
      />
      <path
        className="cls-1"
        d="M2355.91,339.74c-34,0-62.42-24.35-62.42-64.78,0-38.78,29.08-63.13,60.76-63.13s59.82,22,59.82,66v5.91h-94.33c2.36,21.28,18.2,34.28,36.88,34.28,12.29,0,24.59-4,30.74-15.84h25.53C2405.56,328.39,2379.79,339.74,2355.91,339.74Zm-36.41-76.37h67.86c-1.42-18.2-15.13-30-33.58-30C2336.28,233.35,2321.63,244.69,2319.5,263.37Z"
      />
      <path
        className="cls-1"
        d="M2496.6,339.74c-34,0-62.42-24.35-62.42-64.78,0-38.78,29.08-63.13,60.76-63.13s59.83,22,59.83,66v5.91h-94.34c2.36,21.28,18.2,34.28,36.88,34.28,12.3,0,24.59-4,30.74-15.84h25.53C2546.25,328.39,2520.48,339.74,2496.6,339.74Zm-36.41-76.37h67.86c-1.42-18.2-15.13-30-33.58-30C2477,233.35,2462.32,244.69,2460.19,263.37Z"
      />
      <circle className="cls-1" cx="1547.21" cy="181.59" r="15.62" />
    </S.Logo>
  );
};

export default Logo;
