import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Breadcrumbs, Container, Grid, Typography } from '@mui/material';
import {
  Container as NTContainer,
  DataView,
  TabMenu,
  TabPanel,
} from '@nordictrustee/nt-ui-library';
import { AuthContext } from 'context/AuthProvider/AuthProvider';
import { FACILITY_AGREEMENTS } from 'router/url';
import { homeMainColor } from 'themes/theme';
import { useTabMenu } from './useTabMenu';
import { PaddingDiv } from './Profile.css';

export const Profile = () => {
  const { TabList, currentTab, handleTabChange } = useTabMenu();
  const { isLoadingUser, user } = useContext(AuthContext);

  const data = [
    {
      data: [
        {
          label: 'Name',
          value: user?.firstName + ' ' + user?.lastName,
        },
        {
          label: 'Login',
          value: (
            <span style={{ textDecoration: 'underline' }}>{user?.email}</span>
          ),
        },
        {
          label: 'Abbreviation',
          value: user?.abbreviation,
        },
      ],
    },
  ];

  return (
    <Container disableGutters maxWidth="xl">
      <PaddingDiv>
        <Breadcrumbs>
          <NavLink to={FACILITY_AGREEMENTS} style={{ color: homeMainColor }}>
            Home
          </NavLink>
        </Breadcrumbs>
      </PaddingDiv>
      <Box display="flex" alignItems="center" mt={1.3}>
        <Typography variant="h2">Profile</Typography>
      </Box>
      <Box mt={2} mb={1}>
        <TabMenu
          variant="standard"
          items={TabList}
          tabsValue={currentTab}
          handleChange={handleTabChange}
          isDividerVisible
        />
      </Box>
      <TabPanel currentTab={currentTab} value={TabList['settings'].value}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <NTContainer
              title="User details"
              isLoadingContent={isLoadingUser}
              containerHeight="350px"
            >
              <DataView columns={1} data={data} />
            </NTContainer>
          </Grid>
        </Grid>
      </TabPanel>
    </Container>
  );
};

export default Profile;
