import moment from 'moment';
import {
  FacilityInterestSchedule,
  FacilityInterestSchedulePeriodsData,
} from '../modules/FacilityDetails/FacilityDetails.types';
import { getInitDate } from './formatters/dateFormatter';

export const findClosestToTodayPeriod = (tableData: any) => {
  const currentDate = moment();
  const currentPeriod = tableData?.filter((period: any) => {
    const termStartDate = getInitDate(period.termStart);
    const termEndDate = getInitDate(period.termEnd);
    return !!(
      termStartDate?.isSameOrBefore(currentDate) &&
      termEndDate?.isAfter(currentDate)
    );
  });

  if (currentPeriod.length) {
    // if there is a current period
    return currentPeriod[0];
  } else {
    const firstPeriod = tableData[0];
    const lastPeriod = tableData[tableData.length - 1];
    const termStartDate = getInitDate(firstPeriod?.termStart);
    if (termStartDate?.isBefore(currentDate)) {
      // if all dates are in the past
      return lastPeriod;
    } else {
      // if all dates are in the future
      return firstPeriod;
    }
  }
};

export const getFormattedSchedulesData = (
  schedulePeriodsData: FacilityInterestSchedulePeriodsData,
): FacilityInterestSchedule[] =>
  schedulePeriodsData?.schedulePeriods?.flatMap(
    ({ id, schedules, startDate, endDate, containsManualPayments }) =>
      schedules?.map((schedule) => ({
        ...schedule,
        periodId: id,
        periodStartDate: startDate,
        periodEndDate: endDate,
        containsManualPayments: containsManualPayments,
      })),
  ) ?? [];
