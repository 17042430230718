import React, { Dispatch } from 'react';
import { Container, InitialContent } from '@nordictrustee/nt-ui-library';
import {
  FacilityDetailsDataSectionsType,
  Payment,
} from 'modules/FacilityDetails/FacilityDetails.types';
import PaymentsTable from './components/PaymentsTable';

interface Props {
  sectionName: FacilityDetailsDataSectionsType;
  payments: Payment[];
  isLoadingPayments: boolean;
  selectedPayment?: Payment;
  setSelectedPayment: Dispatch<React.SetStateAction<Payment | undefined>>;
  itemId: number;
  period?: string;
}

const Payments = ({
  sectionName,
  payments,
  isLoadingPayments,
  selectedPayment,
  setSelectedPayment,
  itemId,
  period,
}: Props) => {
  const isAmountAdjustmentSection =
    sectionName === FacilityDetailsDataSectionsType.LoanAmountAdjustment;

  return itemId ? (
    <Container
      borderRadius={!isAmountAdjustmentSection ? '0' : undefined}
      withoutShadow={!isAmountAdjustmentSection}
      containerHeight="100%"
      title={
        isAmountAdjustmentSection ? `Payments - ${period}` : 'Total Payments'
      }
    >
      <PaymentsTable
        sectionName={sectionName}
        data={payments}
        isLoadingPayments={isLoadingPayments}
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
      />
    </Container>
  ) : (
    <InitialContent
      square={!isAmountAdjustmentSection}
      withoutShadow={!isAmountAdjustmentSection}
      description="No payments"
      customHeight={isAmountAdjustmentSection ? '35vh' : '22vh'}
    />
  );
};

export default Payments;
