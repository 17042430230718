import { SelectOption } from 'react-select-material-ui';
import { isEmptyObject } from '../../../../utils/functional';
import { useAxios } from '../../../../utils/hooks/useAxios';
import { PageableResponse } from '../../../../utils/types/PageableResponse';
import {
  BasicAgreement,
  CaseHandler,
  EventFA,
  FacilityItem,
} from '../../FacilityAgreement.types';

export const useGetBasicFacility = (
  agreementId?: string,
  currency?: string,
) => {
  const [
    { data: basicFacility, loading: isFacilityLoading },
    getBasicFacility,
  ] = useAxios<BasicAgreement>(
    `/facility-agreements/${agreementId}${
      currency ? `?currency=${currency}` : ''
    }`,
    {
      manual: true,
    },
  );
  return { basicFacility, isFacilityLoading, getBasicFacility };
};

export const useGetFacilities = (agreementId?: string) => {
  const [
    { data: facilitiesData = [], loading: areFacilitiesLoading },
    getFacilities,
  ] = useAxios<FacilityItem[]>(
    `/facility-agreements/${agreementId}/facilities`,
    {
      manual: true,
    },
  );
  return { facilitiesData, areFacilitiesLoading, getFacilities };
};

export const useGetUpcomingEvents = (agreementId?: string) => {
  const [{ data: eventsData, loading: isLoading }, getEvents] = useAxios<
    PageableResponse<EventFA[]>
  >(
    `/calendar-events/upcoming-calendar-events?facilityAgreementID=${agreementId}&page=0&pageSize=8&sortKey=Date&sortDir=Asc`,
    {
      manual: true,
    },
  );
  return { eventsData, isLoading, getEvents };
};

export const useGetEvents = (params: URLSearchParams, agreementId?: string) => {
  const [{ data: eventsData, loading: isLoading }, getEvents] = useAxios<
    PageableResponse<EventFA[]>
  >(
    {
      url: `/calendar-events/upcoming-calendar-events?facilityAgreementID=${agreementId}`,
      params: Object.fromEntries(params.entries()),
    },
    {
      manual: true,
    },
  );
  return { eventsData, isLoading, getEvents };
};

export const useGetCurrenciesForAgreement = (agreementId?: string) => {
  const [{ data: currencies, loading: areCurrenciesLoading }, getFACurrencies] =
    useAxios<string[]>(`/currencies/${agreementId}/facilities`, {
      manual: true,
    });

  const currencyOptions: SelectOption[] =
    currencies?.map((o) => ({
      value: o,
      label: o,
    })) || [];

  return { currencies, currencyOptions, areCurrenciesLoading, getFACurrencies };
};

export const useGetCaseHandlerPhoto = (agreementId?: string) => {
  const [{ data: caseHandler, loading: isPhotoLoading }, getCaseHandlerPhoto] =
    useAxios<CaseHandler>(
      `/facility-agreements/${agreementId}/case-handler-photo`,
      {
        manual: true,
      },
    );

  if (caseHandler?.content && agreementId) {
    const image = JSON.parse(
      localStorage.getItem(`photo-${agreementId}`) || '{}',
    );
    if (isEmptyObject(image)) {
      localStorage.setItem(
        `photo-${agreementId}`,
        JSON.stringify(caseHandler?.content),
      );
    }
  }

  return { caseHandler, isPhotoLoading, getCaseHandlerPhoto };
};
