import theme from 'themes';
import { tableRowHeight } from 'utils/constants';

export const selectedRowStyle = (
  rowId: Number,
  itemId?: string | number,
  color?: string,
  height?: string,
) => {
  return rowId === Number(itemId)
    ? {
        backgroundColor: color ? color : theme.tableColors.selectedRow,
        height: height ?? tableRowHeight,
      }
    : { height: height ?? tableRowHeight };
};
