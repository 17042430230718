/* eslint-disable max-len */
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Container, Link, Typography } from '@mui/material';
import { Card } from '@nordictrustee/nt-ui-library';
import { FACILITY_AGREEMENTS } from 'router/url';
import { homeMainColor } from 'themes/theme';
import TextParagraph from 'components/TextParagraph/TextParagraph';
import * as S from './Terms.css';

const Terms = () => (
  <Container maxWidth="xl">
    <S.BreadcrumbsMargin>
      <Breadcrumbs>
        <NavLink to={FACILITY_AGREEMENTS} style={{ color: homeMainColor }}>
          Home
        </NavLink>
      </Breadcrumbs>
    </S.BreadcrumbsMargin>
    <S.PaddingDiv>
      <Card
        customHeader={
          <S.TermHeader>
            <Typography variant="h3">
              GENERAL TERMS AND CONDITIONS FOR THE USE OF THE LOAN PORTAL
            </Typography>
          </S.TermHeader>
        }
      >
        <S.TermBody>
          <Typography variant="body1">
            These general terms and conditions (the “General Terms and
            Conditions”) govern a User’s individual right to use and access the
            Loan Portal. If a User fails to comply with any provision contained
            in these terms and conditions or uses the Loan Portal in conflict
            with these General Terms and Conditions, it may lose its right to
            access and use the Loan Portal. A User’s access and use of the Loan
            Portal may also be governed by an applicable agency agreement
            between Nordic Trustee (the “Agent”) in its capacity as agent and
            the respective lender(s) and/or borrower(s). In the event of any
            inconsistency with these General Terms and Conditions, the terms of
            such agency agreement shall prevail.
          </Typography>
          <br />
          <br />
          <TextParagraph header="Definitions">
            <Typography>
              <strong>“Facility” </strong>means any facility or loan which is
              included in the Loan Portal from time to time.
            </Typography>
            <br />
            <Typography>
              <strong>“Facility Party” </strong>means any company which is a
              party to a Facility as either a lender or borrower.
            </Typography>
            <br />
            <Typography>
              <strong>“Loan Portal” </strong>means the digital, web-based
              service platform designed and developed (and exclusively owned) by
              the Agent for Facility Parties to support Facility Parties with
              their loan administration which is accessible at{' '}
              <Link
                href="https://loan-portal.nordictrustee.com"
                target="_blank"
              >
                https://loan-portal.nordictrustee.com
              </Link>
              .
            </Typography>
            <br />
            <Typography>
              <strong>“Nordic Trustee” </strong>means, as applicable, Nordic
              Trustee AS (Norway), Nordic Trustee & Agency AB (publ) (Sweden),
              Nordic Trustee Oy (Finland), Nordic Trustee A/S (Denmark), or any
              of its affiliates.
            </Typography>
            <br />
            <Typography>
              <strong>“User” </strong>
              means a natural person having been approved by a Facility Party
              and duly authorized by the Agent to access and use the Loan Portal
              in respect of one or more Facilities, in each case identifiable by
              its unique email address.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Account safety">
            <Typography>
              Users may be required to create an account (an “Account”) and
              provide Nordic Trustee with a username, password, and certain
              other required information. The User is solely responsible for the
              information associated with an Account. An Account is personal,
              and the User shall not give any other person access to an Account
              without the Agent’s prior written approval. The User shall
              promptly notify the Agent if unpermitted accessed is suspected or
              discovered.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Content on the Loan Portal">
            <Typography>
              Data or data files derived from the Loan Portal may not be
              redistributed, in part or whole, in any form, by a User, without
              prior written consent from the Agent.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Information on the Loan Portal">
            <Typography>
              Information on the Loan Portal is provided by the Agent on a best
              effort basis for the User’s convenience. The Agent shall have no
              responsibility for the information on the Loan Portal. The Agent
              does not verify any User-submitted content, but may view, store
              and process any such content. The User shall promptly inform the
              Agent of any errors or inconsistencies in the information on the
              Loan Portal.
            </Typography>
            <br />
            <Typography>
              The Loan Portal is an information platform for convenience only
              and does not affect any obligations under any underlying financial
              arrangements of a Facility. In the event of inconsistencies
              between the information in the Loan Portal and the applicable
              legal documents governing the Parties’ obligations in respect of
              the relevant Facility, the latter will prevail. In particular,
              Users should be aware there may be undertakings, obligations and
              deadlines pursuant to the underlying financial documentation that
              are not visible in the Loan Portal.
            </Typography>
            <br />
            <Typography>
              The Agent does not guarantee that the Loan Portal is up-to-date,
              complete, free of errors or available at all times, and does not
              take any responsibility for the accuracy, completeness or
              timeliness of any information or documentation provided by the
              Agent in the Loan Portal. The Agent is in certain aspects of the
              Loan Portal dependent on receipt of information from third parties
              in order to provide up-to-date information on the Loan Portal. The
              Agent will not be responsible for the unavailability, delay or
              incorrectness of information on the Loan Portal arising from the
              acts or omissions by any third parties.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Modifications to the Loan Portal">
            <Typography>
              The Agent reserves the right to modify, add or remove, in part or
              in whole, material, content and features presented on the Loan
              Portal at any time.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Intellectual Property rights">
            <Typography>
              The Agent has the right to any information and other material
              (including texts, picture material, graphical interfaces and
              databases) on the Loan Portal. Users are not permitted to make any
              copies or redistribution of any information or other material
              without the prior written consent from the Agent. Users of the
              Loan Portal are not permitted to make any amendments of any
              information of the Loan Portal, or otherwise take any actions
              which commercially exploit the Loan Portal.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Agent Provision">
            <Typography>
              The User acknowledges that the Agent in its capacity as agent (the
              “Agent”) in respect of a Facility shall never be deemed to have
              knowledge of any fact or circumstance evident from the Loan
              Portal.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Limitation of liability and disclaimer">
            <Typography>
              The Agent shall never be liable towards the User for any damage or
              loss of any kind arising from or relating to the Loan Portal. The
              Agent will use its best efforts to maintain adequate security to
              prevent unlawful access by third parties to the Agent’s
              infrastructures.
            </Typography>
            <br />
            <Typography>
              No content and data available on the Loan Portal shall be
              construed as the Agent providing financial, investment, legal, tax
              or other advice of any kind.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Personal data">
            <Typography>
              All personal data provided by a User will be handled in accordance
              with relevant data protection regulations from time to time, and
              in accordance with the Agent’s privacy statement available at{' '}
              <Link
                href="https://nordictrustee.com/about/governance"
                target="_blank"
              >
                https://nordictrustee.com/about/governance
              </Link>
              .
            </Typography>
            <br />
            <Typography>
              The personal data collected through the Loan Portal is only for
              internal use and will not be handed over to any external parties.
              The Agent only collects information that is required to fulfill
              the purpose of the actual service, however the Agent may use
              external technology providers (such as Hotjar and Google
              Analytics) in order to use data for better understanding of the
              Agent’s customers’ needs and to optimize the Loan Portal. Such
              external providers may use cookies and other technologies to
              collect data on the Agent’s users’ behavior and their devices.
              This data does not contain any personally identifiable information
              and will never be used to identify individual users. You may
              opt-out of Hotjar’s tracking service by visiting{' '}
              <Link
                href="https://www.hotjar.com/legal/compliance/opt-out"
                target="_blank"
              >
                https://www.hotjar.com/legal/compliance/opt-out
              </Link>
              and opt-out of Google Analytics by downloading Google’s official
              browser add-on here:{' '}
              <Link
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
              >
                https://tools.google.com/dlpage/gaoptout
              </Link>
              .
            </Typography>
            <br />
            <Typography>
              More information about the Agent’s use of cookies can also be
              found at{' '}
              <Link
                href="https://www.nordictrustee.com/privacy/"
                target="_blank"
              >
                https://www.nordictrustee.com/privacy/
              </Link>
              .
            </Typography>
          </TextParagraph>
          <TextParagraph header="Maintenance of the Loan Portal">
            <Typography>
              For IT maintenance purposes, the Agent may access the Loan Portal
              from a User’s view in relation to a Facility without prior notice
              to the Facility Party or the User.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Termination of a User's access to the Loan Portal">
            <Typography>
              The Agent may, by written notice to the User, suspend or terminate
              a User’s access to the Loan Portal in case of (i) any breach or
              misuse by the User or these General Terms and Conditions, (ii)
              unpaid fees in respect of the Loan Portal (to the extent
              applicable), (iii) if the Agent for any reason is unable to
              provide access to the Loan Portal and the services contemplated by
              this Agreement or (iv) if the User's approval from a Finance Party
              has been revoked.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Assignments">
            <Typography>
              No User may assign any of its rights and obligations under these
              General Terms and Conditions to another party without the prior
              written consent from the Agent.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Fees payable in respect of the Loan Portal">
            <Typography>
              The Loan Portal is an integrated part of the Agent's service
              offering as Agent. The Agent may introduce a fee for any
              additional services or features subject in each case to the user's
              consent.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Notices">
            <Typography>
              Any notice or other communication to be made to the Agent in
              relation to these General Terms and Conditions shall be sent to
              the following email address:
            </Typography>
            <Typography>
              portals@nordictrustee.com or as may be notified by the Agent from
              time to time.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Changes to these General Terms and Conditions">
            <Typography>
              The Agent may change these General Terms and Conditions at any
              time and will notify the User of any material changes by sending
              an email to the address(es) associated with the User. By
              continuing to use the Loan Portal after the date of revision, the
              User agrees to be bound by the changes.
            </Typography>
          </TextParagraph>
          <TextParagraph header="Governing Law and Jurisdiction">
            <Typography>
              These General Terms and Conditions shall be exclusively governed
              by the laws of Norway. All disputes arising out of, or in
              connection with these General Terms and Conditions shall be
              exclusively resolved by the courts of Norway, with the District
              Court of Oslo as a first legal venue.
            </Typography>
          </TextParagraph>
        </S.TermBody>
      </Card>
    </S.PaddingDiv>
  </Container>
);

export default Terms;
