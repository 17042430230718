import {
  FacilityDetailsDataSectionsType,
  LenderSplit,
} from 'modules/FacilityDetails/FacilityDetails.types';
import { useAxios } from '../../../../../../../../utils/hooks/useAxios';

const getPaymentLenderSplitUrl = (
  sectionName: FacilityDetailsDataSectionsType,
  paymentItemId?: number,
) => {
  switch (sectionName) {
    case FacilityDetailsDataSectionsType.FacilityCommitmentFeeSchedule: {
      return `/commitment-fee-schedule-payments/${paymentItemId}/payment-lender-splits`;
    }
    case FacilityDetailsDataSectionsType.LoanAmountAdjustment: {
      return `/amount-adjustment-payments/${paymentItemId}/payment-lender-splits`;
    }
    case FacilityDetailsDataSectionsType.LoanInterestSchedule: {
      return `/interest-schedule-payments/${paymentItemId}/payment-lender-splits`;
    }
    default: {
      return '';
    }
  }
};

export const useGetPaymentLenderSplit = (
  sectionName: FacilityDetailsDataSectionsType,
  paymentId?: number,
) => {
  const [
    { data: paymentLenderSplit = [], loading: isLoadingPaymentLenderSplitData },
    getPaymentLenderSplit,
  ] = useAxios<LenderSplit[]>(
    getPaymentLenderSplitUrl(sectionName, paymentId),
    {
      manual: true,
    },
  );
  return {
    paymentLenderSplit,
    isLoadingPaymentLenderSplitData,
    getPaymentLenderSplit,
  };
};
