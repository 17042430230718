import moment from 'moment';

export const getNearestDate = (
  datesList: {
    value: string;
    label: string;
  }[],
) => {
  return datesList
    .map((el) => ({
      ...el,
      diff: Math.abs(moment().diff(moment(el.label, 'DD.MM.YYYY'), 'days')),
    }))
    .reduce((acc, loc) => (acc.diff < loc.diff ? acc : loc));
};
