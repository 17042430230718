import { useEffect } from 'react';
import { Column } from '@material-table/core';
import { Container, Table } from '@nordictrustee/nt-ui-library';
import DataLinkBlack from 'components/DataLinkBlack';
import { linkCellPaddingLeftRight } from 'utils/constants';
import * as api from './api';
import { LastVisitedFAs } from './LastVisitedFacilityAgreements.types';

const columns: Column<LastVisitedFAs>[] = [
  {
    title: '',
    field: 'name',
    render: ({ objectName, objectID }) => (
      <DataLinkBlack
        to={`/facility-agreements/${objectID}/overview`}
        text={objectName}
        noPaddingLeft
        maxWidth="100%"
        tooltip={objectName}
      />
    ),
    ...linkCellPaddingLeftRight,
    sorting: false,
  },
];

const LastVisitedFacilityAgreements = () => {
  const { lastVisitedFAsData, isDataLoading, getLastVisitedFAs } =
    api.useGetLastVisitedFAs();

  useEffect(() => {
    getLastVisitedFAs();
  }, [getLastVisitedFAs]);

  return (
    <Container
      title="Last visited facility agreements"
      containerHeight="350px"
    >
      <Table
        isLoading={isDataLoading}
        data={lastVisitedFAsData || []}
        columns={columns}
        options={{
          paging: false,
          toolbar: false,
          tableLayout: 'fixed',
          minBodyHeight: '15.1rem',
          maxBodyHeight: '15.1rem',
          rowStyle: {
            height: '2.5rem',
          },
        }}
      />
    </Container>
  );
};

export default LastVisitedFacilityAgreements;
