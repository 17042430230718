import React, { KeyboardEvent } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { TextField } from '@mui/material';
import { helperText } from 'utils/reactHookFormUtils';
import { registerMaxLength } from 'utils/registerMaxLength';
import { ErrorLabel } from '../styles';

interface Props {
  name: string;
  label: string;
  control: Control;
  errors: DeepMap<object, FieldError>;
  isRequired?: boolean;
  hideRequiredStar?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  onKeyUp?: (data: KeyboardEvent<HTMLElement>) => void;
}

const EmailInput = ({
  name,
  label,
  control,
  errors,
  isRequired,
  hideRequiredStar,
  autoFocus,
  disabled,
  onKeyUp,
}: Props) => {
  const required = isRequired && !hideRequiredStar ? '*' : '';
  return (
    <>
      <Controller
        defaultValue=""
        name={name ?? ''}
        render={({ field: { ref, ...restField }, fieldState: {error} }) => (
          <TextField
            {...restField}
            inputRef={ref}
            autoFocus={autoFocus}
            label={`${label}${required}`}
            onKeyUp={onKeyUp}
            disabled={disabled}
            size="small"
            margin={'dense'}
            error={!!error}
            fullWidth
          />
        )}
        control={control}
        rules={{
          required: isRequired ? 'This field is required' : '',
          pattern: {
            value: /^\S+@\S+\.\S+$/i,
            message: 'Invalid email',
          },
          ...registerMaxLength(100),
        }}
      />
      {errors && (
        <ErrorLabel>
          {helperText(name, errors)}
        </ErrorLabel>
      )}
    </>
  );
};

export default EmailInput;
