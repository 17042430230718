import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
import { ErrorToast, SuccessToast } from '@nordictrustee/nt-ui-library';
import FormPage from '../../../components/FormPage';
import PasswordInput from '../../../components/Inputs/PasswordInput';
import * as URL from '../../../router/url';
import { handleException } from '../../../utils/handleException';
import useQuery from '../../../utils/hooks/useQuery';
import { resetPassword } from '../api';
import * as S from './styles';

const ResetPassword = () => {
  const { push } = useHistory();

  const query = useQuery();
  const token = useMemo(() => query.get('token') || '', [query]);

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm({ mode: 'onBlur' });

  const handleResetPassword = handleSubmit(async (data) => {
    const isValid = await trigger();
    if (isValid) {
      setIsLoading(true);
      if (data['New password'] !== data['Confirm password']) {
        toast.error(<ErrorToast message="Passwords don't match" />);
        setIsLoading(false);
      } else {
        try {
          const response = await resetPassword(token, data['New password']);
          setIsLoading(false);
          if (response && response.status === 202) {
            toast.success(
              <SuccessToast message="Password has been successfully changed. Log in now with new credentials." />,
            );
            push(`${URL.LOGIN}`);
          }
        } catch (e) {
          setIsLoading(false);
          handleException(e);
        }
      }
    }
  });

  return (
    <FormPage
      cardTitle="Reset password"
      buttonText="Reset password"
      emptyHeader
      boxSize={6}
      onClick={handleResetPassword}
      isLoadingButton={isLoading}
    >
      <S.CardText>
        <Typography variant="subtitle1">
          You requested to reset the password for your account. Please enter
          your new credentials below.
        </Typography>
      </S.CardText>
      <PasswordInput
        name={'New password'}
        control={control}
        errors={errors}
        required
        resetPassword
      />
      <PasswordInput
        name={'Confirm password'}
        control={control}
        errors={errors}
        required
        resetPassword
      />
    </FormPage>
  );
};

export default ResetPassword;
