import { FC, PropsWithChildren } from 'react';
import { Box, CardContent, CircularProgress, Typography } from '@mui/material';
import { Button } from '@nordictrustee/nt-ui-library';
import * as S from './styles';

type FormPageCardProps = {
  buttonText?: string;
  boxSize?: number;
  isLoadingPage?: boolean;
  cardTitle?: string;
  isLoadingButton?: boolean;
  onClick?: () => void;
} & PropsWithChildren;

export const FormPageCard: FC<FormPageCardProps> = ({
  boxSize = 4,
  isLoadingPage,
  cardTitle,
  children,
  buttonText,
  isLoadingButton,
  onClick,
}) => {
  return !isLoadingPage ? (
    <S.FormPageCard>
      <S.CardHeader>
        {cardTitle && <Typography variant="h3">{cardTitle}</Typography>}
      </S.CardHeader>
      <CardContent>
        {children}
        <Box mt={boxSize}>
          {buttonText && (
            <Button
              fullWidth
              onClick={onClick}
              isLoading={isLoadingButton}
            >
              {buttonText}
            </Button>
          )}
        </Box>
      </CardContent>
    </S.FormPageCard>
  ) : (
    <CircularProgress />
  );
};
