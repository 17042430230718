import { useEffect } from 'react';
import { Container } from '@nordictrustee/nt-ui-library';
import { useGetHomeUpcomingEvents } from '../../api';
import EventsTable from '../../components/EventsTable/EventsTable';

const HomeUpcomingEvents = () => {
  const { events, isLoading, getHomeEvents } = useGetHomeUpcomingEvents();

  useEffect(() => {
    getHomeEvents();
  }, [getHomeEvents]);

  return (
    <Container title="Upcoming events" containerHeight="100%">
      <EventsTable isLoading={isLoading} data={events} bodyHeight="15.1rem" />
    </Container>
  );
};

export default HomeUpcomingEvents;
