import { DataView } from '@nordictrustee/nt-ui-library';
import { decimalNumberWithPercentFormatter } from 'utils/formatters/numberFormatter';
import { ScheduleDefinition } from '../../../../../../../../FacilityDetails.types';

interface Props {
  scheduleData: ScheduleDefinition;
}

const BasicInformation = ({ scheduleData }: Props) => {
  const baseData = [
    {
      label: 'Return Type',
      value: scheduleData?.returnType,
    },
    {
      label: 'Day Count Convention',
      value: scheduleData?.dayCountConventionLabel,
    },

    {
      label: 'Business Day Convention',
      value: scheduleData?.businessDayConvention?.name,
    },
    {
      label: 'Terms Per Year',
      value: scheduleData?.termsPerYearLabel,
    },
  ];

  const dataFRN = () => {
    const data = [];
    if (scheduleData?.margin || scheduleData?.margin === 0) {
      data.push({
        label: 'Margin',
        value: decimalNumberWithPercentFormatter(scheduleData.margin),
      });
    }
    data.push({
      label: 'Reference rate',
      value: scheduleData.referenceRate,
    });
    if (scheduleData?.interestPaymentType) {
      data.push({
        label: 'Payment type',
        value: `${scheduleData?.interestPaymentType?.name}`,
      });
    }
    data.push({
      label: 'Floor convention',
      value: scheduleData?.interestFloorConvention?.label,
    });
    data.push({
      label: 'Floor',
      value: decimalNumberWithPercentFormatter(scheduleData.floor),
    });

    return data;
  };

  const extraData =
    scheduleData?.returnType === 'FIX'
      ? [
          {
            label: 'Interest Rate',
            value: decimalNumberWithPercentFormatter(
              scheduleData?.interestRate,
            ),
          },
          {
            label: 'Payment type',
            value: `${scheduleData?.interestPaymentType?.name}`,
          },
        ]
      : dataFRN();

  const data = [
    {
      data: [...baseData, ...extraData],
    },
  ];

  return (
    <DataView
      data={data}
      minRows={6}
      maxRows={6}
      extendContainer
      extendInfoColumnContainer
    />
  );
};

export default BasicInformation;
