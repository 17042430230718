import styled from 'styled-components';

const maxWidth = '600px';

export const TermBody = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${maxWidth};
  margin: auto;
`;

export const TermHeader = styled.div`
  max-width: ${maxWidth};
  margin: auto;
  color: red;
  width: 100%;
`;

export const PaddingDiv = styled.div`
  margin-top: 1rem;
`;

export const BreadcrumbsMargin = styled.div`
  margin-top: -0.5rem;
`;
